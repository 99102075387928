import { useLocation, useParams, useNavigate } from "react-router-dom";
// import StoreCategory from "../components/StorePage/StoreCategory";
import FiftyOff from "../components/Home/FiftyOff";
import storeimg from "../images/store.png";
import location1 from "../images/location1.png";
import star1 from "../images/star1.png";
// import DogFood from "../components/StorePage/DogFood";
import MenuSection from "../components/StorePage/MenuSection";
// import AvailableSlots from "../components/Services/AvailableSlots";
// import EmptyItems from "../components/Services/EmptyItems";
import Form from "react-bootstrap/Form";
import { useEffect, useState } from "react";
import { commonHeaders, petSetUrl } from "../components/utils";

const StoreItems = () => {
  const location = useLocation();
  const redirect = useNavigate();
  const id = location?.state?.store_id;
  // const { id } = useParams();
  const [storeData, setstoreData] = useState({});
  const [val, setval] = useState("");
  // const [selectedCategory, setSelectedCategory] = useState(0);
  // const handleCategoryClick = (index) => {
  //   console.log(`Received clicked category index in StoreItems: ${index}`);
  //   setSelectedCategory(index);
  // };
  // const MenuSectionComponent = location.pathname === `/StoreItems/${id}` && (
  //   <MenuSection
  // clickindex={selectedCategory}
  //   />
  // );
  // const StoreCategoryComponent = location.pathname === `/StoreItems/${id}` && (
  //   <StoreCategory onCategoryClick={handleCategoryClick}/>
  // );
  // const DogFoodComponent = location.pathname === "/ServicesItems" && (
  //   <DogFood />
  // );
  // const AvailableSlotsComponent = (location.pathname === "/ServicesItems" ||
  //   location.pathname === "/EmptyServicesItems") && <AvailableSlots />;

  // const EmptyItemComponent = location.pathname === "/EmptyServicesItems" && (
  //   <EmptyItems />
  // );
  const handleClick = () => {
    // console.log("Enter clicked");
    const StoreApi = `${petSetUrl}/v1/store/products/store/filter?store_id=${id}&query=${val}`;
    const requestOptions = {
      method: "GET",
      headers: commonHeaders,
    };
    fetch(StoreApi, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((resopnseData) => {
        // console.log(resopnseData.data);
        redirect("/SearchProduct", {
          state: {
            data: resopnseData.data,
            query: val,
          },
        });
        setval("");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleClick();
    }
  };

  const getStore = () => {
    const storedLatitude = localStorage.getItem("lat");
    const storedLongitude = localStorage.getItem("lng");
    const Api = `${petSetUrl}//v1/store/get?lng=${storedLongitude}&lat=${storedLatitude}`;
    const requestOptions = {
      method: "GET",
      headers: commonHeaders,
    };
    fetch(Api, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        const matchingRecord = responseData.shops.find(
          (record) => record.sid === id
        );
        setstoreData(matchingRecord);
      })
      .catch((error) => {
        console.error("Error fetching store data from the API:", error);
      });
  };
  useEffect(() => {
    getStore();
  }, []);
  return (
    <>
      {/* <div style={{ height: "40px" }} className="border-bottom"></div> */}
      <div
        className="mb-5"
        style={{ height: "100px", backgroundColor: "#F2EDFF" }}
      ></div>
      <div className="mt-5 ps-5">
        <div className="ps-lg-5 mb-5 d-lg-flex d-md-flex d-sm-block ps-sm-0">
          <div className="mt-2">
            <img
              src={
                storeData.media === "http"
                  ? storeData.media
                  : storeData.media === ""
                  ? storeimg
                  : "https://petsetgostorage.blob.core.windows.net/petsetgo-u2/" +
                    storeData.media
              }
              alt=""
              width="100px"
              height="100px"
              className="bg-white border border-dark"
            />
          </div>
          <div className="lh-1 ms-md-4 ms-sm-0">
            <p>Home > Store > {storeData.store_name}</p>
            <h1>{storeData.store_name}</h1>
            <div className="d-flex">
              <p>
                <img className="mb-1" src={location1} alt="" width="16px" />{" "}
                <span className="small">
                  {" "}
                  {/* {storeData.distance.toFixed(2)}KM away */}
                  {storeData.distance
                    ? storeData.distance.toFixed(2) + "KM away"
                    : ""}
                </span>
              </p>
              <p className="ms-2">
                {Array(5)
                  .fill(null)
                  .map((_, starIndex) => (
                    <img
                      className="mb-1"
                      key={starIndex}
                      src={star1}
                      alt=""
                      width="16px"
                    />
                  ))}
                <span className="small">
                  {" "}
                  {storeData.averageRatings
                    ? storeData.averageRatings.toFixed(1)
                    : ""}
                </span>
              </p>
            </div>
          </div>
          <Form.Control
            style={{
              borderRadius: "20px",
              width: "150px",
              boxShadow: "none",
              height: "30px",
              marginLeft: "19%",
              borderColor: "#D1D5DB",
            }}
            type="search"
            className="py-0"
            placeholder="Search here"
            aria-label="Search"
            value={val}
            onChange={(e) => {
              setval(e.target.value);
            }}
            onKeyDown={handleKeyDown}
          />
        </div>

        {/* <div className="row row-cols-1 row-cols-lg-2 flex_direction">
          <div className="ms-4 storeproduct"> */}
        {/* {MenuSectionComponent} */}
        <MenuSection />
        {/* {DogFoodComponent} */}
        {/* {EmptyItemComponent} */}
        {/* </div>
          <div className="ms-4 storecategory"> */}
        {/* {StoreCategoryComponent} */}
        {/* {AvailableSlotsComponent} */}
        {/* </div>
        </div> */}
      </div>
      <div className="mt-5">
        <FiftyOff />
      </div>
    </>
  );
};
export default StoreItems;
