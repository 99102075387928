import "./App.css";
import "./fonts/Gramatika-Black.ttf";
import "./fonts/Gramatika-Regular.ttf";
import "bootstrap/dist/css/bootstrap.min.css";
import Routing from "./Routing";

function App() {
  return (
    <>
      <Routing />
    </>
  );
}

export default App;
