import Accordion from "react-bootstrap/Accordion";
import DogFood from "./DogFood";
import { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { commonHeaders, petSetUrl } from "../utils";

function MenuSection() {
  // const { id } = useParams();
  const location = useLocation();
  const id = location?.state?.store_id;
  const [storeData, setstoreData] = useState({});
  const [sections, setsections] = useState(undefined || []);
  const [selectedSortOption, setSelectedSortOption] = useState("default");
  const [activeAccordionKey, setActiveAccordionKey] = useState(0);
  const [selectedCategory, setselectedCategory] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedSubcategoryId, setSelectedSubcategoryId] = useState(null);

  const [selectedSubcategoryIndex, setSelectedSubcategoryIndex] = useState(0);

  // const handleCategoryClick = (index) => {
  //   setActiveAccordionKey((prevKey) =>
  //     prevKey === index.toString() ? null : index.toString()
  //   );
  //   setselectedCategory(index);
  // };

  const handleCategoryClick = (index) => {
    setActiveAccordionKey((prevKey) =>
      prevKey === index.toString() ? null : index.toString()
    );
    setselectedCategory(index);
  };

  const handleSortChange = (option) => {
    setSelectedSortOption(option);
  };
  useEffect(() => {
    const storedLatitude = localStorage.getItem("lat");
    const storedLongitude = localStorage.getItem("lng");
    const Api = `${petSetUrl}/v1/store/get?lng=${storedLongitude}&lat=${storedLatitude}`;
    const requestOptions = {
      method: "GET",
      headers: commonHeaders,
    };
    fetch(Api, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        const matchingRecord = responseData.shops.find(
          (record) => record.sid === id
        );
        setstoreData(matchingRecord);
      })
      .catch((error) => {
        console.error("Error fetching store data from the API:", error);
      });
    setLoading(true);
    const CategoryApi = ` ${petSetUrl}/v1/store/tag/store/subtag?sid=${id}&cid=05f8383d-33d5-4c62-9ba6-c054f9ebb6ae`;
    fetch(CategoryApi, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        setsections(responseData.data);
      })
      .catch((error) => {
        console.error("Error fetching store data from the API:", error);
      })
      .finally(() => {
        // Set loading to false after two seconds
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      });
  }, [id]);

  const handleSubcategoryClick = (subcategoryId, index) => {
    setSelectedSubcategoryId(subcategoryId);
    setSelectedSubcategoryIndex(index);
  };
  return (
    <>
      <div className="row row-cols-sm-1 row-cols-md-2 row-cols-lg-2 flex_direction">
        <div className="ms-1 storeproduct">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <p>{storeData.description}</p>
              </div>
              <div className="col-lg-4 mt-5 right-0">
                <div className="row">
                  <div className="col-md-12">
                    <select
                      className="form-select shadow-none border border-#D1D5DB "
                      id="exampleSelect"
                      onChange={(e) => {
                        handleSortChange(e.target.value);
                      }}
                    >
                      <option value="option0">Sort by...</option>
                      <option value="high_to_low">Price: High to Low</option>
                      <option value="low_to_high">Price: Low to High</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {loading ? (
            <div
              className="loader"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            ></div>
          ) : (
            <Accordion
              activeKey={activeAccordionKey}
              defaultActiveKey="0"
              flush
              className="mt-3"
            >
              {sections && sections.length > 0 ? (
                sections.map((section, index) => (
                  <Accordion.Item key={index} eventKey={index.toString()}>
                    <Accordion.Header
                      onClick={() => handleCategoryClick(index)}
                    >
                      {section.des}
                    </Accordion.Header>
                    <Accordion.Body>
                      {activeAccordionKey === index.toString() && (
                        <div
                          style={{
                            display: "flex",
                            marginBottom: "40px",
                            overflowX: "auto", // Enable horizontal scrolling
                          }}
                          className="itemproduct"
                        >
                          {section.sub_categories
                            .sort((a, b) => a.des.localeCompare(b.des)) // Sort alphabetically
                            .map((subcategory, subIndex) => (
                              <button
                                style={{ marginRight: "10px" }}
                                className={
                                  selectedSubcategoryIndex === subIndex
                                    ? "selected-button"
                                    : "unselected-button"
                                }
                                onClick={() =>
                                  handleSubcategoryClick(
                                    subcategory.des,
                                    subIndex
                                  )
                                }
                              >
                                {subcategory.des}
                              </button>
                            ))}
                        </div>
                      )}
                      {activeAccordionKey === index.toString() && (
                        // Render DogFood only for the open accordion
                        <DogFood
                          cid={section.child_cid}
                          sortOption={selectedSortOption}
                          selectedSubcategoryId={selectedSubcategoryId}
                          // store_id={id}
                        />
                      )}
                      {/* <DogFood
                        cid={section.child_cid}
                        sortOption={selectedSortOption}
                        selectedSubcategoryId={selectedSubcategoryId}
                      /> */}
                    </Accordion.Body>
                  </Accordion.Item>
                ))
              ) : (
                <p className="fs-1">No items found for this store</p>
              )}
            </Accordion>
          )}
        </div>

        {/* ----------------------Cetegories------------------- */}
        <div className="ms-md-4 storecategory">
          <div className="card ps-3 pt-3">
            <h4 className="mb-3">Category</h4>
            {sections?.length > 0 ? (
              sections.map((item, index) => (
                <p
                  className={selectedCategory === index ? "text-primary" : ""}
                  key={index}
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => handleCategoryClick(index)}
                >
                  {item.des}
                </p>
              ))
            ) : (
              <p>No categories</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default MenuSection;
