import information from "../../images/information.png";
import OrderHistory from "../../images/history.png";
import Address from "../../images/account_address.png";
import { Link } from "react-router-dom";
const Accountdetail = () => {
  return (
    <>
      <div className="mt-4 " style={{ padding: "0 5%" }}>
        <div className="row">
          <div className="col-md-4 mt-sm-3 mt-md-0">
            <Link to="/Information" className="text-decoration-none text-dark">
              <div
                className="w-100 py-4 text-center"
                style={{ backgroundColor: "#FAFAFA" }}
              >
                <img src={information} alt="" height="30px"/>

                <p>Information</p>
              </div>
            </Link>
          </div>
          <div className="col-md-4 mt-3 mt-md-0">
            <Link to="/OrderHistory" className="text-decoration-none text-dark">
              <div
                className="w-100 py-4 text-center"
                style={{ backgroundColor: "#FAFAFA" }}
              >
                <img src={OrderHistory} alt="" height="30px" />

                <p>Order History</p>
              </div>
            </Link>
          </div>
          <div className="col-md-4 mt-3 mt-md-0">
            <Link
              to="/AccountAddresses"
              className="text-decoration-none text-dark"
            >
              <div
                className="w-100 py-4 text-center"
                style={{ backgroundColor: "#FAFAFA" }}
              >
                <img src={Address} alt="" height="30px"/> <p>My Addresses</p>
              </div>
            </Link>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-4">
            <Link to="/MyPets" className="text-decoration-none text-dark">
              <div
                className="w-100 py-4 text-center"
                style={{ backgroundColor: "#FAFAFA" }}
              >
                <img src={information} alt="" height="30px"/> <p>My Pets</p>{" "}
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
export default Accountdetail;