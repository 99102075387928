import star1 from "../../images/star1.png";
// import servicproduct from "../../images/serviceitem.png";
import { useLocation, useNavigate } from "react-router";
import { useContext, useEffect, useState } from "react";
import CartContext from "../../Cart/Cart";
import { format, parse, parseISO } from "date-fns";
import axios from "axios";
import { commonHeaders, petSetUrl } from "../utils";
import LoginModal from "../../pages/LoginModal";
import Swal from "sweetalert2";

const ServiceProduct = () => {
  const location = useLocation();
  const nav = useNavigate();
  const {
    user,
    addService,
    store,
    state,
    currentdate,
    setcurrentdate,
    formattedDate,
    cartDelete,
    setStore,
  } = useContext(CartContext);

  const [data, setData] = useState(
    location?.state?.data ? location.state.data : ""
  );
  // console.log(data);

  const [pickUp, setpickUp] = useState(false);
  const [dropOff, setdropOff] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  const pickDrop = (value) => {
    if (value === "pickup") {
      setpickUp(true);
      setdropOff(false);
    } else if (value === "dropoff") {
      setdropOff(true);
      setpickUp(false);
    } else if (value === "both") {
      setpickUp(true);
      setdropOff(true);
    } else {
      setpickUp(false);
      setdropOff(false);
    }
  };

  const formatDate = (date) => {
    return format(date, "yyyy-MM-dd");
  };
  const formatDay = (date) => {
    const parse = parseISO(date);
    return format(parse, "EEEE");
  };

  const formattedTimes = (timeString) => {
    const parsedTime = parse(timeString, "hh:mm:ss a", new Date());
    return format(parsedTime, "hh:mm:ss a");
  };
  const [slot, setSlot] = useState(localStorage.getItem("select time") || "");
  // console.log(slot);
  const [pet, setPet] = useState("");
  const [pets, setPets] = useState([]);
  const [storeData, setstoreData] = useState({});

  const closeLoginModal = () => {
    setIsLoginModalOpen(false);
  };

  const handleGoBack = () => {};

  const handleProceedTo = (sericedata) => {
    // let day = "";
    // if (currentdate) {
    //   day = formatDay(currentdate);
    // }
    // const data = {
    //   customer_uid: user.uid,
    //   service_id: sericedata.item.service_id,
    //   store_id: sericedata.item.store_id,
    //   slotTiming: slot,
    //   slotDate: currentdate,
    //   slotDay: day,
    //   pet_name: pet,
    //   resource_name: sericedata.item.service_title,
    //   cart_id: "",
    //   media: sericedata.item.media,
    //   price: sericedata.item.service_price,
    //   drop_off: false,
    //   pickup: false,
    //   dropoff_rate: 0.0,
    //   pickup_rate: 0.0,
    //   pickup_rate_AND_dropoff_rate: 0.0,
    // };
    // if (pickUp && !dropOff) {
    //   data.pickup = true;
    //   data.pickup_rate = store?.pickup_rate || 0.0;
    // } else if (dropOff && !pickUp) {
    //   data.drop_off = true;
    //   data.dropoff_rate = store?.dropoff_rate || 0.0;
    // } else if (pickUp && dropOff) {
    //   data.pickup = true;
    //   data.drop_off = true;
    //   data.pickup_rate_AND_dropoff_rate =
    //     store?.pickup_rate_AND_dropoff_rate || 0.0;
    // }
    // cartDelete();
    // setStore(data.store);

    // addService(data);

    let day = "";
    const item = {
      customer_uid: user.uid,
      service_id: data.item.service_id,
      store_id: data.item.store_id,
      slotTiming: slot,
      slotDate: currentdate,
      slotDay: day,
      pet_name: pet,
      resource_name: data.item.service_title,
      cart_id: "",
      media: data.item.media,
      price: data.item.service_price,
      drop_off: false,
      pickup: false,
      dropoff_rate: 0.0,
      pickup_rate: 0.0,
      pickup_rate_AND_dropoff_rate: 0.0,
    };
    if (pickUp && !dropOff) {
      item.pickup = true;
      item.pickup_rate = store?.pickup_rate || 0.0;
    } else if (dropOff && !pickUp) {
      item.drop_off = true;
      item.dropoff_rate = store?.dropoff_rate || 0.0;
    } else if (pickUp && dropOff) {
      item.pickup = true;
      item.drop_off = true;
      item.pickup_rate_AND_dropoff_rate =
        store?.pickup_rate_AND_dropoff_rate || 0.0;
    }

    const AddService = {
      shop_id: data.item.store_id,
      uid: localStorage.getItem("uid"),
      products: [],
      slots: [item],
      dayboarding: [],
    };
    axios
      .post(`${petSetUrl}/v1/cart/add`, AddService)
      .then((response) => {
        // console.log(response);
        Swal.fire("cart added");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleBooking = () => {
    // const hasData =
    //   (state?.slots && state?.slots?.length > 0) ||
    //   (state?.products && state?.products?.length > 0);

    // if (hasData && state?.shop_id !== data.store.sid) {
    //   Swal.fire({
    //     title:
    //       "Your items from another store are in the cart, upon proceeding your items from another store will be cleared",
    //     text: "Choose an action:",
    //     icon: "info",
    //     showCancelButton: true,
    //     confirmButtonText: "Proceed",
    //     cancelButtonText: "Go Back",
    //   }).then((result) => {
    //     if (result.isConfirmed) {
    //       handleProceedTo(data);
    //     } else if (result.dismiss === Swal.DismissReason.cancel) {
    //       handleGoBack();
    //     }
    //   });
    // } else {
    //   let day = "";
    //   if (currentdate) {
    //     day = formatDay(currentdate);
    //   }
    //   const item = {
    //     customer_uid: user.uid,
    //     service_id: data.item.service_id,
    //     store_id: data.item.store_id,
    //     slotTiming: slot,
    //     slotDate: currentdate,
    //     slotDay: day,
    //     pet_name: pet,
    //     resource_name: data.item.service_title,
    //     cart_id: "",
    //     media: data.item.media,
    //     price: data.item.service_price,
    //     drop_off: false,
    //     pickup: false,
    //     dropoff_rate: 0.0,
    //     pickup_rate: 0.0,
    //     pickup_rate_AND_dropoff_rate: 0.0,
    //   };
    //   if (pickUp && !dropOff) {
    //     item.pickup = true;
    //     item.pickup_rate = store?.pickup_rate || 0.0;
    //   } else if (dropOff && !pickUp) {
    //     item.drop_off = true;
    //     item.dropoff_rate = store?.dropoff_rate || 0.0;
    //   } else if (pickUp && dropOff) {
    //     item.pickup = true;
    //     item.drop_off = true;
    //     item.pickup_rate_AND_dropoff_rate =
    //       store?.pickup_rate_AND_dropoff_rate || 0.0;
    //   }
    //   const existItem = state.slots.find(
    //     (ser) => ser.service_id === item.service_id
    //   );
    //   if (existItem) {
    //     cartDelete();
    //     setStore(data.store);
    //     addService(item);
    //     nav("/Cart");
    //   } else {
    //     setStore(data.store);
    //     addService(item);
    //     nav("/Cart");
    //   }
    // }

    axios
      .get(`${petSetUrl}/v1/cart/get?uid=${localStorage.getItem("uid")}`, {
        headers: commonHeaders,
      })

      .then((response) => {
        // console.log(response.data.data);
        let day = "";
        const item = {
          customer_uid: user.uid,
          service_id: data.item.service_id,
          store_id: data.item.store_id,
          slotTiming: slot,
          slotDate: currentdate,
          slotDay: day,
          pet_name: pet,
          resource_name: data.item.service_title,
          cart_id: "",
          media: data.item.media,
          price: data.item.service_price,
          drop_off: false,
          pickup: false,
          dropoff_rate: 0.0,
          pickup_rate: 0.0,
          pickup_rate_AND_dropoff_rate: 0.0,
        };
        if (pickUp && !dropOff) {
          item.pickup = true;
          item.pickup_rate = store?.pickup_rate || 0.0;
        } else if (dropOff && !pickUp) {
          item.drop_off = true;
          item.dropoff_rate = store?.dropoff_rate || 0.0;
        } else if (pickUp && dropOff) {
          item.pickup = true;
          item.drop_off = true;
          item.pickup_rate_AND_dropoff_rate =
            store?.pickup_rate_AND_dropoff_rate || 0.0;
        }

        if (response?.data?.data?.shop_id !== data.item.store_id) {
          Swal.fire({
            title:
              "Your items from another store are in the cart, upon proceeding your items from another store will be cleared",
            text: "Choose an action:",
            icon: "info",
            showCancelButton: true,
            confirmButtonText: "Proceed",
            cancelButtonText: "Go Back",
          }).then((result) => {
            if (result.isConfirmed) {
              handleProceedTo(data);
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              handleGoBack();
            }
          });
          // const AddService = {
          //   shop_id: data.item.store_id,
          //   uid: localStorage.getItem("uid"),
          //   products: [],
          //   slots: [item],
          //   dayboarding: [],
          // };
          // axios
          //   .post(`${petSetUrl}/v1/cart/add`, AddService)
          //   .then((response) => {
          //     console.log(response);
          //     Swal.fire("cart added");
          //   })
          //   .catch((error) => {
          //     console.log(error);
          //   });
        }

        if (response?.data?.data?.shop_id === data.item.store_id) {
          const NewService = {
            ...item,
            cart_id: response?.data?.data?.cart_id,
          };

          axios
            .post(`${petSetUrl}/v1/service/slots/reserve/add`, NewService)
            .then((response) => {
              // console.log(response);
              Swal.fire("cart updated");
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {
        console.log(error.response.data.message);
        let day = "";
        const item = {
          customer_uid: user.uid,
          service_id: data.item.service_id,
          store_id: data.item.store_id,
          slotTiming: slot,
          slotDate: currentdate,
          slotDay: day,
          pet_name: pet,
          resource_name: data.item.service_title,
          cart_id: "",
          media: data.item.media,
          price: data.item.service_price,
          drop_off: false,
          pickup: false,
          dropoff_rate: 0.0,
          pickup_rate: 0.0,
          pickup_rate_AND_dropoff_rate: 0.0,
        };
        if (pickUp && !dropOff) {
          item.pickup = true;
          item.pickup_rate = store?.pickup_rate || 0.0;
        } else if (dropOff && !pickUp) {
          item.drop_off = true;
          item.dropoff_rate = store?.dropoff_rate || 0.0;
        } else if (pickUp && dropOff) {
          item.pickup = true;
          item.drop_off = true;
          item.pickup_rate_AND_dropoff_rate =
            store?.pickup_rate_AND_dropoff_rate || 0.0;
        }
        // console.log(error?.response);
        // if (error?.response?.data?.status === 400) {
        const AddService = {
          shop_id: data.item.store_id,
          uid: localStorage.getItem("uid"),
          products: [],
          slots: [item],
          dayboarding: [],
        };
        axios
          .post(`${petSetUrl}/v1/cart/add`, AddService)
          .then((response) => {
            // console.log(response);
            Swal.fire("cart added");
          })
          .catch((error) => {
            console.log(error);
          });
        // }
      });
  };

  // const handleProceedTo = (sericedata) => {
  //   let day = "";
  //   if (currentdate) {
  //     day = formatDay(currentdate);
  //   }
  //   const data = {
  //     customer_uid: user.uid,
  //     service_id: sericedata.item.service_id,
  //     store_id: sericedata.item.store_id,
  //     slotTiming: slot + ".00",
  //     slotDate: currentdate,
  //     slotDay: day,
  //     pet_name: pet,
  //     resource_name: sericedata.item.service_title,
  //     cart_id: "",
  //     media: sericedata.item.media,
  //     price: sericedata.item.service_price,
  //     drop_off: false,
  //     pickup: false,
  //     dropoff_rate: 0.0,
  //     pickup_rate: 0.0,
  //     pickup_rate_AND_dropoff_rate: 0.0,
  //   };
  //   if (pickUp && !dropOff) {
  //     data.pickup = true;
  //     data.pickup_rate = store?.pickup_rate || 0.0;
  //   } else if (dropOff && !pickUp) {
  //     data.drop_off = true;
  //     data.dropoff_rate = store?.dropoff_rate || 0.0;
  //   } else if (pickUp && dropOff) {
  //     data.pickup = true;
  //     data.drop_off = true;
  //     data.pickup_rate_AND_dropoff_rate =
  //       store?.pickup_rate_AND_dropoff_rate || 0.0;
  //   }
  //   cartDelete();
  //   setStore(data.store);

  //   addService(data);
  // };

  // const handleBooking = () => {
  //   const hasData =
  //     (state?.slots && state?.slots?.length > 0) ||
  //     (state?.products && state?.products?.length > 0);

  //   if (hasData && state?.shop_id !== data.store.sid) {
  //     Swal.fire({
  //       title:
  //         "Your items from another store are in the cart, upon proceeding your items from another store will be cleared",
  //       text: "Choose an action:",
  //       icon: "info",
  //       showCancelButton: true,
  //       confirmButtonText: "Proceed",
  //       cancelButtonText: "Go Back",
  //     }).then((result) => {
  //       if (result.isConfirmed) {
  //         handleProceedTo(data);
  //       } else if (result.dismiss === Swal.DismissReason.cancel) {
  //         handleGoBack();
  //       }
  //     });
  //   } else {
  //     let day = "";
  //     if (currentdate) {
  //       day = formatDay(currentdate);
  //     }
  //     const item = {
  //       customer_uid: user.uid,
  //       service_id: data.item.service_id,
  //       store_id: data.item.store_id,
  //       slotTiming: slot,
  //       slotDate: currentdate,
  //       slotDay: day,
  //       pet_name: pet,
  //       resource_name: data.item.service_title,
  //       cart_id: "",
  //       media: data.item.media,
  //       price: data.item.service_price,
  //       drop_off: false,
  //       pickup: false,
  //       dropoff_rate: 0.0,
  //       pickup_rate: 0.0,
  //       pickup_rate_AND_dropoff_rate: 0.0,
  //     };
  //     if (pickUp && !dropOff) {
  //       item.pickup = true;
  //       item.pickup_rate = store?.pickup_rate || 0.0;
  //     } else if (dropOff && !pickUp) {
  //       item.drop_off = true;
  //       item.dropoff_rate = store?.dropoff_rate || 0.0;
  //     } else if (pickUp && dropOff) {
  //       item.pickup = true;
  //       item.drop_off = true;
  //       item.pickup_rate_AND_dropoff_rate =
  //         store?.pickup_rate_AND_dropoff_rate || 0.0;
  //     }
  //     const existItem = state.slots.find(
  //       (ser) => ser.service_id === item.service_id
  //     );
  //     if (existItem) {
  //       cartDelete();
  //       setStore(data.store);
  //       addService(item);
  //       nav("/Cart");
  //     } else {
  //       setStore(data.store);
  //       addService(item);
  //       nav("/Cart");
  //     }
  //   }
  // };

  const getPets = async (id) => {
    try {
      const response = await axios.get(
        `${petSetUrl}/v1/pet/get?owner_id=${id}`,
        {
          headers: commonHeaders,
        }
      );
      if (response.data) {
        // console.log(response.data.data);
        setPets(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getStore = () => {
    const storedLatitude = localStorage.getItem("lat");
    const storedLongitude = localStorage.getItem("lng");
    const Api = `${petSetUrl}//v1/store/get?lng=${storedLongitude}&lat=${storedLatitude}`;
    const requestOptions = {
      method: "GET",
      headers: commonHeaders,
    };
    fetch(Api, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        const matchingRecord = responseData.shops.find(
          (record) => record.sid === data.item.store_id
        );
        setstoreData(matchingRecord);
      })
      .catch((error) => {
        console.error("Error fetching store data from the API:", error);
      });
  };

  useEffect(() => {
    if (user?.uid) {
      getPets(user.uid);
    }

    getStore();
  }, [user]);

  return (
    <>
      <div>
        <p className=" mt-5" style={{ padding: "1% 5%" }}>
          Home > Services > {storeData.store_name}
        </p>
      </div>
      <div
        className="row row-cols-1 row-cols-md-2 g-4"
        style={{ padding: "0% 5%" }}
      >
        <img src={data?.item?.media} alt="" width="auto" height="430px" />

        <div className="mt-8">
          <p className="">
            {Array(5)
              .fill(null)
              .map((_, starIndex) => (
                <img
                  className="mb-1"
                  key={starIndex}
                  src={star1}
                  alt=""
                  width="16px"
                />
              ))}
            <span className="small">5.0</span>
          </p>
          <h3>{data?.item?.service_title}</h3>
          {/* <p className="w-75 mt-5" style={{ fontSize: "16px" }}>
            {data?.item?.service_desc}
          </p> */}
          <h5>AED: {data?.item?.service_price}</h5>
          <div className="row">
            <div className="col-6">
              <h6 className="w-100" style={{ fontSize: "14px" }}>
                Select Date
              </h6>
              <input
                type="date"
                className="w-75 border rounded-3"
                value={currentdate}
                onChange={(e) => setcurrentdate(e.target.value)}
                min={formattedDate}
                disabled
                style={{
                  padding: "0.375rem 2.25rem 0.375rem 0.75rem",
                  borderWidth: "2px",
                  borderColor: "#007bff",
                  outline: "none",
                }}
              />
            </div>
            <div className="col-6">
              <h6 tyle={{ fontSize: "14px" }}>Slot Time</h6>
              <select
                className="form-select w-75 rounded-3 border"
                style={{ boxShadow: "none", border: "none" }}
                onChange={(e) => {
                  // const parsedTime = parse(
                  //   e.target.value,
                  //   "h:mm:ss a",
                  //   new Date()
                  // );
                  // const Time = format(parsedTime, "HH:mm:ss a");
                  // setSlot(Time);
                  const parsedTime = parse(
                    e.target.value,
                    "h:mm:ss a",
                    new Date()
                  );
                  const formattedTime = format(parsedTime, "hh:mm:ss a");
                  setSlot(formattedTime);
                }}
              >
                <option value="" disabled selected>
                  {localStorage.getItem("select time")}
                </option>
                {data?.slots
                  ? data.slots.map((item, i) => (
                      <option value={item} key={i}>
                        {formattedTimes(item)}
                      </option>
                    ))
                  : null}
              </select>
            </div>
            {/* <div className="col-6">
              <h6 style={{ fontSize: "14px" }}>Select Pet</h6>
              {user ? (
                <select
                  className="form-select w-75 rounded-3 border"
                  style={{ boxShadow: "none", border: "none" }}
                  onChange={(e) => setPet(e.target.value)}
                >
                  <option value="" disabled selected>
                    Select Your Pet
                  </option>
                  {pets
                    ? pets.map((item, i) => (
                        <option value={item.pet_name} key={i}>
                          {item.pet_name}
                        </option>
                      ))
                    : null}
                </select>
              ) : (
                <button
                  className="w-75 rounded-5 border"
                  style={{ fontFamily: "black" }}
                  onClick={() => setIsLoginModalOpen(true)}
                >
                  Login first
                </button>
              )}
            </div> */}
            <div className="col-6 mt-2">
              <h6 style={{ fontSize: "14px" }}>Select Pet</h6>
              {user ? (
                pets && pets.length > 0 ? (
                  <select
                    className="form-select w-75 rounded-3 border"
                    style={{ boxShadow: "none", border: "none" }}
                    onChange={(e) => setPet(e.target.value)}
                  >
                    <option value="" disabled selected>
                      Select Your Pet
                    </option>
                    {pets.map((item, i) => (
                      <option value={item.pet_name} key={i}>
                        {item.pet_name}
                      </option>
                    ))}
                  </select>
                ) : (
                  <button
                    className="border rounded-5 w-75"
                    style={{ fontFamily: "black", backgroundColor: "#e0d9fa" }}
                    onClick={() => {
                      nav("/NewPet");
                    }}
                  >
                    Create Pet
                  </button>
                )
              ) : (
                <button
                  className="w-75 rounded-5 border"
                  style={{ fontFamily: "black" }}
                  onClick={() => setIsLoginModalOpen(true)}
                >
                  Login first
                </button>
              )}
            </div>
            <div className="col-6 mt-2">
              <h6 style={{ fontSize: "14px" }}>Pickup Needed</h6>
              <select
                className="form-select w-75 rounded-3 border"
                style={{ boxShadow: "none", border: "none" }}
                onChange={(e) => pickDrop(e.target.value)}
              >
                <option value="" disabled selected>
                  Chose Pickup
                </option>
                <option value="pickup">Pickup Required</option>
                <option value="dropoff">Dropoff Required</option>
                <option value="both">Both Required</option>
                <option value="self">Pickup & Dropoff - self</option>
              </select>
            </div>
          </div>
          <LoginModal
            isOpen={isLoginModalOpen}
            onRequestClose={closeLoginModal}
          />
          <button
            className="border-0 rounded-5 mt-5 foodbtn"
            style={{ fontFamily: "black" }}
            // onClick={() => {
            //   user
            //     ? pet
            //       ? handleBooking()
            //       : Swal.fire("Select Your Pet First")
            //     : setIsLoginModalOpen(true);
            // }}

            onClick={() => {
              if (!slot) {
                Swal.fire("Please select a slot before booking");
                return;
              }

              user
                ? pet
                  ? handleBooking()
                  : Swal.fire("Select Your Pet First")
                : setIsLoginModalOpen(true);
            }}
          >
            BOOK NOW
          </button>
        </div>
      </div>
    </>
  );
};
export default ServiceProduct;
