import { Link } from "react-router-dom";
// import App from "../images/DownloadApp.png";
import AppStore from "../images/Appstore.png";
import PlayStore from "../images/playstore.png";

const Footer = () => {
  return (
    <>
      <div className="row mt-5 pt-4 border-top" style={{ margin: "0 5%" }}>
        <div className="col-12 col-sm-12 col-md-6 col-lg-6">
          <p className="w-sm-100 w-md-50 w-lg-25">
            Pet Set GO is the first mobile app in the Middle East region that
            fully caters to your pets' needs. Whether they have fur, feathers,
            or scales, we've got something for everyone.
          </p>
          <Link to="https://apps.apple.com/pk/app/pet-set-go/id1667478871">
            <img
              className="mt-2 ms-3 ms-md-0"
              src={AppStore}
              alt=""
              width="130px"
              height="40px"
            />
          </Link>
          <Link to="https://play.google.com/store/apps/details?id=com.ventures.psg&pli=1">
            <img
              className="mt-2 ms-3"
              src={PlayStore}
              alt=""
              width="130px"
              height="40px"
            />
          </Link>
        </div>
        <div className="col-2 d-sm-none d-md-block d-lg-block">
          {/* <ul style={{ listStyle: "none", lineHeight: "2" }}>
            <li style={{ fontFamily: "black" }}>Company</li>
            <li>About</li>
            <li>Blog</li>
            <li>Jobs</li>
            <li>Press</li>
            <li>Partners</li>
          </ul> */}
        </div>
        <div className="col-sm-12 col-md-2">
          <ul style={{ listStyle: "none", lineHeight: "2" }}>
            <li style={{ fontFamily: "black" }}>Legal</li>
            {/* <li>Claim</li> */}
            <Link
              to="/privacy-policy"
              className="text-decoration-none text-dark"
            >
              <li>Privacy</li>
            </Link>
            <Link to="/Terms" className="text-decoration-none text-dark">
              <li>Terms</li>
            </Link>
          </ul>
        </div>
      </div>
      <div className="border-top pt-3 mt-3" style={{ margin: "0 5%" }}>
        <p className="small">© 2023 Pet Set Go.</p>
      </div>
    </>
  );
};
export default Footer;
