import { useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import { commonHeaders,petSetUrl } from "../components/utils";
const ForgetPass = () => {
  const [email, setEmail] = useState("");
  const SendReset = () => {
    if (email === "") {
      Swal.fire("Field required");
    } else {
      const emaildata = {
        email: email,
      };
      axios
        .post(`${petSetUrl}/v1/customer/reset`, emaildata, {
          headers: commonHeaders,
        })
        .then((data) => {
          // console.log("API Response:", data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };
  return (
    <>
      <div className="d-flex justify-content-center align-items-center mt-5">
        <div className="row">
          <div className="col-12">
            <h2 className="text-center">Forget Password</h2>
            <div>
              <label style={{ fontFamily: "black" }} className="mt-5 small">
                Email
              </label>
              <br />
              <input
                type="email"
                value={email}
                className="w-100 border rounded-3 mt-2"
                placeholder="Enter your email"
                style={{
                  padding: "0.375rem 2.25rem 0.375rem 0.75rem",
                  borderWidth: "2px",
                  borderColor: "#007bff",
                  outline: "none",
                }}
                onChange={(e) => setEmail(e.target.value)}
              />
              <br />
              <button
                className="border-0 rounded-5 px-4 py-2 mt-3 small w-100"
                style={{ fontFamily: "black", backgroundColor: "#E0D9FA" }}
                onClick={SendReset}
              >
                Sent Reset Link
              </button>
              <p className="text-center small mt-3">
                Already have an account? Login{" "}
                <Link
                  to="/Login"
                  className="text-decoration-none"
                  style={{ color: "#E0D9FA" }}
                >
                  here
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgetPass;
