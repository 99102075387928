import { useNavigate, useLocation } from "react-router-dom";
import PaymentModal from "../../PaymentModal";
import Swal from "sweetalert2";
import CartContext from "../../Cart/Cart";
import { useContext, useState } from "react";
import { petSetUrl, commonHeaders } from "../utils";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import { useEffect } from "react";

const Checkoutproceed = () => {
  const { store, state, selectedAddress } = useContext(CartContext);
  // console.log(selectedAddress?.first_name);
  const [apiData, setApiData] = useState([]);
  const getCart = async (id) => {
    try {
      const response = await axios.get(`${petSetUrl}/v1/cart/get?uid=${id}`, {
        headers: commonHeaders,
      });
      if (response) {
        // console.log(response.data.data);
        setApiData(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCart(state.uid || localStorage.getItem("uid"));
  }, [state]);

  const Nav = useNavigate();
  const location = useLocation();
  const [DelieveryNote, setDelieveryNote] = useState(null);
  const [showModal, setShowModal] = useState(false);
  // console.log("Delievery Note is :", DelieveryNote);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  // const orderID = location.state.OrderId;
  const totalprice = location.state.total_price;
  const SubTotal = location.state.sub_total;
  const Tax = location.state.tax;
  const CoupnsAppied = location.state.couponApply;
  const CouponCode = location.state.couponName;
  const Address = JSON.parse(localStorage.getItem("selectedAddress"));
  // console.log(CartSelectedAddress);
  const CartSelectedAddress = selectedAddress || Address;

  // console.log(CartSelectedAddress);

  // console.log(CoupnsAppied);
  // console.log(CouponCode);
  // const totalprice = price 100;
  const handleConfirmCheckout = () => {
    // Perform the checkout logic here
    if (!DelieveryNote || DelieveryNote.trim() === "") {
      // Set DelieveryNote to an empty string
      setDelieveryNote("");
    }
    handleCloseModal();
  };

  // const handlepayment = () => {
  //   Nav(
  //     "/Payment"
  //     , {
  //       state: {
  //         OrderId: orderID,
  //         total: totalprice,
  //       },
  //     }
  //   );
  // };

  function calculateDistance(lat1, lon1, lat2, lon2) {
    const R = 6371; // Radius of the Earth in kilometers
    const dLat = toRadians(lat2 - lat1);
    const dLon = toRadians(lon2 - lon1);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRadians(lat1)) *
        Math.cos(toRadians(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = R * c; // Distance in kilometers

    return distance;
  }
  function toRadians(degrees) {
    return degrees * (Math.PI / 180);
  }

  const handleCheckout = async () => {
    // console.log(CartSelectedAddress);
    if (!CartSelectedAddress || Object.keys(CartSelectedAddress).length === 0) {
      Swal.fire("Please select an address before proceeding to checkout.");
      return;
    }

    const userLat = parseFloat(CartSelectedAddress.lat_lng.coordinates[0]);
    const userLng = parseFloat(CartSelectedAddress.lat_lng.coordinates[1]);
    // console.log("User lat_lng: ", userLat, userLng);

    const storeLat = apiData.store.lat_lng.coordinates[0];
    const storeLng = apiData.store.lat_lng.coordinates[1];
    // console.log("store lat_lng: ", storeLat, storeLng);

    const distance = calculateDistance(userLat, userLng, storeLat, storeLng);
    // console.log("Store Distance", distance);
    const deliveryAddressRadious =
      typeof apiData.store.deliveryRadius === "number" &&
      apiData.store.deliveryRadius > 0
        ? apiData.store.deliveryRadius
        : 15;

    // console.log("Delievery Radius", deliveryAddressRadious);

    if (distance > deliveryAddressRadious) {
      Swal.fire(
        "Please change the address. The selected address is too far from the store."
      );
      return;
    }
    if (DelieveryNote === null) {
      handleShowModal();
    } else {
      try {
        const apiUrl = `${petSetUrl}/v2/order/add`;
        const params = {
          customer_id: localStorage.getItem("uid"),
          seller_id: "1tziGfeN8SW8rQG8L5whk5nD2Bn1",
          store_id: apiData.shop_id,
          order_status: "Pending",
          address: {
            fName:
              localStorage.getItem("firstname") ||
              CartSelectedAddress.first_name,
            address: CartSelectedAddress.address1,
            country: CartSelectedAddress.country,
            state: CartSelectedAddress.state,
            city: CartSelectedAddress.city,
            pincode: "",
            phone: localStorage.getItem("Phone") || CartSelectedAddress.phone,
            buildingName: CartSelectedAddress.building_name,
            buildingNum: CartSelectedAddress.building_num,
            email: localStorage.getItem("Email") || CartSelectedAddress.email,
            lat: localStorage.getItem("lat"),
            lng: localStorage.getItem("lng"),
          },
          total_price: totalprice.toFixed(2).toString(),
          cartCartId: apiData.cart_id,
          discount_price: "0.0",
          discount_type: CoupnsAppied,
          pickupPrice: 0.0,
          coupons: CouponCode,
          delivery_notes: DelieveryNote,
          redeemablePoints: 0,
        };

        const response = await axios.post(apiUrl, params, {
          headers: {
            Authorization: commonHeaders,
          },
        });
        // console.log(response);
        // console.log(response.data.data.order_id);
        Nav("/Payment", {
          state: {
            // OrderId: response.data.data.order_id,
            // total_price: total,

            OrderId: response.data.data.order_id,
            total: totalprice,
          },
        });
        // cartDelete();
      } catch (error) {
        console.log(error);

        if (error.response) {
          console.error("Response data:", error.response.data);
          // console.error("Response status:", error.response.status);
          // console.error("Response headers:", error.response.headers);
        }
        // else if (error.request) {
        //   console.error("No response received:", error.request);
        // } else {
        //   console.error("Error during request setup:", error.message);
        // }
      }
    }
  };

  return (
    <>
      {/* <div className="border p-4">
        <div className="row pt-2">
          <p className="col-7">Subtotal</p>
          <h6 className="col-5">AED : {(totalprice / 1.05).toFixed(2)}</h6>
        </div>
        <div className="row pt-2">
          <p className="col-7">Tax</p>
          <h6 className="col-5">
            AED : {(totalprice - (totalprice / 1.05).toFixed(2)).toFixed(2)}
          </h6>
        </div>
        <div className="row">
          <p className="col-7">Total</p>
          <h6 className="col-5">AED : {totalprice.toFixed(2)}</h6>
        </div>

        <button
          className="border-0 rounded-5 p-2 px-5"
          style={{ backgroundColor: "#E0D9FA", fontFamily: "black" }}
          onClick={handleCheckout}
        >
          Proceed to checkout
        </button>
      </div> */}
      <div className="border p-4">
        <div className="row pt-2">
          <p className="col-7">Subtotal</p>
          <h6 className="col-5">AED : {SubTotal?.toFixed(2)}</h6>
        </div>
        <div className="row pt-2">
          <p className="col-7">Tax</p>
          <h6 className="col-5">AED : {Tax?.toFixed(2)}</h6>
        </div>
        <div className="row">
          <p className="col-7">Total</p>
          <h6 className="col-5">AED : {totalprice?.toFixed(2)}</h6>
        </div>

        <button
          className="border-0 rounded-5 p-2 px-5"
          style={{ backgroundColor: "#E0D9FA", fontFamily: "black" }}
          onClick={handleCheckout}
        >
          Proceed to checkout
        </button>
      </div>
      <PaymentModal />
      <div className="pt-4">
        {/* <h6>Promocode & Gifts</h6>
        <p className="w-100">
          Note: One discount can be redeemed per order. Check our terms &
          conditions.
        </p> */}
        {/* {CheckoutSection} */}

        {/* <div className="row pt-3" style={{ backgroundColor: "#F2EDFF" }}>
          <div className="d-flex col-9">
            <img src={checkout} alt="" height="20px" />
            <div className=" ms-3">
              <h6 className="small">PETSET50</h6>
              <p className="small">50% off on all items</p>
            </div>
          </div>
          <p className="small text-danger col-2">Remove</p>
        </div> */}

        <Modal
          show={showModal}
          // onHide={handleCloseModal}
          className="custom-modal"
        >
          {/* <Modal.Header></Modal.Header> */}
          <Modal.Body>
            <Modal.Title>Delivery Note</Modal.Title>
            <textarea
              rows="4"
              // cols="62"
              placeholder="Enter delivery note here..."
              style={{ width: "100%", marginTop: "12px" }}
              onChange={(e) => setDelieveryNote(e.target.value)}
            />
            <Button
              variant="primary"
              onClick={handleConfirmCheckout}
              style={{
                marginTop: "24px",
                float: "right",
              }}
            >
              Submit
            </Button>
          </Modal.Body>
          {/* <Modal.Footer>
         
        </Modal.Footer> */}
        </Modal>
      </div>
    </>
  );
};
export default Checkoutproceed;