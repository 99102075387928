import React, { useContext } from "react";
import storeimage from "../../images/store.png";
import locate from "../../images/location.png";
import star from "../../images/star.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import Loader from "../../components/Home/Loader";
import Swal from "sweetalert2";
import CartContext from "../../Cart/Cart";

export default function ServiceStores({ storeData, title }) {
  const nav = useNavigate();
  const { setStore, state, user, cartDelete } = useContext(CartContext);
  const location = useLocation();
  // const handleGoBack = () => {
  //   // console.log("Go back clicked");
  // };

  // const handleProceedTo = (item) => {
  //   cartDelete();
  //   setStore(item);
  //   nav(`/Services/Store`, {
  //     state: {
  //       data: item,
  //     },
  //   });
  // };

  const handleStoreNav = (item) => {
    // console.log(item);
    // const hasData =
    //   (state.slots && state.slots.length > 0) ||
    //   (state.products && state.products.length > 0);

    // if (hasData && state.shop_id !== item.sid) {
    //   Swal.fire({
    //     title: "You already have items in cart from another Store",
    //     text: "Choose an action:",
    //     icon: "info",
    //     showCancelButton: true,
    //     confirmButtonText: "Proceed To",
    //     cancelButtonText: "Go Back",
    //   }).then((result) => {
    //     if (result.isConfirmed) {
    //       handleProceedTo(item);
    //     } else if (result.dismiss === Swal.DismissReason.cancel) {
    //       handleGoBack();
    //     }
    //   });
    // } else {
    // setStore(item);
    nav(`/Services/Store`, {
      state: {
        data: item,
      },
    });
    // }
  };

  return (
    <div className="Services_Stores">
      <h1 className="text-bold mb-4" style={{ fontSize: "2.5vw" }}>
        {title}
      </h1>
      <div className="row row-cols-xs-1 row-cols-md-2 row-cols-lg-3 g-4 stores stores_conteiner">
        {Array.isArray(storeData) && storeData.length > 0 ? (
          storeData.map((item, index) => (
            <div className="col d-flex justify-content-center" key={index}>
              <div className="card border-0" key={index}>
                <div
                  style={{
                    width: "285px",
                    height: "240px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={
                      item.media.substring(0, 4) === "http"
                        ? item.media
                        : item.media === ""
                        ? storeimage
                        : "https://petsetgostorage.blob.core.windows.net/petsetgo-u2/" +
                          item.media
                    }
                    alt="media not found"
                    // width="285px"
                    // height="240px"
                    className="card-img-top foodimg"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <div
                  className="d-flex justify-content-between"
                  style={{ width: "285px" }}
                >
                  <p>
                    <img className="mb-1" src={locate} alt="" width="16px" />{" "}
                    <span className="small">
                      {item.distance.toFixed(2)} KM away
                    </span>
                  </p>
                  <p>
                    {Array(Math.floor(item.averageRatings))
                      .fill(null)
                      .map((_, starIndex) => (
                        <img
                          className="mb-1"
                          key={starIndex}
                          src={star}
                          alt=""
                          width="16px"
                        />
                      ))}
                    <span className="small">
                      {item.averageRatings.toFixed(1)}
                    </span>
                  </p>
                </div>
                <div className="title">
                  <p className="text-dark text-decoration-none">
                    <h5
                      className="mb-3"
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        maxWidth: "100%",
                      }}
                    >
                      {item.store_name}
                    </h5>{" "}
                  </p>

                  <button
                    className="productbtn"
                    style={{ width: "285px" }}
                    onClick={() => {
                      handleStoreNav(item);
                    }}
                  >
                    View Services
                  </button>
                </div>
              </div>
            </div>
          ))
        ) : (
          <>
            {/* <Loader /> */}
            No Store found, kindly change your date and search again
          </>
        )}
      </div>
    </div>
  );
}
