import React, { useState, useEffect } from "react";
// import cat from "../../images/cat.png";
import Day_Care from "../../images/Day_Care.png";
// import vet_home from "../../images/Vet_Home.png";
import date from "../../images/date.png";

// const articleData = [
//   {
//     image: cat,
//     date: "June 12, 2023",
//     title: "New dishes every day according to your menu",
//     content:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
//   },
//   {
//     image: Day_Care,
//     date: "June 12, 2023",
//     title: "New dishes every day according to your menu",
//     content:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
//   },
//   {
//     image: vet_home,
//     date: "June 12, 2023",
//     title: "New dishes every day according to your menu",
//     content:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
//   },
// ];

const Articles = () => {
  const [articleData, setarticleData] = useState({});
  useEffect(() => {
    const storeApiUrl = `https://cms.petsetgo.com/api/blogs?sort=desc&populate=*&pagination[page]=0&pagination[pageSize]=100`;
    const requestOptions = {
      method: "GET",
      headers: {
        accept: "application/json",
        Authorization:
          "Bearer 983c41cdf0c329784bdbde473a2e7146559e94bd135032de86eda6291302310da86249e439452bb1fd7442076eeece9b5764c2b864df3b294a39d64c76f45c2d3ada8e68cf542c952aa04fd6afade2ffd41ac2b45c721a94a3d998d180d24fc86752a926b547a9e58eee1c3b036b176d08d875d0bfc8d65135d7b4d10e1c8de3",
      },
    };
    fetch(storeApiUrl, requestOptions)
      .then((response) => response.json())
      .then((storeResponseData) => {
        setarticleData(storeResponseData.data);
      })
      .catch((error) => console.log("error", error));
  }, []);

  return (
    // <div className="Latest_Articles container-fluid">
    //   <h1 className="Articles_heading">Latest Articles</h1>
    //   <div className="ArticleCardWrapper">
    //     {Array.isArray(articleData) ? (
    //       articleData.map((article, index) => (
    //         <div className="articlecard" key={index}>
    //           <img
    //             className="article_image"
    //             src={Day_Care}
    //             alt=""
    //             style={{ backgroundColor: "#FFF7ED" }}
    //           />
    //           <p className="mt-3">
    //             <img className="mb-1" src={date} alt="" width="16px" />{" "}
    //             <span className="ms-2">
    //               {new Date(article.attributes.createdAt).toLocaleString(
    //                 "en-US",
    //                 {
    //                   year: "numeric",
    //                   month: "long",
    //                   day: "numeric",
    //                 }
    //               )}{" "}
    //             </span>
    //           </p>
    //           <div className="title Article_title">
    //             <h5>{article.attributes.title}</h5>
    //             <p>{article.attributes.content.substring(0, 250)}...</p>
    //           </div>
    //         </div>
    //       ))
    //     ) : (
    //       <>Loading....</>
    //     )}
    //   </div>
    // </div>

    <div style={{ padding: "0 5%", backgroundColor: "#F7F7F7" }}>
      <h1 className="py-4">Latest Articles</h1>

      <div className="row row-cols-xs-1 row-cols-md-2 row-cols-lg-3 g-4 ">
        {Array.isArray(articleData) && articleData.length > 0 ? (
          articleData.map((article, index) => (
            <div className="col Articles_New_card" key={index}>
              <div
                className="card border-0"
                key={index}
                style={{ backgroundColor: "#F7F7F7" }}
              >
                <img
                  className="border article_image"
                  src={
                    "https://cms.petsetgo.com" +
                    article.attributes.media.data[0].attributes.url
                  }
                  alt="media not found"
                />
                <div className="d-flex">
                  <p className="mt-3">
                    <img className="mb-1" src={date} alt="" width="16px" />{" "}
                    <span className="ms-2">
                      {new Date(article.attributes.createdAt).toLocaleString(
                        "en-US",
                        {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        }
                      )}{" "}
                    </span>
                  </p>
                </div>
                <div className="Article_title">
                  <h5
                    className="mb-3"
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      maxWidth: "100%",
                    }}
                  >
                    {article.attributes.title}
                  </h5>
                  <p>{article.attributes.content.substring(0, 250)}...</p>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>Loading.....</p>
        )}
      </div>
    </div>
  );
};

export default Articles;
