// PaymentComponent.jsx

import axios from "axios";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { commonHeaders, petSetUrl } from "./components/utils";
const PaymentComponent = () => {
  const location = useLocation();
  const orderID = location.state.OrderId;
  // const totalprice = (location.state.total * 100).toFixed(2).toString();
  const totalprice = Math.round(location.state.total * 100);


  useEffect(() => {
    initiatePayment();
  }, []);

  const initiatePayment = async () => {
    const params = {
      command: "AUTHORIZATION",
      access_code: "PC8iIGFRkPtgz0oHNfMw",
      merchant_identifier: "UZafmfHa",
      merchant_reference: orderID, 
      amount: totalprice, //order_amonunt*1000   10*1000=10000
      currency: "AED",
      language: "en",
      customer_email: localStorage.getItem("Email"), //user email
      return_url: `${petSetUrl}/api/v1/payment/feedback`,
    };

    // Generate the signature
    // const signature = generatePayFortSignature(params);

    // Include the signature in your API request
    let signature = (
      await axios.get(
        `${petSetUrl}/getSignature?payload=` + JSON.stringify(params)
      )
    ).data;
    const form = document.createElement("form");
    form.method = "POST";
    form.id = "form1";
    form.name = "form1";
    form.action = "https://checkout.payfort.com/FortAPI/paymentPage"; // Use the test environment URL
    // Add input fields for each parameter
    addInputField(form, "access_code", `${params.access_code}`);
    addInputField(form, "amount", `${params.amount}`);
    addInputField(form, "command", "AUTHORIZATION");
    addInputField(form, "currency", `${params.currency}`);
    addInputField(form, "customer_email", `${params.customer_email}`);
    addInputField(form, "language", `${params.language}`);
    addInputField(form, "merchant_identifier", `${params.merchant_identifier}`);
    addInputField(form, "merchant_reference", `${params.merchant_reference}`);
    addInputField(form, "signature", `${signature}`);
    addInputField(form, "return_url", `${params.return_url}`);
    // Add other required fields as per Payfort documentation

    // Append the form to the document body and submit it
    document.body.appendChild(form);
    form.submit();
  };

  const addInputField = (form, name, value) => {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = name;
    input.value = value;
    form.appendChild(input);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <div className="loader"></div>
      </div>
      {/* You can add a loading spinner or other UI elements here */}
    </div>
  );
};

export default PaymentComponent;
