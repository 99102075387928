import Modal from "react-modal";
import PaymentComponent from "./PaymentComponent";

Modal.setAppElement("#root");

const PaymentModal = ({ isOpen, onRequestClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Payment Modal Modal"
      style={{
        content: {
          width: "40%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          margin: "auto",
          marginTop: "4%",
          // position:"fixed",
          // zIndex:"50"
        },
      }}
    >
      <PaymentComponent />
    </Modal>
  );
};

export default PaymentModal;
