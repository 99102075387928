import { useNavigate } from "react-router-dom";
import CartContext from "../../Cart/Cart";
import { useContext } from "react";
import { Link } from "react-router-dom";

const Groaming = () => {
  const { ServiceType, setServiceType } = useContext(CartContext);
  const nav = useNavigate();
  return (
    <>
      <div className="card_section1">
        <div className="Grooming">
          <h1 className="Grooming_heading">Grooming</h1>
          <p className="Grooming_paragraph">
            Pamper, Primp, and Purrfection: Unleashing the True Beauty in Every
            Pet!
          </p>
          <button
            className="Grooming_button"
            onClick={() => {
              nav("/Services", {
                state: {
                  data: "Grooming",
                },
              });
              setServiceType("Grooming");
            }}
          >
            Book Now
          </button>
        </div>
        <div className="Veterinary">
          <h1 className="Veterinary_heading">Veterinary</h1>
          <p className="Veterinary_paragraph">
            Caring for Companions, Healing with Heart: Your Trusted Partner in
            Pet Wellness
          </p>
          <button
            className="Veterinary_button"
            onClick={() => {
              nav("/Services", {
                state: {
                  data: "Veterinary",
                },
              });
              setServiceType("Veterinary");
            }}
          >
            Book Now
          </button>
        </div>

        <div className="Day_Care">
          <h1 className="Day_Care_heading">Video Call</h1>
          <p className="Day_Care_paragraph">
            Bringing Expert Care to Your Furry Friend, Virtually! Just a Click
            Away.
          </p>
          <Link to="VideoCall">
            <button className="Day_Care_button">Book Now</button>
          </Link>
        </div>
        {/* <div className="Day_Care">
          <h1 className="Day_Care_heading">Day Care</h1>
          <p className="Day_Care_paragraph">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </p>
          <button
            className="Day_Care_button"
            onClick={() => {
              nav("/Services", {
                state: {
                  data: "Day Care",
                },
              });
              setServiceType("Day Care");
            }}
          >
            Get Started
          </button>
        </div> */}
      </div>
    </>
  );
};

export default Groaming;
