import FiftyOff from "../components/Home/FiftyOff";
import NearMeStores from "../components/NearMe/NearMeStores";
import { useState, useEffect } from "react";
import { MarkerF, useJsApiLoader, GoogleMap } from "@react-google-maps/api";
import { commonHeaders, petSetUrl } from "../components/utils";
// import MapMarker from "./Marker";

const NearMe = () => {
  const storedLatitude = localStorage.getItem("lat");
  const storedLongitude = localStorage.getItem("lng");
  const { isLoaded } = useJsApiLoader({
    id: "AIzaSyC-28dhX46EXos0B_fx9cuUWEpWZEMIQCc",
    googleMapsApiKey: "AIzaSyC-28dhX46EXos0B_fx9cuUWEpWZEMIQCc",
    onLoad: () => console.log("Google Maps API loaded successfully"),
  });
  const containerStyle = {
    width: "100%",
    height: "600px",
  };

  const postion = {
    lat: 31.2223332,
    lng: 74.2544051,
  };
  const [Data, setData] = useState([]);
  // console.log(Data);
  useEffect(() => {
    const storeApiUrl = `${petSetUrl}/v1/store/get?lng=${storedLongitude}&lat=${storedLatitude}`;
    const requestOptions = {
      method: "GET",
      headers: commonHeaders,
    };
    fetch(storeApiUrl, requestOptions)
      .then((response) => response.json())
      .then((storeResponseData) => {
        // console.log(storeResponseData);
        setData(storeResponseData.shops);
      })
      .catch((error) => console.log("error", error));
  }, [storedLongitude, storedLatitude]);
  console.log(
    Data.map((item) => {
      return item.lat_lng.coordinates[0];
    })
  );
  return (
    <>
      <div className="lh-1" style={{ padding: "0 6%" }}>
        <p className="mt-5">Home {">"} Near Me</p>
        <h1 className="text-bold">Near Me</h1>
      </div>
      <div className="mt-5 row ps-5 ps-md-5 NearMeStores">
        <div className="col-lg-7 col-md-5 col-10 ">
          <NearMeStores storeData={Data} />
        </div>
        <div className="col-lg-5 col-md-6 col-10">
          {isLoaded ? (
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={postion}
              zoom={10}
            >
              {Data.map((item, index) => {
                return (
                  <div key={index}>
                    <MarkerF
                      position={{
                        lat: item.lat_lng.coordinates[0],
                        lng: item.lat_lng.coordinates[1],
                      }}
                      // icon={{
                      //   url: "",
                      //   scaledSize: new window.google.maps.Size(30, 30),
                      // }}
                    />
                    {/* <MapMarker /> */}
                  </div>
                );
              })}
            </GoogleMap>
          ) : (
            <div>Loading Google Maps...</div>
          )}
        </div>
      </div>

      <FiftyOff />
    </>
  );
};
export default NearMe;
