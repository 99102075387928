const PetRelocation = () => {
  return (
    <>
      <div className="lh-1" style={{ padding: "0 5%" }}>
        <p className="mt-5">Home > Your Account > Relocation Form</p>
        <h1 className="text-bold">Pet Relocation Form</h1>
      </div>

      <div
        style={{ margin: "0 5%", width: "45%", backgroundColor: "#F4F4F5" }}
        className="mt-5"
      >
        <div className="p-5">
          <label style={{ fontFamily: "black" }} className="small">
            Relocation From
          </label>
          <br />
          <input
            type="text"
            className="w-100 border rounded-3 mt-2"
            style={{
              padding: "0.375rem 2.25rem 0.375rem 0.75rem",
              borderWidth: "2px",
              borderColor: "#007bff",
              outline: "none",
            }}
          />
          <br />
          <label style={{ fontFamily: "black" }} className="mt-2 small">
            Relocate To
          </label>
          <br />
          <input
            type="text"
            className="w-100 border rounded-3 mt-2"
            style={{
              padding: "0.375rem 2.25rem 0.375rem 0.75rem",
              borderWidth: "2px",
              borderColor: "#007bff",
              outline: "none",
            }}
          />
          <br />
          <label style={{ fontFamily: "black" }} className="mt-2 small">
            Round Trip
          </label>
          <br />
          <input
            type="text"
            className="w-100 border rounded-3 mt-2"
            style={{
              padding: "0.375rem 2.25rem 0.375rem 0.75rem",
              borderWidth: "2px",
              borderColor: "#007bff",
              outline: "none",
            }}
          />
          <br />
          <label style={{ fontFamily: "black" }} className="mt-2 small">
            Pet
          </label>
          <br />
          <input
            type="text"
            className="w-100 border rounded-3 mt-2"
            style={{
              padding: "0.375rem 2.25rem 0.375rem 0.75rem",
              borderWidth: "2px",
              borderColor: "#007bff",
              outline: "none",
            }}
          />
          <br />
          <label style={{ fontFamily: "black" }} className="mt-2 small">
            Reclocation Date
          </label>
          <br />
          <input
            type="date"
            className="w-100 border rounded-3 mt-2"
            placeholder="Date"
            style={{
              padding: "0.375rem 2.25rem 0.375rem 0.75rem",
              borderWidth: "2px",
              borderColor: "#007bff",
              outline: "none",
            }}
          />
          <br />
          <label style={{ fontFamily: "black" }} className="mt-2 small">
            Additional Information
          </label>
          <br />
          <input
            type="text"
            className="w-100 border rounded-3 mt-2"
            style={{
              padding: "0.375rem 2.25rem 0.375rem 0.75rem",
              borderWidth: "2px",
              borderColor: "#007bff",
              outline: "none",
            }}
          />
          <br />
          <button
            className="border-0 rounded-5 px-4 py-2 mt-3 small"
            style={{ fontFamily: "black", backgroundColor: "#E0D9FA" }}
          >
            Submit
          </button>
        </div>
      </div>
    </>
  );
};
export default PetRelocation;
