// import CartProductPrice from "../components/Cart/CartProductPrice";
import Checkoutdetail from "../components/Checkout/Checkoutdetail";
import Checkoutproceed from "../components/Checkout/Checkoutproceed";
// import CheckoutPrice from "../components/Checkout/CheckoutPrice";
import { useLocation } from "react-router-dom";
// import PaymentComponent from "../PaymentComponent";
const Checkout = () => {
  const location = useLocation();
  // const navigate = useNavigate();
  // const orderID = location.state.OrderId;
  // const totalprice = location.state.total_price;
  // console.log(orderID, totalprice);
  // const handleNavigation = (id) => {
  //   const query = "false";
  //   navigate(/paymentStatus/${id}?success=${query});
  // };
  return (
    <>
      <>
        <div className="lh-1" style={{ padding: "1% 5%" }}>
          <p className="mt-5">Home > Checkout</p>
          <h1 className="text-bold">Checkout</h1>
        </div>
        <div className="row ms-0 ms-md-5 mt-5">
          <div className="col-lg-8 col-md-7">
            <Checkoutdetail />
          </div>
          <div className="col-lg-3 col-md-4 ms-0 mt-3 mt-md-0 ms-md-4">
            <Checkoutproceed />
            {/* <CartProductPrice /> */}
            {/* <button onClick={() => handleNavigation(orderID)}>
              Proceed to checkout
            </button> */}
          </div>
        </div>
      </>
    </>
  );
};
export default Checkout;