const Notification = () => {
  const NotificationData = [
    {
      from: "Tom & Jerry Pet Shop",
      message: "Your PSG Order ID#0000000 is created and payment is pending.",
      dateTime: "01:43 PM - 10/10/2023",
    },
    {
      from: "Tom & Jerry Pet Shop",
      message: "Your PSG Order ID#0000000 is created and payment is pending.",
      dateTime: "01:43 PM - 10/10/2023",
    },
    {
      from: "Tom & Jerry Pet Shop",
      message: "Your PSG Order ID#0000000 is created and payment is pending.",
      dateTime: "01:43 PM - 10/10/2023",
    },
    {
      from: "Tom & Jerry Pet Shop",
      message: "Your PSG Order ID#0000000 is created and payment is pending.",
      dateTime: "01:43 PM - 10/10/2023",
    },
    {
      from: "Tom & Jerry Pet Shop",
      message: "Your PSG Order ID#0000000 is created and payment is pending.",
      dateTime: "01:43 PM - 10/10/2023",
    },
  ];
  return (
    <>
      <div className="lh-1" style={{ padding: "1% 5%" }}>
        <p className="mt-5">Home > Your Account > Notifications</p>
        <h1 className="text-bold">Notifications</h1>
      </div>

      <div className="mt-5" style={{ padding: "0 5%" }}>
        <table class="table border">
          <thead style={{ backgroundColor: "black" }}>
            <tr>
              <th className="small bg-light" style={{ fontWeight: "400" }}>
                From
              </th>
              <th className="small bg-light" style={{ fontWeight: "400" }}>
                Message
              </th>
              <th className="small bg-light" style={{ fontWeight: "400" }}>
                Date & Time
              </th>
              <th className="small bg-light" style={{ fontWeight: "400" }}></th>
            </tr>
          </thead>
          <tbody>
            {NotificationData.map((notification, index) => (
              <tr key={index}>
                <td className="small" style={{ fontFamily: "black" }}>
                  {notification.from}
                </td>
                <td className="small">{notification.message}</td>
                <td className="small">{notification.dateTime}</td>
                {/* <td className="text-danger small">View</td> */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Notification;
