import React, { useState, useEffect } from "react";
import locationimg from "../images/user_location.png";
import axios from "axios";
import { commonHeaders } from "./utils";
import { Modal } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";

const NewNav = () => {
  const [location, setLocation] = useState(
    localStorage.getItem("NewAssress") || ""
  );
  const [locationVal, setLocationVal] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState("");
  const [addresses, setAddresses] = useState([]);

  const handleModalClose = () => {
    setShowModal(false);
  };

  useEffect(() => {
    const lat = localStorage.getItem("lat");
    const lng = localStorage.getItem("lng");
    if (!lat || !lng) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, []);

  const handleSearchInputChange = (inputVal) => {
    setLocationVal(inputVal);
    fetchOptions(inputVal);
  };

  const fetchOptions = async (inputVal) => {
    // const apiUrl = `https://petsetgo_staging.petsetgo.com/v1/seller/get/place?placeString=${inputVal}`;
    const apiUrl = `https://testserver.petsetgo.com/v1/seller/search/places?placeString=${inputVal}`;
    try {
      const response = await axios.get(apiUrl, {
        headers: commonHeaders,
      });
      // console.log(response.data.predictions);

      setAddresses(
        response.data.predictions.map((address) => ({
          value: address.description,
          label: address.description,
          placeId: address.place_id,
        }))
      );
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  const handleSelectChange = async (selectedOptions) => {
    if (selectedOptions.length > 0) {
      const selectedOption = selectedOptions[0];
      // console.log(selectedOption);

      setSelectedAddress(selectedOption.value);

      localStorage.setItem("NewAssress", selectedOption.value);
      setLocation(selectedOption.value);
      try {
        const Api = `https://testserver.petsetgo.com/v1/seller/search/places/latlng?place_id=${selectedOption.placeId}`;
        const response = await axios.get(Api, {
          commonHeaders,
        });
        // console.log(response.data.predictions);
        localStorage.setItem("lat", response?.data?.predictions?.lat);
        localStorage.setItem("lng", response?.data?.predictions?.lng);

        window.location.reload();
      } catch (error) {
        console.log(error);
      }

      // const selectedAddressObject = addresses.find(
      //   (address) => address.value === selectedOption.value
      // );

      // console.log(selectedAddressObject);

      // if (selectedAddressObject && selectedAddressObject.lat_lng) {
      //   const { lat, lng } = selectedAddressObject.lat_lng;
      //   const desc = selectedAddressObject.value;
      //   localStorage.setItem("lat", lat);
      //   localStorage.setItem("lng", lng);
      //   localStorage.setItem("NewAssress", desc);
      //   setLocation(desc);
      // }

      setShowModal(false);
      // window.location.reload();
    }
  };

  // console.log(addresses);

  return (
    <>
      <div
        className="py-2"
        style={{
          backgroundColor: "#E0D9FA",
          padding: "0% 4%",
          height: "45px",
        }}
      >
        <div className="d-flex">
          <div className="mt-1 mt-md-0">
            <img src={locationimg} alt="" height="20px" />
          </div>
          <p
            className="ms-2 mt-1 mt-md-0"
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              maxWidth: "100%",
            }}
          >
            Delivery to:
            <span
              style={{
                fontFamily: "black",
                cursor: "pointer",
              }}
              className="ms-1"
              onClick={() => setShowModal(true)}
            >
              {location ? `${location}` : "Choose location"}
            </span>
          </p>

          <Modal
            show={showModal}
            backdrop="static"
            dialogClassName="custom-modal"
          >
            <Modal.Header variant="danger">
              <Modal.Title>Pick your Delivery Area</Modal.Title>
            </Modal.Header>
            <Modal.Body variant="danger">
              <label>Search Location:</label>
              <Typeahead
                id="locationTypeahead"
                labelKey="label"
                options={addresses}
                onChange={(selectedOptions) =>
                  handleSelectChange(selectedOptions)
                }
                onInputChange={(inputVal) => handleSearchInputChange(inputVal)}
                placeholder="Select an address"
              />
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default NewNav;