import Modal from "react-modal";
import { useState } from "react";

Modal.setAppElement("#root");

const OtpModal = ({ isOpen, onRequestClose, onSubmit }) => {
  const [otpValue, setOtpValue] = useState(""); // State to manage OTP input value

  const handleOtpInputChange = (e) => {
    setOtpValue(e.target.value); // Update state as the user types
  };
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="OTP Verify"
      style={{
        content: {
          width: "40%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          margin: "auto",
          marginTop: "4%",
        },
      }}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Enter OTP</h5>
          </div>
          <div className="modal-body">
            <input
              type="text"
              placeholder="Enter OTP"
              value={otpValue}
              onChange={handleOtpInputChange}
            />
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => onSubmit(otpValue)}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default OtpModal;
