import React from "react";
import storeimage from "../../images/store.png";
import locate from "../../images/location.png";
import star from "../../images/star.png";
import { Link, useLocation } from "react-router-dom";

const NearMeStores = ({ storeData }) => {
  const location = useLocation();
  const linkTo =
    location.pathname === "/Services" ? "/ServicesItems" : "/StoreItems";
  return (
    <div className="mt-5 mt-md-0">
      <div className="row row-cols-1 row-cols-sm-2 row-cols-md-1 row-cols-lg-2">
        {storeData.map((store, index) => (
          <div className="col" key={index}>
            <div className="card border-0" key={index}>
              <div
                style={{
                  width: "285px",
                  height: "240px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  overflow: "hidden",
                }}
              >
                <img
                  src={
                    store.media.substring(0, 4) === "http"
                      ? store.media
                      : store.media === ""
                      ? storeimage
                      : "https://petsetgostorage.blob.core.windows.net/petsetgo-u2/" +
                        store.media
                  }
                  className="card-img-top foodimg"
                  alt="media not found"
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    objectFit: "cover",
                  }}
                />
              </div>
              <div
                className="d-flex justify-content-between"
                style={{
                  width: "285px",
                }}
              >
                <p>
                  <img className="mb-1" src={locate} alt="" width="16px" />{" "}
                  <span className="small">
                    {(store.distance / 1000).toFixed(2)} KM away
                  </span>
                </p>
                <p>
                  {Array(Math.floor(store.averageRatings))
                    .fill(null)
                    .map((_, starIndex) => (
                      <img
                        className="mb-1"
                        key={starIndex}
                        src={star}
                        alt=""
                        width="16px"
                      />
                    ))}
                  <span className="small">
                    {" "}
                    {store.averageRatings.toFixed(1)}
                  </span>
                </p>
              </div>
              <div className="title">
                <Link to={linkTo} className="text-dark text-decoration-none">
                  {" "}
                  <h5
                    className="mb-3"
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      maxWidth: "100%",
                    }}
                  >
                    {store.store_name}
                  </h5>{" "}
                </Link>
                {/* <Link to="/Services"> */}
                <button
                  style={{
                    width: "285px",
                  }}
                >
                  View Products
                </button>
                {/* </Link> */}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NearMeStores;
