import downloadback from "../../images/head_back.png";
import downloadMobile from "../../images/download_mobile_image.png";
import downloadcat from "../../images/download_cat_image.png";
// import downloadapp from '../../images/DownloadApp.png'
import AppStore from "../../images/Appstore.png";
import PlayStore from "../../images/playstore.png";
import { Link } from "react-router-dom";
const DownloadApp = () => {
  return (
    <>
      <div className="Download_App">
        <div className="Download_App_images d-none d-sm-block">
          <img className="image1" src={downloadback} alt="" />
          <img className="image2" src={downloadcat} alt="" />
          <img className="image3" src={downloadMobile} alt="" />
        </div>
        <div className="Download_App_text">
          <h6 style={{ color: "#c2f7f2" }}>DOWNLOAD OUR APP</h6>
          <h1 className="">Order Faster With Our App!</h1>
          <div className="row">
            <p className="mt-2 col-10">
              Pet Set GO is the first mobile app in the Middle East region that
              fully caters to your pets' needs. Whether they have fur, feathers,
              or scales, we've got something for everyone.
            </p>
          </div>
          <Link to="https://apps.apple.com/pk/app/pet-set-go/id1667478871">
            <img className="mt-2 AppStore" src={AppStore} alt="" />
          </Link>
          <Link to="https://play.google.com/store/apps/details?id=com.ventures.psg&pli=1">
            <img className="mt-2 ms-3 PlayStore" src={PlayStore} alt="" />
          </Link>
        </div>
      </div>
    </>
  );
};

export default DownloadApp;
