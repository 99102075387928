import { useNavigate } from "react-router-dom";
import { useState } from "react";
// import id from "date-fns/locale/id";

const Checkoutaddress = () => {
  const Nav = useNavigate();

  const [data, setdata] = useState(
    JSON.parse(localStorage.getItem("selectedAddress"))
  );
  // const DeleteCheckAddress = () => {
  //   localStorage.removeItem("selectedAddress");
  //   setdata(null);
  // };
  return (
    <div className="container">
      {data ? (
        <div className="row">
          <div
            className="col-md-6 px-3 py-3 mt-3"
            style={{ backgroundColor: "#F4F4F5" }}
          >
            <h6 className="small">Address #1</h6>
            <div style={{ lineHeight: "1" }} className="mt-3">
              <p className="small">
                {data.first_name} {data.last_name}
              </p>
              <p className="small">{data.phone}</p>
              <p className="small">
                {data.building_num} {data.address1}, {data.country}
              </p>
            </div>
            {/* <div className="row mt-3">
              <p className="col-3 small">
                <span style={{ color: "#E0D9FA", cursor: "pointer" }}>
                  Update
                </span>
              </p>
              <p
                className="col-3 small text-danger"
                style={{ cursor: "pointer" }}
                onClick={DeleteCheckAddress}
              >
                Delete
              </p>
            </div> */}
          </div>
        </div>
      ) : (
        <p className="mt-3">
          Please select any address{" "}
          <span
            className="text-primary"
            style={{ fontWeight: "bold", cursor: "pointer" }}
            onClick={() => {
              Nav("/AccountAddresses"
              // {
              //   state: {
              //     id: orderID,
              //     price: totalprice,
              //   },
              // }
              );
            }}
          >
            Click here
          </span>
        </p>
      )}
      <div className="row mt-3">
        {/* <p
          className="col-md-10"
          style={{ backgroundColor: "#E0D9FA", fontFamily: "black" }}
          onClick={() => {
            Nav("/AccountAddresses");
          }}
        >
          Change Address
        </p> */}
        <button
          className="col-md-4 border-0 rounded-5 p-2 px-5 "
          style={{ backgroundColor: "#E0D9FA", fontFamily: "black" }}
          onClick={() => {
            Nav("/AccountAddresses");
          }}
        >
          Change Address
        </button>
        {/* <button
          className="col-md-2 btn rounded-5"
          style={{ backgroundColor: "#E0D9FA", fontFamily: "black" }}
        >
          Continue
        </button> */}
      </div>
    </div>
  );
};

export default Checkoutaddress;