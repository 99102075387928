import { useState, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import star1 from "../../images/star1.png";
// import { useParams } from "react-router-dom";
import { commonHeaders, petSetUrl } from "../utils";
import { useLocation } from "react-router-dom";

function StoreProductDetail() {
  const location = useLocation();
  const singleproduct = location?.state?.data;

  // const { storeId, productId } = useParams();
  const storeId = location?.state?.store_id;
  const productId = location?.state?.product_id;
  const [productDetail, setproductDetail] = useState([]);
  useEffect(() => {
    // const productApi = `${petSetUrl}/v1/store/product/get/category?store_id=${storeId}&cid=${categoryId}&type=child&offset=0&limit=100`;
    const productApi = `${petSetUrl}/v1/store/product/get?store_id=${storeId}`;

    const requestOptions = {
      method: "GET",
      headers: commonHeaders,
    };
    fetch(productApi, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        const product = responseData.products.find((item) => {
          return item.product_id === productId;
        });
        setproductDetail(product);
      })
      .catch((error) => {
        console.error("Error fetching store data from the API:", error);
      });
  }, [storeId, productId]);

  const Navdata = [
    // { Tab: "Description", eventKey: "tab1" },
    { Tab: "Product Details", eventKey: "tab2" },
    { Tab: "Reviews", eventKey: "tab3" },
  ];
  const [activeKey, setActiveKey] = useState("tab1");
  const handleTabSelect = (selectedKey) => {
    setActiveKey(selectedKey);
  };

  return (
    <div className="container-fluid mt-5" style={{ padding: "0% 5%" }}>
      <Tab.Container
        className="w-100"
        id="myTabs"
        activeKey={activeKey}
        onSelect={handleTabSelect}
      >
        <Nav variant="tabs">
          {Navdata.map((items) => {
            return (
              <Nav.Item>
                <Nav.Link
                  className={
                    activeKey === items.eventKey
                      ? "active-link border-0 text-#E0D9FA mb-0"
                      : ""
                  }
                  style={{ color: "#A1A1AA", fontFamily: "black" }}
                  eventKey={items.eventKey}
                >
                  {items.Tab}
                </Nav.Link>
              </Nav.Item>
            );
          })}
        </Nav>

        <Tab.Content className="pt-4">
          <Tab.Pane eventKey="tab1" className="ms-3">
            <p>{singleproduct.description}</p>
          </Tab.Pane>
          <Tab.Pane eventKey="tab2" className="ms-3">
            <h5>title:</h5>
            <p>{singleproduct.title}</p>

            <h5>Sku Number:</h5>
            <p>{singleproduct.sku_number}</p>

            <h5>Stock:</h5>
            <p>{singleproduct.stock}</p>

            <h5>Weight:</h5>
            <p>{singleproduct.weight}</p>
          </Tab.Pane>
          <Tab.Pane eventKey="tab3" className="ms-3">
            <h5>Total Rating</h5>
            <p className="ms-2">
              {Array(5)
                .fill(null)
                .map((_, starIndex) => (
                  <img
                    className="mb-1"
                    key={starIndex}
                    src={star1}
                    alt=""
                    width="16px"
                  />
                ))}
              <span className="small">{singleproduct.averageRatings}</span>
            </p>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
}

export default StoreProductDetail;
