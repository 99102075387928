import urgent from "../../images/urgent.png";
import { GoArrowRight } from "react-icons/go";
import Appoint from "../../images/appoint.png";
import { Link } from "react-router-dom";
const GenealNeeds = () => {
  return (
    <>
      <div className=" mt-5">
        <h6>General Needs</h6>
        <div className="row mt-4 g-4">
          <div className="row pt-3 border rounded-3 col-lg-6 col-md-6 col-sm-12 ms-1 px-4">
            <Link
              to="/CallVeterinary"
              className="text-decoration-none text-dark"
            >
              <div className="row">
                <div className="d-flex col-10">
                  <img src={urgent} alt="" height="40px" />
                  <div className="ms-3">
                    <h6 className="small">Request Urgent Care</h6>
                    <p className="small">
                      Chat by video with the next available doctor
                    </p>
                  </div>
                </div>
                {/* <div className="col-1"> */}
                <p className="col-1" style={{ cursor: "pointer" }}>
                  <GoArrowRight />
                </p>
                {/* </div> */}
              </div>
            </Link>
          </div>

          <div className="row pt-3 border rounded-3 col-lg-6 col-md-6 col-12 ms-1 ms-md-3 mt-2 mt-md-0 px-4">
            <Link
              to="/VeterinaryDoctor"
              className="text-decoration-none text-dark"
            >
              <div className="row">
                <div className="d-flex col-11">
                  <img src={Appoint} alt="" height="40px" />
                  <div className="ms-3">
                    <h6 className="small">Book an Appointment</h6>
                    <p className="small">
                      Chat by video with the next available doctor
                    </p>
                  </div>
                </div>
                <p className="col-1">
                  <GoArrowRight />
                </p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
export default GenealNeeds;
