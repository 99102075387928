import { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import { commonHeaders,petSetUrl } from "../components/utils";
const PaymentStatus = () => {
  const navigate = useNavigate();
  const [status, setStatus] = useState("");
  // const [counter, setCounter] = useState(10);
  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const success = queryParameters.get("success");
    const payload = queryParameters.get("payload");
    const payloadData = JSON.parse(decodeURIComponent(payload));
    // console.log(payloadData);

    // const startCounter = () => {
    //   const intervalId = setInterval(() => {
    //     setCounter((prevCounter) => prevCounter - 1);
    //   }, 1000);

    //   return () => clearInterval(intervalId);
    // };

    const data = {
      order_id: payloadData?.order_id,
      payment_amount: "600.00",
      command: payloadData?.command,
      fort_id: payloadData?.fort_id,
      option: payloadData?.option,
    };

    if (success === "true") {
      Swal.fire({
        title: "Payment added successfully",
        icon: "success",
      });
      axios
        .post(`${petSetUrl}/v1/order/paymentev`, data, {
          headers:commonHeaders,
          // headers: {
          //   "Content-Type": "application/json",
          //   Authorization: "Bearer 22ba20b1-cee6-42ff-9bf0-5f3ef9055c11",
          // },
        })
        .then((response) => {
          // console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
      setStatus("Successfully added");
      // startCounter();

      const timeoutId = setTimeout(() => {
        navigate("/");
      }, 2000);

      return () => clearTimeout(timeoutId);
    } else if (success === "false") {
      Swal.fire({
        title: "Payment failed",
        icon: "error",
      });
      setStatus("Failed");
      const timeoutId = setTimeout(() => {
        navigate("/");
      }, 2000);

      return () => clearTimeout(timeoutId);
      // startCounter();
    }
  }, [navigate]);

  // useEffect(() => {
  //   if (counter === 0) {
  //     navigate("/");
  //   }
  // }, [counter, navigate]);

  return (
    <>
      <div style={{ marginTop: "5%", padding: "0 5%" }}>
        <h1>Payment Status : {status}</h1>
        {/* <p>Time Counter {counter}</p> */}
      </div>
    </>
  );
};

export default PaymentStatus;
