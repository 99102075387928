import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useNavigate, useLocation } from "react-router-dom";
import { commonHeaders, petSetUrl } from "../utils";
const MyAddresses = () => {
  const location = useLocation();
  // const orderID = location.state.id;
  // const price = location.state.total_price;
  const navigate = useNavigate();
  const [Addresses, setAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(
    localStorage.getItem("selectedAddressId")
  );

  useEffect(() => {
    const uid = localStorage.getItem("uid");
    const Api = `${petSetUrl}/v1/customer/address/get?uid=${uid}`;
    const requestOptions = {
      method: "GET",
      headers: commonHeaders,
    };
    fetch(Api, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        setAddresses(responseData.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleDelete = (AddressId) => {
    const DeleteApi = `${petSetUrl}/v1/customer/address/delete?address_id=${AddressId}`;
    const requestOptions = {
      method: "DELETE",
      headers: commonHeaders,
    };
    fetch(DeleteApi, requestOptions)
      .then((response) => response.json())
      .then((responseData) => {
        setAddresses((prevAddress) =>
          prevAddress.filter((Address) => Address.address_id !== AddressId)
        );
        Swal.fire("Address deleted successfully");
      })
      .catch((error) => {
        console.error("Error fetching store data from the API:", error);
      });
  };
  const handleRadioChange = (AddressId) => {
    const uid = localStorage.getItem("uid");
    const Api = `${petSetUrl}/v1/customer/address/get?uid=${uid}`;
    const requestOptions = {
      method: "GET",
      headers: commonHeaders,
    };
    fetch(Api, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        const selectedAddress = responseData.data.find(
          (address) => address.address_id === AddressId
        );
        if (selectedAddress) {
          localStorage.setItem(
            "selectedAddress",
            JSON.stringify(selectedAddress)
          );

          localStorage.setItem("lat", selectedAddress.lat_lng.coordinates[0]);
          localStorage.setItem("lng", selectedAddress.lat_lng.coordinates[1]);
          localStorage.setItem("building_name", selectedAddress.building_name);
          localStorage.setItem("selectedAddressId", AddressId);

          window.location.reload();
          navigate(-1)

          //  {
          //   state: {
          //     OrderId: orderID,
          //     total_price: price,
          //   },
          // }
          // );
        } else {
          console.error("Selected address not found in the response data.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      <div className="container-fluid mt-5" style={{ padding: "0 5%" }}>
        <div className="row">
          {Addresses && Addresses.length > 0 ? (
            Addresses.map((address, index) => (
              <div className="col-md-4 mb-4" key={index}>
                <div
                  className="card"
                  style={{
                    backgroundColor: "#F4F4F5",
                    borderRadius: "10px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <div className="card-body">
                    <h6 className="small">Address #{index + 1}</h6>
                    <div style={{ lineHeight: "1.5" }} className="mt-3">
                      <p className="small mb-2">
                        {address.first_name} {address.last_name}
                      </p>
                      <p className="small mb-2">{address.phone}</p>
                      <p className="small mb-2">
                        {address.building_num} {address.address1},{" "}
                        {address.country}
                      </p>
                    </div>
                    <div className="row mt-3 ms-2">
                      <p
                        className="col-3 small"
                        style={{ cursor: "pointer", color: "#F97316" }}
                        onClick={() =>
                          navigate("/EditAddress", {
                            state: { id: address.address_id },
                          })
                        }
                      >
                        Edit
                      </p>
                      <p
                        className="col-3 small text-danger"
                        style={{ cursor: "pointer", color: "#F97316" }}
                        onClick={() => handleDelete(address.address_id)}
                      >
                        Delete
                      </p>
                    </div>
                    <div className="col-12">
                      <input
                        type="radio"
                        id={`addressRadio-${index}`}
                        name="addressRadio"
                        // checked={selectedAddress}
                        checked={selectedAddress === address.address_id}
                        onChange={() => handleRadioChange(address.address_id)}
                      />
                      <label
                        htmlFor={`addressRadio - ${index}`}
                        className="ms-2"
                      >
                        Select Address
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div>
              <p>Addresses not found</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default MyAddresses;
