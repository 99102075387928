import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { MarkerF, useJsApiLoader, GoogleMap } from "@react-google-maps/api";
import axios from "axios";
import Swal from "sweetalert2";
import { commonHeaders, petSetUrl } from "../components/utils";
import Select from "react-select";

const EditAddress = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const addressID = location.state.id;
  useEffect(() => {
    const loadScript = () => {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyC-28dhX46EXos0B_fx9cuUWEpWZEMIQCc&libraries=places`;
      script.async = true;
      script.defer = true;
      script.onload = initAutocomplete;
      document.head.appendChild(script);
    };

    const initAutocomplete = () => {
      var options = {
        // types: ["(cities)"],
        componentRestrictions: { country: "ae" },
      };

      // const input = document.getElementById("autocomplete");
      // const autocomplete = new window.google.maps.places.Autocomplete(
      //   input,
      //   options
      // );
      try {
        const input = document.getElementById("autocomplete");
        if (!input) {
          throw new Error("Input element not found.");
        }

        const autocomplete = new window.google.maps.places.Autocomplete(
          input,
          options
        );

        // Add event listener to handle place selection
        autocomplete.addListener("place_changed", () => {
          const selectedPlace = autocomplete.getPlace();
          if (selectedPlace) {
            // Update the Address variable with the selected address
            const formattedAddress = selectedPlace.formatted_address;
            setAddress(formattedAddress);
            // const { lat, lng } = selectedPlace.geometry.location;
            // console.log("Latitude:", lat());
            // console.log("Longitude:", lng());
            // setlatitude(lat());
            // setlongitude(lng());

            // You can also extract other address components if needed
            // const city = selectedPlace.address_components.find(...);
            // setCity(city);

            // Log the selected place for reference
            // console.log("Selected Place:", selectedPlace);
          }
        });
      } catch (error) {
        console.error("Error initializing autocomplete:", error.message);
      }
    };

    loadScript();
  }, []);

  const [locationVal, setLocationVal] = useState("");

  const [addresses, setAddresses] = useState([]);

  const [singleAddress, setSingleAddress] = useState({});
  const [Address, setAddress] = useState(singleAddress.address1);
  const [Villanumber, setVillanumber] = useState(singleAddress.building_num);
  const [Villaname, setVillaname] = useState(singleAddress.building_name);
  const [City, setCity] = useState(singleAddress.city);

  const handleSearchInputChange = (inputVal) => {
    setLocationVal(inputVal);
    fetchOptions(inputVal);
  };

  const fetchOptions = async (inputVal) => {
    const apiUrl = "https://petsetgo_staging.petsetgo.com/v1/seller/get/place";

    try {
      const response = await axios.get(apiUrl, {
        params: {
          placeString: inputVal,
        },
        headers: commonHeaders,
      });

      setAddresses(
        response.data.data.map((address) => ({
          value: address.desc,
          label: address.desc,
          lat_lng: address.lat_lng,
        }))
      );
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  const handleSelectChange = (selectedOption) => {
    if (selectedOption) {
      setAddress(selectedOption.value);

      const selectedAddressObject = addresses.find(
        (address) => address.value === selectedOption.value
      );

      if (selectedAddressObject && selectedAddressObject.lat_lng) {
        const { lat, lng } = selectedAddressObject.lat_lng;
        const desc = selectedAddressObject.value;
        // setlatitude(lat);
        // setlongitude(lng);
        // localStorage.setItem("lat", lat);
        // localStorage.setItem("lng", lng);
        // localStorage.setItem("NewAssress", desc);
        // setLocation(desc);
      }

      // setShowModal(false);
      // window.location.reload();
    }
  };
  // const [markerPosition, setMarkerPosition] = useState({
  //   lat: parseFloat(localStorage.getItem("lat")) || 0,
  //   lng: parseFloat(localStorage.getItem("lng")) || 0,
  // });

  // const { isLoaded } = useJsApiLoader({
  //   id: "AIzaSyC-28dhX46EXos0B_fx9cuUWEpWZEMIQCc",
  //   googleMapsApiKey: "AIzaSyC-28dhX46EXos0B_fx9cuUWEpWZEMIQCc",
  //   onLoad: () => console.log("Google Maps API loaded successfully"),
  // });
  // const containerStyle = {
  //   width: "100%",
  //   height: "600px",
  // };

  // const handleMarkerDrag = (event) => {
  //   const latLng = event.latLng;
  //   const updatedPosition = {
  //     lat: latLng.lat(),
  //     lng: latLng.lng(),
  //   };

  //   console.log("updatedPosition is:", updatedPosition);

  //   setMarkerPosition(updatedPosition);

  //   const geocodingApiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${updatedPosition.lat},${updatedPosition.lng}&key=AIzaSyC-28dhX46EXos0B_fx9cuUWEpWZEMIQCc`;

  //   axios
  //     .get(geocodingApiUrl)
  //     .then((response) => {
  //       console.log(response.data.results[0]);
  //       const formattedAddress =
  //         response.data.results[0]?.formatted_address || "Address not found";
  //       console.log("Formatted Address:", formattedAddress);

  //       const city = response.data.results[0]?.address_components.find(
  //         (component) =>
  //           component.types.includes("locality") ||
  //           component.types.includes("administrative_area_level_3")
  //       )?.long_name;
  //       console.log("City:", city);
  //       setAddress(formattedAddress);
  //       setCity(city);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching address:", error);
  //     });

  //   setMarkerPosition(updatedPosition);
  // };

  useEffect(() => {
    const uid = localStorage.getItem("uid");
    const Api = `${petSetUrl}/v1/customer/address/get?uid=${uid}`;
    const requestOptions = {
      method: "GET",
      headers: commonHeaders,
    };
    fetch(Api, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        const singleAddressData = responseData.data.find(
          (item) => item.address_id === addressID
        );
        // console.log(singleAddressData);
        setSingleAddress(singleAddressData);

        setAddress(singleAddressData.address1);
        setVillanumber(singleAddressData.building_num);
        setVillaname(singleAddressData.building_name);
        setCity(singleAddressData.city);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [addressID]);

  const handleUpdate = () => {
    const data = {
      first_name: singleAddress.first_name,
      last_name: singleAddress.last_name,
      email: singleAddress.email,
      phone: singleAddress.phone,
      address1: Address,
      city: City,
      pincode: singleAddress.pincode,
      country: "",
      state: singleAddress.state,
      uid: singleAddress.uid,
    };

    const UpdataApi = `${petSetUrl}/v1/customer/address/update?address_id=${addressID}`;
    axios
      .post(UpdataApi, data, {
        headers: commonHeaders,
      })
      .then((response) => {
        if (response.status === 200) {
          Swal.fire("Address updated");
          navigate("/AccountAddresses");
        } else {
          Swal.fire("Error In Adress created");

          navigate("/EditAddress");
        }
      });
  };
  return (
    <>
      <div className="lh-1" style={{ padding: "1% 5%" }}>
        <p className="mt-5">
          Home > Your Account > My Addresses > Edit Address
        </p>
        <h1 className="text-bold">Edit Address</h1>
      </div>

      <div className="row">
        <div
          style={{ margin: "0 5%", backgroundColor: "#F4F4F5" }}
          className="mt-5 col-11 col-md-5"
        >
          <div className="p-5">
            <label style={{ fontFamily: "black" }} className="mt-2 small">
              Building / Villa Name
            </label>
            <br />
            <input
              type="text"
              className="w-100 border rounded-3 mt-2"
              value={Villaname}
              onChange={(e) => setVillaname(e.target.value)}
              style={{
                padding: "0.375rem 2.25rem 0.375rem 0.75rem",
                borderWidth: "2px",
                borderColor: "#007bff",
                outline: "none",
              }}
            />
            <br />

            <label style={{ fontFamily: "black" }} className="mt-2 small">
              Apartment / Villa Number
            </label>
            <br />
            <input
              type="text"
              className="w-100 border rounded-3 mt-2"
              value={Villanumber}
              onChange={(e) => setVillanumber(e.target.value)}
              style={{
                padding: "0.375rem 2.25rem 0.375rem 0.75rem",
                borderWidth: "2px",
                borderColor: "#007bff",
                outline: "none",
              }}
            />
            <br />
            <label style={{ fontFamily: "black" }} className="mt-2 small">
              Address
            </label>
            <br />
            <input
              type="text"
              id="autocomplete"
              className="w-100 border rounded-3 mt-2"
              value={Address}
              onChange={(e) => setAddress(e.target.value)}
              style={{
                padding: "0.375rem 2.25rem 0.375rem 0.75rem",
                borderWidth: "2px",
                borderColor: "#007bff",
                outline: "none",
              }}
            />
            <br />
            <label style={{ fontFamily: "black" }} className="mt-2 small">
              City
            </label>
            <br />
            <input
              type="text"
              className="w-100 border rounded-3 mt-2"
              value={City}
              onChange={(e) => setCity(e.target.value)}
              style={{
                padding: "0.375rem 2.25rem 0.375rem 0.75rem",
                borderWidth: "2px",
                borderColor: "#007bff",
                outline: "none",
              }}
            />
            <br />
            <button
              className="border-0 rounded-5 px-4 py-2 mt-3 small"
              style={{ fontFamily: "black", backgroundColor: "#E0D9FA" }}
              onClick={() => handleUpdate()}
            >
              Save Address
            </button>
          </div>
        </div>
        <div className="mt-5 ms-sm-5 ms-md-0 col-10 col-md-5">
          {/* {isLoaded ? (
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={markerPosition}
              zoom={16}
            
            >
              <MarkerF
                draggable={true}
                position={markerPosition}
                onDragEnd={handleMarkerDrag}
              />
            </GoogleMap>
          ) : (
            <div>Loading Google Maps...</div>
          )} */}
          {/* <label>Search Location:</label>
          <Select
            styles={{
              control: (provided) => ({
                ...provided,
                height: "30px",
              }),
            }}
            value={{ value: locationVal, label: locationVal }}
            options={addresses}
            onInputChange={(inputVal) => handleSearchInputChange(inputVal)}
            onChange={(selectedOption) => handleSelectChange(selectedOption)}
            placeholder="Select an address"
          /> */}
        </div>
      </div>
    </>
  );
};
export default EditAddress;