import UpcomingAppoint from "../components/VideoCall/UpcomingAppoint";
import GenealNeeds from "../components/VideoCall/GenealNeeds";
// import EmptyVideoCall from "../components/VideoCall/EmptyVideoCall";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import Swal from "sweetalert2";
const VideoCall = () => {
  const location = useLocation();
  const UpcommingAppointComponent = location.pathname === "/VideoCall" && (
    <UpcomingAppoint />
  );
  // const EmptyVideoCallComponent = location.pathname === "/EmptyVideoCall" && (
  //   <EmptyVideoCall />
  // );
  useEffect(() => {
    Swal.fire({
      title:
        "Should your chosen veterinary provider suggest an in-clinic consultation for your pet, te consultation fee will not be charged again",
      icon: "question",
      confirmButtonColor: "#e74c3c", // Pinkish-red color
      confirmButtonText: "I agree",
      customClass: {
        title: "fs-5", // Define a custom class for the title
      },
    }).then((result) => {
      if (result.isConfirmed) {
        // Handle confirmation logic
        // console.log("Proceed to clicked");
      }
    });
  }, []);
  // const EmptyVideoCallComponent = location.pathname === "/EmptyVideoCall" && (
  //   <EmptyVideoCall />
  // );
  return (
    <>
      <div className="lh-1" style={{ padding: "1% 5%" }}>
        <p className="mt-5">Home > Video Call</p>
        <h1 className="text-bold">Video Call</h1>
        <GenealNeeds />
        {UpcommingAppointComponent}
        {/* {EmptyVideoCallComponent} */}
      </div>
    </>
  );
};
export default VideoCall;
