import MyAddresses from "../components/Addresses/MyAddresses";
import { Link } from "react-router-dom";
const AccountAddresses = () => {
  return (
    <>
      <div className="lh-1 row" style={{ padding: "0 5%" }}>
        <div className="col">
          <p className="mt-5">Home > Your Account > My Addresses</p>
          <h1 className="text-bold">Your Addresses</h1>
        </div>
        <div className="col text-end" style={{ marginTop: "7%" }}>
          <Link to="/NewAddress">
            <button
              className="border-0 rounded-5 py-2 px-4 small "
              style={{ backgroundColor: "#E0D9FA", fontFamily: "black" }}
            >
              New Address
            </button>
          </Link>
        </div>
      </div>
      <MyAddresses/>
    </>
  );
};
export default AccountAddresses;
