import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import React from "react";
import axios from "axios";
import { commonHeaders, petSetUrl } from "../components/utils";
const OrderDetail = () => {
  const { orderID } = useParams();
  const [isTableSpanVisible, setTableSpanVisible] = useState({});
  const [isServiceSpanVisible, setisServiceSpanVisible] = useState({});
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [singleOrder, setsingleOrder] = useState([]);
  const [OrderProduct, setOrderProduct] = useState([]);
  const [OrderSevices, setOrderSevices] = useState([]);
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const formattedDate = `${
      date.getMonth() + 1
    }/${date.getDate()}/${date.getFullYear()}`;
    return formattedDate;
  };
  useEffect(() => {
    const Api = `${petSetUrl}/v1/order/order/get?customer_id=${localStorage.getItem(
      "uid"
    )}`;
    const requestOptions = {
      method: "GET",
      headers: commonHeaders,
    };
    fetch(Api, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        // console.log(responseData);
        const singleData = responseData.data.filter(
          (item) => item.orderID === orderID
        );
        // console.log(singleData);
        setOrderProduct(singleData[0].cart.cartProducts);
        setOrderSevices(singleData[0].cart.slotReservations);
        setsingleOrder(singleData[0]);
        setTableSpanVisible({});
        setisServiceSpanVisible({});
      })
      .catch((error) => {
        console.log(error);
      });
  }, [orderID]);

  // console.log(isTableSpanVisible);

  const handleNewDataHover = (index) => {
    setTableSpanVisible((prev) => ({ ...prev, [index]: true }));
  };

  const handleNewDataLeave = () => {
    setTableSpanVisible({});
  };

  const handleNewServiceHover = (index) => {
    setisServiceSpanVisible((prev) => ({ ...prev, [index]: true }));
  };

  const handleNewServiceLeave = () => {
    setisServiceSpanVisible({});
  };

  const handleReplacementButtonClick = (index) => {
    setSelectedRowIndex(index);
    setTableSpanVisible({});
  };

  const handleConfirmButtonClick = (ProductID) => {
    const params = {
      cartProductID: ProductID,
      replace_confirm: "CONFIRMED",
      product_price: singleOrder.total_price,
      cart_id: singleOrder.cart.cart_id,
    };

    const Api = `${petSetUrl}/v1/order/confirm`;

    axios
      .post(Api, params, {
        headers: commonHeaders,
      })
      .then((response) => {
        // console.log(response);
        setOrderProduct((prevProducts) =>
          prevProducts.filter((product) => product.cartProductID !== ProductID)
        );
      })
      .catch((error) => {
        console.log(error);
      });

    setSelectedRowIndex(null);
  };

  const handleRejectButtonClick = (ProductID) => {
    const params = {
      cartProductID: ProductID,
      replace_confirm: "REJECTED",
      product_price: singleOrder.total_price,
      cart_id: singleOrder.cart.cart_id,
    };

    const Api = `${petSetUrl}/v1/order/confirm`;

    axios
      .post(Api, params, {
        headers: commonHeaders,
      })
      .then((response) => {
        // console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });

    setSelectedRowIndex(null);
  };

  const handleServiceReject = (serviceID) => {
    // console.log(serviceID);
    const params = {
      slot_reservation_id: serviceID,
      replace_confirm: "REJECTED",
      product_price: singleOrder.total_price,
      cart_id: singleOrder.cart.cart_id,
    };

    const Api = `${petSetUrl}/v1/order/confirm`;

    axios
      .post(Api, params, {
        headers: commonHeaders,
      })
      .then((response) => {
        // console.log(response);
        alert("Do you want to reshedule");
      })
      .catch((error) => {
        console.log(error);
        if (error.response) {
          console.error("Response data:", error.response.data);
        }
      });
  };

  const handleServiceConfirm = (serviceID) => {
    const params = {
      slot_reservation_id: serviceID,
      replace_confirm: "CONFIRMED",
      product_price: singleOrder.total_price,
      cart_id: singleOrder.cart.cart_id,
    };

    const Api = `${petSetUrl}/v1/order/confirm`;

    axios
      .post(Api, params, {
        headers: commonHeaders,
      })
      .then((response) => {
        // console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      <div className="lh-1" style={{ padding: "1% 5%" }}>
        <p className="mt-5">
          Home {">"} Your Account {">"} Order History {">"} {orderID}
        </p>
        <h1 className="text-bold">Order Details</h1>
      </div>

      <div style={{ padding: "0 5%" }}>
        <div
          className="d-flex justify-content-between px-3 pt-2"
          style={{ backgroundColor: "#F4F4F5", alignItems: "center" }}
        >
          <p className="small">
            Order ID {singleOrder.orderID} - placed on{" "}
            {formatDate(singleOrder.createdAt)}
          </p>
          <p className="small" style={{ color: "#F97316" }}>
            Reorder
          </p>
        </div>
        <p className="small">Payment Method: {singleOrder.paymentType}</p>
        <div className="row g-4">
          <div className="col-6">
            <div className="border ps-3 py-4">
              <h6 className="small">Delivery Address</h6>
              <div className="lh-1 mt-3">
                <p className="small">{singleOrder?.address?.fName}</p>
                <p className="small">{singleOrder?.address?.phone}</p>
                <p className="small">{singleOrder?.address?.address}</p>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="border ps-3 py-4">
              <h6 className="small">Invoice Address</h6>
              <div className="lh-1 mt-3">
                <p className="small">{singleOrder?.address?.fName}</p>
                <p className="small">{singleOrder?.address?.phone}</p>
                <p className="small">{singleOrder?.address?.address}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-3" style={{ padding: "0 5%" }}>
        <table class="table border">
          <thead style={{ backgroundColor: "black" }}>
            <tr>
              <th className="small bg-light" style={{ fontWeight: "400" }}>
                Product
              </th>
              <th className="small bg-light" style={{ fontWeight: "400" }}>
                Quantity
              </th>
              <th className="small bg-light" style={{ fontWeight: "400" }}>
                Unit Price
              </th>
              <th className="small bg-light" style={{ fontWeight: "400" }}>
                Total Price
              </th>
            </tr>
          </thead>
          <tbody>
            {OrderProduct.map((item, index) => (
              <React.Fragment key={index}>
                <tr>
                  <td
                    className="w-50"
                    onMouseEnter={() => handleNewDataHover(index)}
                    onMouseLeave={handleNewDataLeave}
                    style={{ fontFamily: "black" }}
                  >
                    <span className="small">{item.product.title}</span>

                    {isTableSpanVisible[index] && (
                      <span
                        className="small text-danger text-end"
                        style={{ marginLeft: "30%", cursor: "pointer" }}
                        onClick={() => handleReplacementButtonClick(index)}
                      >
                        Replacement
                      </span>
                    )}
                  </td>
                  <td className="small">{item.quantity}</td>
                  <td className="small">{item.product.price}</td>
                  <td className="small" style={{ fontFamily: "black" }}>
                    {item.product.price}
                  </td>
                </tr>
                {selectedRowIndex === index && (
                  <tr>
                    <td className="small">
                      <span
                        className="bg-danger px-4 py-1 text-light rounded-5"
                        style={{
                          fontFamily: "black",
                          marginLeft: "50%",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleRejectButtonClick(item.cartProductID)
                        }
                      >
                        Reject
                      </span>
                      <span
                        className="bg-secondary px-4 py-1 rounded-5 ms-3"
                        onClick={() =>
                          handleConfirmButtonClick(item.cartProductID)
                        }
                        style={{ fontFamily: "black", cursor: "pointer" }}
                      >
                        Confirm
                      </span>
                    </td>
                    <td className="small"></td>
                    <td className="small"></td>
                    <td className="small" style={{ fontFamily: "black" }}></td>
                  </tr>
                )}
              </React.Fragment>
            ))}

            {OrderSevices.map((item, index) => (
              <React.Fragment key={index}>
                <tr>
                  <td
                    className="w-50"
                    onMouseEnter={() => handleNewServiceHover(index)}
                    onMouseLeave={handleNewServiceLeave}
                    style={{ fontFamily: "black" }}
                  >
                    <span className="small">{item.service.service_title}</span>

                    {isServiceSpanVisible[index] && (
                      <>
                        <span
                          className="bg-danger px-4 py-1 text-light rounded-5"
                          style={{
                            fontFamily: "black",
                            marginLeft: "50%",
                            cursor: "pointer",
                          }}
                          // onClick={() => handleReplacementButtonClick(index)}

                          onClick={() =>
                            handleServiceReject(item.slot_reservation_id)
                          }
                        >
                          Reject
                        </span>
                        <span
                          className="bg-secondary px-4 py-1 rounded-5 ms-3"
                          onClick={() =>
                            handleServiceConfirm(item.slot_reservation_id)
                          }
                          style={{ fontFamily: "black", cursor: "pointer" }}
                        >
                          Confirm
                        </span>
                      </>
                    )}
                  </td>
                  <td className="small">""</td>
                  <td className="small">{item.service?.service_price}</td>
                  <td className="small" style={{ fontFamily: "black" }}>
                    {item.service?.service_price}
                  </td>
                </tr>
                {/* {selectedRowIndex === index && ( */}
                <tr>
                  <td className="small">
                    <span style={{ cursor: "pointer" }}>
                      <p>
                        <span style={{ fontWeight: "bold" }}>
                          Previous booking:{" "}
                        </span>
                        {item?.slotDate}, {item?.slotTiming}
                      </p>
                      <p>
                        <span style={{ fontWeight: "bold" }}>
                          Current booking:
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: "bold" }}>Pickup:</span>
                      </p>

                      <button
                        style={{ outline: "none" }}
                        className="border-0 rounded-5 px-3 fs-5"
                      >
                        Rescheudle
                      </button>
                    </span>
                  </td>
                  <td className="small"></td>
                  <td className="small"></td>
                  <td className="small" style={{ fontFamily: "black" }}></td>
                </tr>
                {/* )} */}
              </React.Fragment>
            ))}

            <tr>
              <td
                className="small"
                style={{ fontFamily: "black", backgroundColor: "#F9FAFB" }}
              ></td>
              <td className="small" style={{ backgroundColor: "#F9FAFB" }}></td>
              <td className="small">Sub Total</td>
              <td className="small" style={{ fontFamily: "black" }}>
                AED {singleOrder?.sub_price?.toFixed(2)}
              </td>
            </tr>
            <tr>
              <td
                className="small"
                style={{ fontFamily: "black", backgroundColor: "#F9FAFB" }}
              ></td>
              <td className="small" style={{ backgroundColor: "#F9FAFB" }}></td>
              <td className="small">Shipping</td>
              <td className="small" style={{ fontFamily: "black" }}>
                AED {singleOrder?.shipment_price?.toFixed(2)}
              </td>
            </tr>

            <tr>
              <td
                className="small"
                style={{ fontFamily: "black", backgroundColor: "#F9FAFB" }}
              ></td>
              <td className="small" style={{ backgroundColor: "#F9FAFB" }}></td>
              <td className="small">Tax</td>
              <td className="small" style={{ fontFamily: "black" }}>
                AED 00.00
              </td>
            </tr>

            <tr>
              <td
                className="small"
                style={{ fontFamily: "black", backgroundColor: "#F9FAFB" }}
              ></td>
              <td className="small" style={{ backgroundColor: "#F9FAFB" }}></td>
              <td className="small">Total</td>
              <td className="small" style={{ fontFamily: "black" }}>
                AED {singleOrder?.total_price?.toFixed(2)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};
export default OrderDetail;
