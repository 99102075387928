import fifty_off_back from "../../images/fifty_off_back.png";
import fifty_off_image from "../../images/fifty_off_image.png";
import { Link } from "react-router-dom";
const FiftyOff = () => {
  return (
    <>
      <div className="fifty_off">
        <div className="div1">
          <h2 className="">
            Get an amazing range of products at your doorsteps!
          </h2>
          <h3>Safe and effective products.</h3>
        </div>
        <div className="div2">
          <img className="image1" src={fifty_off_back} alt="" />
          <img className="image2" src={fifty_off_image} alt="" />
        </div>
        <div className="div3">
          <Link to="/Store">
            <button>SHOP NOW</button>
          </Link>
        </div>
      </div>
    </>
  );
};
export default FiftyOff;
