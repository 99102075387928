// import { Link } from "react-router-dom";
// import { useLocation, useNavigate } from "react-router-dom";

// const NormalCallDetail = () => {
//   const location = useLocation();
//   const Nav = useNavigate();
//   const price = location?.state?.total_price;
//   const orderID = location?.state?.OrderId;

//   const handlepayment = () => {
//     Nav("/Payment", {
//       state: {
//         OrderId: orderID,
//         total: price,
//       },
//     });
//   };

//   return (
//     <>
//       <div className="border p-4">
//         <div className="row">
//           <p className="col-7">Duration</p>
//           <h6 className="col-5">30min</h6>
//         </div>
//         <div className="row ">
//           <p className="col-7">Payment</p>
//           <h6 className="col-5">
//             AED {parseFloat((price / (1 + 5 / 100)).toFixed(2))}
//           </h6>
//         </div>
//         <div className="row border-bottom pb-2 ">
//           <p className="col-7">VAT</p>
//           <h6 className="col-5">
//             AED {(price - price / (1 + 5 / 100)).toFixed(2)}
//           </h6>
//         </div>
//         <div className="row pt-2">
//           <p className="col-7">Total</p>
//           <h6 className="col-5">AED {price}</h6>
//         </div>
//         <Link to="">
//           <button
//             className="border-0 rounded-5 p-2 px-5 w-100"
//             style={{ backgroundColor: "#E0D9FA", fontFamily: "black" }}
//             onClick={handlepayment}
//           >
//             Checkout
//           </button>
//         </Link>
//       </div>
//     </>
//   );
// };

// export default NormalCallDetail;

import React from "react";
// import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";

const NormalCallDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
 
  const price = location.state.total_price;
  const orderID = location.state.OrderId;

  const handlePayment = () => {
    navigate("/Payment", {
      state: {
        OrderId: orderID,
        total: price,
      },
    });
  };

  return (
    <>
      <div className="border p-4">
        <div className="row">
          <p className="col-7">Duration</p>
          <h6 className="col-5">30min</h6>
        </div>
        <div className="row ">
          <p className="col-7">Payment</p>
          <h6 className="col-5">
            AED {parseFloat((price / (1 + 5 / 100)).toFixed(2))}
          </h6>
        </div>
        <div className="row border-bottom pb-2 ">
          <p className="col-7">VAT</p>
          <h6 className="col-5">
            AED {(price - price / (1 + 5 / 100)).toFixed(2)}
          </h6>
        </div>
        <div className="row pt-2">
          <p className="col-7">Total</p>
          <h6 className="col-5">AED {price}</h6>
        </div>
        {/* <Link to="#"> */}
          <button
            className="border-0 rounded-5 p-2 px-5 w-100"
            style={{ backgroundColor: "#E0D9FA", fontFamily: "black" }}
            onClick={handlePayment}
          >
            Checkout
          </button>
        {/* </Link> */}
      </div>
    </>
  );
};

export default NormalCallDetail;
