import PopularStores from "../../components/Home/PopularStores";
import FiftyOff from "../../components/Home/FiftyOff";
import { useLocation } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { commonHeaders, petSetUrl } from "../../components/utils";
import ServiceStores from "./ServiceStores";
import CartContext from "../../Cart/Cart";

export default function Services() {
  const location = useLocation();
  const { ServiceType, currentdate, setcurrentdate, formattedDate } =
    useContext(CartContext);
  // console.log(location);
  const [categ, setCateg] = useState(
    location?.state?.data ? location.state.data : ""
  );
  // console.log(categ);
  const queryParams = new URLSearchParams(location.search);
  const selectedService = queryParams.get("service");
  // const [ServiceType, setServiceType] = useState(selectedService || "Grooming");

  const [Data, setData] = useState({});
  const [selectedOption, setSelectedOption] = useState("");
  const [pickUp, setpickUp] = useState(false);
  const [dropOff, setdropOff] = useState(false);
  const [Groomcat, serGroomcat] = useState("");
  const lat = localStorage.getItem("lat");
  const lng = localStorage.getItem("lng");
  const [loading, setLoading] = useState(false);

  const [selectedButton, setSelectedButton] = useState("iGrooming");

  const fetchData = async (category) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${petSetUrl}/v1/store/filter?date=${currentdate}&pick_up=${pickUp}&drop_off=${dropOff}&des=${category}&lat=${lat}&lng=${lng}`,
        {
          headers: commonHeaders,
        }
      );
      // console.log(`${petSetUrl}/v1/store/filter?date=${currentdate}&pick_up=${pickUp}&drop_off=${dropOff}&des=${category}&lat=${lat}&lng=${lng}`)
      const resData = response?.data?.data?.[0]?.stores;
      // const filter = resData.filter(
      //   (store) =>
      //     store?.service_list?.includes(category) &&
      //     store?.lat_lng?.coordinates &&
      //     store?.store_open &&
      //     store?.store_close
      // );
      setData(resData);
      // console.log(resData);
    } catch (error) {
      console.log(error);
      setData([]);
    } finally {
      setLoading(false);
    }
  };

  const pickDrop = (value) => {
    if (value === "pickup") {
      setpickUp(true);
      setdropOff(false);
    } else if (value === "dropoff") {
      setdropOff(true);
      setpickUp(false);
    } else if (value === "both") {
      setpickUp(true);
      setdropOff(true);
    } else {
      setpickUp(false);
      setdropOff(false);
    }
  };

  useEffect(() => {
    fetchData(ServiceType || "Grooming");
  }, [ServiceType, location]);

  const renderFilterSearch = location.pathname !== "/ServiceStore" && (
    <div
      className="ps-lg-4"
      style={{
        height: "80px",
        display: "grid",
        alignItems: "center",
        textAlign: "center",
        width: "90%",
        margin: "0% 5% 0 5%",
      }}
    >
      <div className="container row ps-5">
        <div className="col-12 col-md-4 ">
          <input
            type="date"
            className="w-100 border rounded-3"
            value={currentdate}
            onChange={(e) => setcurrentdate(e.target.value)}
            min={formattedDate}
            style={{
              padding: "0.375rem 2.25rem 0.375rem 0.75rem",
              borderWidth: "2px",
              borderColor: "#007bff",
              outline: "none",
            }}
          />
        </div>
        <div className="col-12 col-md-4 mt-2 mt-md-0 ">
          <select
            className="form-select w-100 rounded-3 border"
            style={{ boxShadow: "none", border: "none" }}
            onChange={(e) => pickDrop(e.target.value)}
          >
            <option value="" disabled selected>
              Chose Pickup
            </option>
            <option value="pickup">Pickup Required</option>
            <option value="dropoff">Dropoff Required</option>
            <option value="both">Both Required</option>
            <option value="self">Pickup & Dropoff - self</option>
          </select>
        </div>
        <div className="col-12 col-md-4 mt-2 mt-md-0 mb-2 mb-md-0">
          <button
            className="btn text-dark w-100 rounded-5"
            style={{ backgroundColor: "#E0D9FA" }}
            // onClick={() => fetchData(ServiceType)
            //   setSelectedButton("iGrooming");
            // }

            onClick={() => {
              if (selectedButton === "mGrooming") {
                fetchData("Mobile Grooming");
              } else if (selectedButton === "aGrooming") {
                fetchData("In House Grooming");
              } else {
                fetchData(ServiceType);
              }
              // You can uncomment the following line if you still want to set the selectedButton to "iGrooming"
              // setSelectedButton("iGrooming");
            }}

            // onClick={() => {
            //   fetchData(ServiceType);

            // }}
          >
            Search
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div>
        <div className="lh-1" style={{ padding: "0 5%" }}>
          <p style={{ marginTop: "8%" }}>
            Home &gt; {ServiceType === null ? "Stores" : ServiceType} &gt;{" "}
            {Groomcat}
          </p>
          <h1 className="text-bold">
            {" "}
            {ServiceType === null ? "Stores" : ServiceType} {Groomcat}
          </h1>
        </div>
        {ServiceType === "Grooming" ? (
          <div className="pb-2 pt-1" style={{ paddingLeft: "5%" }}>
            <button
              // className="productbtn border-0 rounded-5 px-2 "
              className={`productbtn border-0 rounded-5 px-2 ms-2 ${
                selectedButton === "iGrooming" ? "selected" : ""
              }`}
              onClick={() => {
                fetchData("Grooming");
                setSelectedButton("iGrooming");
              }}
            >
              In Store Grooming
            </button>
            <button
              // className="productbtn border-0 rounded-5 px-2 ms-2"
              // onClick={() => fetchData("Mobile Grooming")}
              className={`productbtn border-0 rounded-5 px-2 ms-2 ${
                selectedButton === "mGrooming" ? "selected" : ""
              }`}
              onClick={() => {
                fetchData("Mobile Grooming");
                setSelectedButton("mGrooming");
              }}
            >
              Mobile Grooming
            </button>
            <button
              className={`productbtn border-0 rounded-5 px-2 ms-2 ${
                selectedButton === "aGrooming" ? "selected" : ""
              }`}
              onClick={() => {
                fetchData("In House Grooming");
                setSelectedButton("aGrooming");
              }}
            >
              In House Grooming
            </button>
          </div>
        ) : (
          ""
        )}
        {/* {renderFilterButtons} */}
        {loading ? (
          <div
            className="loader"
            style={{
              marginLeft: "5%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          ></div>
        ) : (
          <>
            {renderFilterSearch}
            <ServiceStores storeData={Data} />
            <FiftyOff />
          </>
        )}
      </div>
    </>
  );
}
