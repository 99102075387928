import AllPets from "../components/Pets/AllPets";
import { Link } from "react-router-dom";
const MyPets = () => {
  return (
    <>
      <div className="lh-1 row" style={{ padding: "0 5%" }}>
        <div className="col">
          <p className="mt-5">Home > Your Account > My Pets</p>
          <h1 className="text-bold">My Pets</h1>
        </div>
        <div className="col text-end" style={{ marginTop: "7%" }}>
          <Link to="/NewPet">
            <button
              className="border-0 rounded-5 py-2 px-4 small"
              style={{ backgroundColor: "#E0D9FA", fontFamily: "black" }}
            >
              New Pet
            </button>
          </Link>

          {/* <Link to="/petCategory">
            <button
              className="border-0 rounded-5 py-2 px-4 small"
              style={{ backgroundColor: "#E0D9FA", fontFamily: "black" }}
            >
              New Pet
            </button>
          </Link> */}
        </div>
      </div>
      <AllPets />
    </>
  );
};
export default MyPets;
