import { useState, useEffect } from "react";
import EmptyVideoCall from "./EmptyVideoCall";
import uicon from "../../images/Union.png";
import { Link } from "react-router-dom";
import { commonHeaders, petSetUrl } from "../utils";
const UpcomingAppoint = () => {
  const [upcomingAppointments, setupcomingAppointments] = useState([]);
  useEffect(() => {
    const customer_id = localStorage.getItem("uid");
    const Api = `${petSetUrl}/v1/video/orders/get?customer_id=${customer_id}`;
    const requestOptions = {
      method: "GET",
      headers: commonHeaders,
    };
    fetch(Api, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        // console.log(responseData);
        setupcomingAppointments(responseData.service);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <>
      <div className="mt-5">
        <h6>Upcoming Appointments</h6>

        <div
          className="d-flex ps-3 pt-2 my-4"
          style={{ backgroundColor: "#E4F9FF" }}
        >
          <div>
            <img src={uicon} alt="" width="20px" height="20px" />
          </div>
          <div className="ms-2">
            <h6 className="small">Video Calls</h6>
            <p className="small">
              All video appointments will be conducted via mobile app. Please
              download mobile app from{" "}
              <Link
                to="https://play.google.com/store/apps/details?id=com.ventures.psg&pli=1"
                className="text-dark"
                style={{
                  fontWeight: "bold",
                }}
              >
                here
              </Link>{" "}
              and sign in to your account.
            </p>
          </div>
        </div>

        {/* {upcomingAppointments && upcomingAppointments.length > 0 ? (
          <table class="table border">
            <thead style={{ backgroundColor: "black" }}>
              <tr>
                <th className="small bg-light" style={{ fontWeight: "400" }}>
                  Date & Time
                </th>
                <th className="small bg-light" style={{ fontWeight: "400" }}>
                  Item
                </th>
                <th className="small bg-light" style={{ fontWeight: "400" }}>
                  Doctor
                </th>
                <th className="small bg-light" style={{ fontWeight: "400" }}>
                  Payment
                </th>
                <th
                  className="small bg-light"
                  style={{ fontWeight: "400" }}
                ></th>
              </tr>
            </thead>
            <tbody>
              {upcomingAppointments.map((appointment, index) => (
                <tr key={index}>
                  <td className="small">
                    {appointment.slotTiming}, {appointment.slotDate}
                  </td>
                  <td className="small">Emergency Call</td>
                  <td className="small">{appointment.name}</td>
                  <td className="small">
                    <span
                    // className="rounded-5 px-4 py-1"
                    // style={{
                    //   color: appointment.statusColor,
                    //   backgroundColor: appointment.bgColor,
                    // }}
                    >
                      {appointment.service_price}
                    </span>
                  </td>
                  <td className="text-danger small">View</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <EmptyVideoCall />
        )} */}
      </div>
    </>
  );
};
export default UpcomingAppoint;
