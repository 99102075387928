import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { BiUserCircle } from "react-icons/bi";
import { IoNotificationsOutline } from "react-icons/io5";
import { SlBasket } from "react-icons/sl";
import logo1 from "../images/logo1.png";
import logo2 from "../images/logo2.png";
import logo from "../images/logo_hori.png";
import { Link } from "react-router-dom";
// import Accountheader from "./Your Account/accountheader";
import NewNav from "./NewNav";
import { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import CartContext from "../Cart/Cart";
import { petSetUrl, commonHeaders } from "./utils";
import axios from "axios";

function Navigation() {
  const { user, setServiceType, state: cartState } = useContext(CartContext);

  const location = useLocation();
  const redirect = useNavigate();
  const { id } = useParams();
  // const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [selectedService, setSelectedService] = useState("");
  const [selectedpet, setselectedpet] = useState("");
  const [cartItemsCount, setCartItemsCount] = useState(0);
  const [expanded, setExpanded] = useState(false);
  const [apiData, setApiData] = useState([]);

  const getCart = async (id) => {
    try {
      const response = await axios.get(`${petSetUrl}/v1/cart/get?uid=${id}`, {
        headers: commonHeaders,
      });
      if (response) {
        // console.log(response.data.data);
        setApiData(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const uid = localStorage.getItem("uid");
    if (uid) {
      getCart(uid);
    }
  }, []);

  const handleGrooming = () => {
    redirect("/Services", {
      state: {
        data: "Grooming",
      },
    });
    setServiceType("Grooming");
    setExpanded(false);
  };

  const handleVeternary = () => {
    redirect("/Services", {
      state: {
        data: "Veterinary",
      },
    });
    setServiceType("Veterinary");
    setExpanded(false);
  };

  const handleVetAtHome = () => {
    redirect("/Services", {
      state: {
        data: "Vet At Home",
      },
    });
    setServiceType("Vet At Home");
    setExpanded(false);
  };

  useEffect(() => {
    // Update cart items count when apiData changes
    if (apiData && apiData.cartProducts) {
      const productQuantity = apiData.cartProducts.reduce(
        (acc, product) => acc + product.quantity,
        0
      );
      setCartItemsCount(productQuantity);
    } else {
      setCartItemsCount(0);
    }

    if (apiData && apiData.slotReservations) {
      const serviceQuantity = apiData.slotReservations.length;
      setCartItemsCount((prevCount) => prevCount + serviceQuantity);
    }
  }, [apiData]);
  const performSearch = () => {
    const targetPath = "/StoreItems"; // Updated to include the leading slash
    const requestOptions = {
      method: "GET",
      headers: commonHeaders,
    };
    if (location.pathname.includes(targetPath)) {
      const StoreApi = `${petSetUrl}/v1/store/products/store/filter?store_id=${id}&query=${searchValue}`;
      fetch(StoreApi, requestOptions)
        .then((response) => {
          return response.json();
        })
        .then((resopnseData) => {
          redirect("/SearchProduct", {
            state: {
              data: resopnseData.data,
              query: searchValue,
            },
          });
          setSearchValue("");
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      const Api = `${petSetUrl}/v1/store/products/search?query=${searchValue}`;

      fetch(Api, requestOptions)
        .then((response) => {
          return response.json();
        })
        .then((resopnseData) => {
          redirect("/SearchProduct", {
            state: {
              data: resopnseData.data,
              query: searchValue,
            },
          });

          setSearchValue("");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      performSearch();
    }
  };

  const handleNavbarToggle = () => {
    setExpanded(!expanded);
  };
  useEffect(() => {
    const selected = JSON.parse(localStorage.getItem("newpet"));
    setselectedpet(selected);

    function handleScroll() {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // const selectedpet = JSON.parse(localStorage.getItem("newpet"));
  return (
    <>
      <NewNav />
      <Navbar
        expand="lg"
        style={{
          border: "1px solid #E4E4E7",
          position: "fixed",
          width: "100%",
          backgroundColor: "white",
          top: isScrolled ? "0" : "auto",
          zIndex: "49",
        }}
        expanded={expanded}
        className="gradiant"
      >
        <Container
          fluid
          className="px-md-5 navbar_container"
          style={{ height: "60px" }}
        >
          <Navbar.Brand
            as={Link}
            to="/"
            className="navbar_logo"
            // style={{
            //   position: "absolute",
            //   top: "50%",
            //   left: "50%",
            //   transform: "translate(-50%, -50%)",
            //   display: "block",
            // }}
          >
            {/* <div className="d-flex">
              <img
                src={logo1}
                alt=""
                height="50px"
                className="d-block d-sm-none"
              />
              <img
                src={logo2}
                alt=""
                height="50px"
                className="d-block d-sm-none"
              />
            </div> */}
            <img
              src={logo}
              alt=""
              // height="25px"
              className="brandlogos"
            />
          </Navbar.Brand>
          <div className="d-flex">
            <Nav
              className="d-lg-none"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Nav.Link
                as={Link}
                to={user ? "/Account" : "/Login"}
                className="me-2"
              >
                <BiUserCircle
                  style={{ fontSize: "22px", marginLeft: "10px" }}
                />
              </Nav.Link>

              <Nav.Link as={Link} 
              // to="/Notification"
               to={user ? "/Notification" : "/Login"}
              className="me-2">
                <IoNotificationsOutline style={{ fontSize: "22px" }} />
              </Nav.Link>
              <Nav.Link as={Link} 
              // to="/Cart"
               to={user ? "/Cart" : "/Login"}
              className="me-2">
                <SlBasket style={{ fontSize: "22px" }} />
                {cartItemsCount && (
                  <span className="cart-item-count">{cartItemsCount}</span>
                )}
              </Nav.Link>
            </Nav>
            <Navbar.Toggle
              aria-controls="navbarScroll"
              style={{ boxShadow: "none", outline: "none" }}
              onClick={handleNavbarToggle}
            />
          </div>
          <Navbar.Collapse
            id="navbarScroll"
            style={{ backgroundColor: "white" }}
            onEnter={() => setExpanded(true)}
            onExited={() => setExpanded(false)}
            className="gradiant"
          >
            <Nav className="me-auto my-2 my-lg-0">
              <Nav.Link
                as={Link}
                to="/"
                className="ms-2 ms-md-3"
                onClick={() => setExpanded(false)}
              >
                Home
              </Nav.Link>

              <Nav.Link
                as={Link}
                to="/Store"
                className="ms-2 ms-md-3"
                onClick={() => setExpanded(false)}
              >
                Shops
              </Nav.Link>
              {/* <NavDropdown
                title="Shops"
                id="navbarScrollingDropdown"
                className="ms-2 ms-md-4"
              >
                <NavDropdown.Item
                  as={Link}
                  to="/Store"
                  onClick={() => setExpanded(false)}
                >
                  Shops
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/Nearme"
                  onClick={() => setExpanded(false)}
                >
                  Near Me
                </NavDropdown.Item>
              </NavDropdown> */}

              <NavDropdown
                title="Services"
                id="navbarScrollingDropdown"
                className="ms-2 ms-md-4"
              >
                <NavDropdown.Item
                  // as={Link}
                  // to={`/Services?service=${
                  //   selectedService === "Grooming" ? "" : "Grooming"
                  // }`}
                  onClick={handleGrooming}
                >
                  Grooming
                </NavDropdown.Item>
                <NavDropdown.Item
                  // as={Link}
                  // to={`/Services?service=${
                  //   selectedService === "Veterinary" ? "" : "Veterinary"
                  // }`}
                  onClick={handleVeternary}
                >
                  Veterinary
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/VideoCall"
                  onClick={() => setExpanded(false)}
                >
                  Video Call
                </NavDropdown.Item>
                <NavDropdown.Item
                  // as={Link}
                  // to={`/Services?service=${
                  //   selectedService === "Vet At Home" ? "" : "Vet At Home"
                  // }`}
                  onClick={handleVetAtHome}
                >
                  Vet At Home
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link as={Link} to="/blogs" className="ms-2 ms-md-4">
                Blog
              </Nav.Link>
            </Nav>
            <Form.Control
              style={{
                borderRadius: "20px",
                width: "200px",
                boxShadow: "none",
                height: "30px",
                marginTop: "6px",
                marginRight: "15px",
                borderColor: "#D1D5DB",
              }}
              type="search"
              className="py-0"
              placeholder="Search here"
              aria-label="Search"
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
              onKeyDown={handleKeyDown}
            />
            <Nav className="d-lg-block d-lg-flex d-none">
              {/* <Nav.Link>Become a partner</Nav.Link> */}
              <Nav.Link as={Link} to={user ? "/Account" : "/Login"}>
                <BiUserCircle style={{ fontSize: "22px" }} />
              </Nav.Link>

              <Nav.Link
                as={Link}
                // to="/Notification"
                to={user ? "/Notification" : "/Login"}
              >
                <IoNotificationsOutline style={{ fontSize: "22px" }} />
              </Nav.Link>
              <Nav.Link
                as={Link}
                // to="/Cart"
                to={user ? "/Cart" : "/Login"}
              >
                <SlBasket style={{ fontSize: "22px" }} />
                {cartItemsCount && (
                  <span className="cart-item-count">{cartItemsCount}</span>
                )}
              </Nav.Link>
              <Nav.Link as={Link} to="#">
                {selectedpet ? (
                  <>
                    <img
                      className="rounded-5"
                      src={selectedpet.pet_image || logo1}
                      alt=""
                      onError={(e) => {
                        e.target.src = logo1;
                      }}
                      style={{
                        height: "22px",
                        width: "22px",
                        paddingRight: "2px",
                      }}
                    />
                    {selectedpet.pet_name}
                  </>
                ) : null}
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {/* <Accountheader /> */}
      <div
        className="accountheader"
        // style={{
        //   paddingTop:"5%"
        // }}
      ></div>
    </>
  );
}

export default Navigation;
