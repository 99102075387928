import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import CartContext from "../../Cart/Cart";
import { useContext } from "react";

const VideoCall = () => {
  const { setServiceType } = useContext(CartContext);
  const nav = useNavigate();

  return (
    <>
      {/* Bringing Expert Care to Your Furry Friend, Virtually! Vet Video
            Calls - Your Pet's Health, Just a Click Away. */}
      <div className="card_section2">
        <div className="Vet_Home">
          <h1 className="Vet_Home_heading">Vet At Home</h1>
          <p className="Vet_Home_paragraph">
            {/* Tailored Care, Right at Your Doorstep: Vet at Home, Because Your Pet
            Deserves Comfortable Wellness. */}
            Tailored Care, Right at Your Doorstep: Vet at Home
          </p>
          <button
            className="Vet_Home_button"
            onClick={() => {
              nav("/Services", {
                state: {
                  data: "Vet At Home",
                },
              });
              setServiceType("Vet At Home");
            }}
          >
            Book Now
          </button>
        </div>
        {/* <div className="Training">
          <h1 className="Training_heading">Training</h1>
          <p className="Training_paragraph">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </p>
          <button className="Training_button">Get Started</button>
        </div> */}
      </div>
    </>
  );
};
export default VideoCall;
