import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
// import { MarkerF, useJsApiLoader, GoogleMap } from "@react-google-maps/api";
import { commonHeaders, petSetUrl } from "../components/utils";
import Select from "react-select";

const NewAddress = () => {
  // const [firstname, setfirstname] = useState("");
  // const [lastname, setlastname] = useState("");
  // const [phonenumber, setphonenumber] = useState("");

  useEffect(() => {
    const loadScript = () => {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyC-28dhX46EXos0B_fx9cuUWEpWZEMIQCc&libraries=places`;
      script.async = true;
      script.defer = true;
      script.onload = initAutocomplete;
      document.head.appendChild(script);
    };

    const initAutocomplete = () => {
      var options = {
        componentRestrictions: { country: "ae" },
      };

      try {
        const input = document.getElementById("autocomplete");
        if (!input) {
          throw new Error("Input element not found.");
        }

        const autocomplete = new window.google.maps.places.Autocomplete(
          input,
          options
        );

        autocomplete.addListener("place_changed", () => {
          const selectedPlace = autocomplete.getPlace();
          if (selectedPlace) {
            const formattedAddress = selectedPlace.formatted_address;
            setAddress(formattedAddress);
            const { lat, lng } = selectedPlace.geometry.location;
            // console.log("Latitude:", lat());
            // console.log("Longitude:", lng());
            setlatitude(lat());
            setlongitude(lng());

            // console.log("Selected Place:", selectedPlace);
          }
        });
      } catch (error) {
        console.error("Error initializing autocomplete:", error.message);
      }
    };

    loadScript();
  }, []);
  const [locationVal, setLocationVal] = useState("");

  const [addresses, setAddresses] = useState([]);

  const [Address, setAddress] = useState("");
  const [Villanumber, setVillanumber] = useState("");
  const [Villaname, setVillaname] = useState("");
  const [City, setCity] = useState("");
  const [latitude, setlatitude] = useState("");
  const [longitude, setlongitude] = useState("");
  // const [markerPosition, setMarkerPosition] = useState({
  //   lat: parseFloat(localStorage.getItem("lat")) || 0,
  //   lng: parseFloat(localStorage.getItem("lng")) || 0,
  // });

  const navigate = useNavigate();

  // const { isLoaded } = useJsApiLoader({
  //   id: "AIzaSyC-28dhX46EXos0B_fx9cuUWEpWZEMIQCc",
  //   googleMapsApiKey: "AIzaSyC-28dhX46EXos0B_fx9cuUWEpWZEMIQCc",
  //   onLoad: () => console.log("Google Maps API loaded successfully"),
  // });
  // const containerStyle = {
  //   width: "100%",
  //   height: "600px",
  // };

  // useEffect(() => {
  //   const defaultLat = parseFloat(localStorage.getItem("lat")) || 0;
  //   const defaultLng = parseFloat(localStorage.getItem("lng")) || 0;
  //   const initialPosition = { lat: defaultLat, lng: defaultLng };

  //   setMarkerPosition(initialPosition);

  //   const geocodingApiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${defaultLat},${defaultLng}&key=AIzaSyC-28dhX46EXos0B_fx9cuUWEpWZEMIQCc`;

  //   axios
  //     .get(geocodingApiUrl)
  //     .then((response) => {
  //       console.log(response.data.results[0]);
  //       const formattedAddress =
  //         response.data.results[0]?.formatted_address || "Address not found";
  //       console.log("Formatted Address:", formattedAddress);

  //       const city = response.data.results[0]?.address_components.find(
  //         (component) =>
  //           component.types.includes("locality") ||
  //           component.types.includes("administrative_area_level_3")
  //       )?.long_name;
  //       console.log("City:", city);
  //       setAddress(formattedAddress);
  //       setCity(city);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching address:", error);
  //     });
  // }, []);

  // const handleMarkerDrag = (event) => {
  //   const latLng = event.latLng;
  //   const updatedPosition = {
  //     lat: latLng.lat(),
  //     lng: latLng.lng(),
  //   };

  //   console.log("updatedPosition is:", updatedPosition);

  //   setMarkerPosition(updatedPosition);

  //   const geocodingApiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${updatedPosition.lat},${updatedPosition.lng}&key=AIzaSyC-28dhX46EXos0B_fx9cuUWEpWZEMIQCc`;

  //   axios
  //     .get(geocodingApiUrl)
  //     .then((response) => {
  //       console.log(response.data.results[0]);
  //       const formattedAddress =
  //         response.data.results[0]?.formatted_address || "Address not found";
  //       console.log("Formatted Address:", formattedAddress);

  //       const city = response.data.results[0]?.address_components.find(
  //         (component) =>
  //           component.types.includes("locality") ||
  //           component.types.includes("administrative_area_level_3")
  //       )?.long_name;
  //       console.log("City:", city);
  //       setAddress(formattedAddress);
  //       setCity(city);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching address:", error);
  //     });

  //   setMarkerPosition(updatedPosition);
  // };
  const handleSearchInputChange = (inputVal) => {
    setLocationVal(inputVal);
    fetchOptions(inputVal);
  };

  const fetchOptions = async (inputVal) => {
    const apiUrl = "https://petsetgo_staging.petsetgo.com/v1/seller/get/place";

    try {
      const response = await axios.get(apiUrl, {
        params: {
          placeString: inputVal,
        },
        headers: commonHeaders,
      });

      setAddresses(
        response.data.data.map((address) => ({
          value: address.desc,
          label: address.desc,
          lat_lng: address.lat_lng,
        }))
      );
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  const handleSelectChange = (selectedOption) => {
    if (selectedOption) {
      setAddress(selectedOption.value);

      const selectedAddressObject = addresses.find(
        (address) => address.value === selectedOption.value
      );

      if (selectedAddressObject && selectedAddressObject.lat_lng) {
        const { lat, lng } = selectedAddressObject.lat_lng;
        const desc = selectedAddressObject.value;
        setlatitude(lat);
        setlongitude(lng);
        // localStorage.setItem("lat", lat);
        // localStorage.setItem("lng", lng);
        // localStorage.setItem("NewAssress", desc);
        // setLocation(desc);
      }

      // setShowModal(false);
      // window.location.reload();
    }
  };

  const validate = () => {
    if (
      Address === "" ||
      Villanumber === "" ||
      Villaname === "" ||
      City === ""
    ) {
      Swal.fire("please fill the field");
    } else {
      const uid = localStorage.getItem("uid");
      const AddressApi = `${petSetUrl}/v1/customer/address/add`;
      const formData = {
        first_name: localStorage.getItem("firstname"),
        last_name: localStorage.getItem("lastname"),
        email: localStorage.getItem("Email"),
        phone: localStorage.getItem("Phone"),
        address1: Address,
        city: City,
        pincode: "",
        country: "",
        state: "",
        building_num: Villanumber,
        building_name: Villaname,
        uid: uid,
        lat_lng: {
          lat: latitude,
          lng: longitude,
        },
      };
      axios
        .post(AddressApi, formData, {
          headers: commonHeaders,
        })
        .then((response) => {
          if (response.status === 200) {
            Swal.fire("Address Added");
            navigate("/AccountAddresses");
          } else {
            Swal.fire("Error In Adress created");

            navigate("/NewAddress");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };
  return (
    <>
      <div className="lh-1" style={{ padding: "1% 5%" }}>
        <p className="mt-5">Home > Your Account > My Addresses > New Address</p>
        <h1 className="text-bold">New Address</h1>
      </div>
      <div className="row">
        <div
          style={{ margin: "0 5%", backgroundColor: "#F4F4F5" }}
          className="mt-5 col-11 col-md-5"
        >
          <div className="p-5">
            <label style={{ fontFamily: "black" }} className="mt-2 small">
              Building / Villa Name
            </label>
            <br />
            <input
              type="text"
              className="w-100 border rounded-3 mt-2"
              value={Villaname}
              onChange={(e) => setVillaname(e.target.value)}
              style={{
                padding: "0.375rem 2.25rem 0.375rem 0.75rem",
                borderWidth: "2px",
                borderColor: "#007bff",
                outline: "none",
              }}
            />
            <br />

            <label style={{ fontFamily: "black" }} className="mt-2 small">
              Apartment / Villa Number
            </label>
            <br />
            <input
              type="text"
              className="w-100 border rounded-3 mt-2"
              value={Villanumber}
              onChange={(e) => setVillanumber(e.target.value)}
              style={{
                padding: "0.375rem 2.25rem 0.375rem 0.75rem",
                borderWidth: "2px",
                borderColor: "#007bff",
                outline: "none",
              }}
            />
            <br />
            <label style={{ fontFamily: "black" }} className="mt-2 small">
              Address
            </label>
            <br />
            <input
              type="text"
              className="w-100 border rounded-3 mt-2"
              id="autocomplete"
              value={Address}
              onChange={(e) => setAddress(e.target.value)}
              style={{
                padding: "0.375rem 2.25rem 0.375rem 0.75rem",
                borderWidth: "2px",
                borderColor: "#007bff",
                outline: "none",
              }}
            />
            <br />
            <label style={{ fontFamily: "black" }} className="mt-2 small">
              City
            </label>
            <br />
            <input
              type="text"
              className="w-100 border rounded-3 mt-2"
              value={City}
              onChange={(e) => setCity(e.target.value)}
              style={{
                padding: "0.375rem 2.25rem 0.375rem 0.75rem",
                borderWidth: "2px",
                borderColor: "#007bff",
                outline: "none",
              }}
            />
            <br />
            <button
              className="border-0 rounded-5 px-4 py-2 mt-3 small"
              style={{ fontFamily: "black", backgroundColor: "#E0D9FA" }}
              onClick={() => validate()}
            >
              Save Address
            </button>
          </div>
        </div>
        <div className="mt-5 ms-4 ms-md-0 col-10 col-md-5"></div>
      </div>
    </>
  );
};
export default NewAddress;