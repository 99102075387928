import axios from "axios";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { auth } from "../firbase.Config";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { commonHeaders, petSetUrl } from "../components/utils";
import OtpModal from "./OtoModal";

const Information = () => {
  const [firstname, setfirstname] = useState(localStorage.getItem("firstname"));
  const [lastname, setlastname] = useState(localStorage.getItem("lastname"));
  const [Email, setEmail] = useState(localStorage.getItem("Email"));
  const [Phone, setPhone] = useState(localStorage.getItem("Phone"));

  const [showOtpModal, setShowOtpModal] = useState(false);
  const [Otpresult, setOtpresult] = useState(null);

  const uid = localStorage.getItem("uid");
  const redirect = useNavigate();

  const handleCloseOtpModal = () => {
    setShowOtpModal(false);
  };

  const handleSubmitOtpModal = (code) => {
    // console.log(code);
    Otpresult.confirm(code)
      .then((result) => {
        const user = result.user;
        // console.log(user);
        localStorage.setItem("Phone", "+" + Phone);
        Swal.fire("Verified");

        setShowOtpModal(false);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onCapchaVerify = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      auth,
      "recaptcha-container",
      {
        size: "normal",
        callback: (response) => {},
        "expired-callback": () => {},
      }
    );
  };

  const UserInfo = () => {
    if (firstname === "" || lastname === "" || Email === "" || Phone === "") {
      Swal.fire("All fields are reqired");
    } else {
      if (Phone !== localStorage.getItem("Phone")) {
        onCapchaVerify();
        const phoneNumber = "+" + Phone;
        const appVerifier = window.recaptchaVerifier;
        signInWithPhoneNumber(auth, phoneNumber, appVerifier)
          .then((result) => {
            window.confirmationResult = result;
            setOtpresult(result);
            // console.log(result);

            setShowOtpModal(true);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const updatedData = {
          first_name: firstname,
          last_name: lastname,
          uid: uid,

          phone: Phone,
        };
        const updateInfo = `${petSetUrl}/v1/customer/update`;
        axios
          .post(updateInfo, updatedData, {
            headers: commonHeaders,
            // headers: {
            //   "Content-Type": "application/json",
            //   Authorization: "Bearer 22ba20b1-cee6-42ff-9bf0-5f3ef9055c11",
            // },
          })
          .then((response) => {
            if (response.status === 200) {
              Swal.fire("Info update Successfully");
              redirect(-1);
            } else {
              Swal.fire("Info Not update please try again");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  useEffect(() => {
    const Api = `${petSetUrl}/v1/customer/get?uid=${localStorage.getItem(
      "uid"
    )}`;
    const requestOptions = {
      method: "GET",
      headers: commonHeaders,
    };
    fetch(Api, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        // console.log(responseData);
        localStorage.setItem("firstname", responseData.data.first_name);
        localStorage.setItem("lastname", responseData.data.last_name);
        localStorage.setItem("Email", responseData.data.email);
        localStorage.setItem("Phone", responseData.data.phone);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <>
      <div className="lh-1" style={{ padding: "1% 5%" }}>
        <p className="mt-5">Home > Your Account > Information</p>
        <h1 className="text-bold">Your Information</h1>
      </div>
      <div className="row">
        <div
          style={{ margin: "0 5%", backgroundColor: "#F4F4F5" }}
          className="mt-md-5 col-lg-5 col-md-8 col-11"
        >
          <div className="p-5">
            <label style={{ fontFamily: "black" }} className="small">
              First Name
            </label>{" "}
            <br />
            <input
              type="text"
              className="w-100 border rounded-3 mt-2"
              placeholder="Enter your first name"
              value={firstname}
              onChange={(e) => setfirstname(e.target.value)}
              style={{
                padding: "0.375rem 2.25rem 0.375rem 0.75rem",
                borderWidth: "2px",
                borderColor: "#007bff",
                outline: "none",
              }}
            />
            <br />
            <label style={{ fontFamily: "black" }} className="mt-2 small">
              Last Name
            </label>
            <br />
            <input
              type="text"
              className="w-100 border rounded-3 mt-2"
              placeholder="Enter your last name"
              value={lastname}
              onChange={(e) => setlastname(e.target.value)}
              style={{
                padding: "0.375rem 2.25rem 0.375rem 0.75rem",
                borderWidth: "2px",
                borderColor: "#007bff",
                outline: "none",
              }}
            />
            <br />
            <label style={{ fontFamily: "black" }} className="mt-2 small">
              Email
            </label>
            <br />
            <input
              type="email"
              className="w-100 border rounded-3 mt-2"
              placeholder="Enter your email"
              value={Email}
              onChange={(e) => setEmail(e.target.value)}
              style={{
                padding: "0.375rem 2.25rem 0.375rem 0.75rem",
                borderWidth: "2px",
                borderColor: "#007bff",
                outline: "none",
              }}
            />
            <br />
            <label style={{ fontFamily: "black" }} className="mt-2 small">
              Phone Number
            </label>
            <br />
            <PhoneInput
              className="w-50 w-md-100 rounded-3 mt-2"
              country={"ae"}
              value={Phone}
              onChange={(value) => setPhone(value)}
            />
            <br />
            {/* <label style={{ fontFamily: "black" }} className="mt-2 small">
              Password
            </label>
            <br />
            <input
              type="password"
              className="w-100 border rounded-3 mt-2"
              value={Password}
              onChange={(e) => setPassword(e.target.value)}
              style={{
                padding: "0.375rem 2.25rem 0.375rem 0.75rem",
                borderWidth: "2px",
                borderColor: "#007bff",
                outline: "none",
              }}
            />
            <br />
            <label style={{ fontFamily: "black" }} className="mt-2 small">
              New Password
            </label>
            <br />
            <input
              type="password"
              className="w-100 border rounded-3 mt-2"
              value={New_Password}
              onChange={(e) => setNew_Password(e.target.value)}
              style={{
                padding: "0.375rem 2.25rem 0.375rem 0.75rem",
                borderWidth: "2px",
                borderColor: "#007bff",
                outline: "none",
              }}
            />
            <br />
            <div className="lh-1 mt-3">
              <input type="checkbox" />
              <label className="ms-2" style={{ fontWeight: "600" }}>
                Sign up for our newsletter
              </label>
              <p className="w-100 mt-2">
                You may unsubscribe at any moment. For that purpose, please find
                our contact info in the legal notice
              </p>
            </div> */}
            <button
              className="border-0 rounded-5 px-4 py-2 mt-3 small"
              style={{ fontFamily: "black", backgroundColor: "#E0D9FA" }}
              onClick={() => UserInfo()}
            >
              Save Changes
            </button>
          </div>
        </div>
      </div>
      <OtpModal
        isOpen={showOtpModal}
        onRequestClose={handleCloseOtpModal}
        onSubmit={handleSubmitOtpModal}
      />

      <div style={{ padding: "0 5%" }} id="recaptcha-container"></div>
    </>
  );
};
export default Information;
