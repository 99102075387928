import NormalCallDetail from "../components/EmergencyCall/NormalCallDetail";
const NormalCall = () => {

  return (
    <>


      <div className="row ms-5 mt-5">
        <div className="col-0 col-md-8 ">{/* <EmergencyCallPayment /> */}</div>
        <div className="col-8 col-md-3 ms-4">
          <NormalCallDetail />
        </div>
      </div>
    </>
  );
};
export default NormalCall;
