import { useState } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { commonHeaders, petSetUrl } from "../components/utils";

const sounds = [
  "Anxious",
  "Aggressive",
  "Howls/Cries too much",
  "Bites",
  "Needy",
  "Dementia",
  "Dominant",
  "Eats too much",
  "Eats too LITERAL_TYPES",
  "Eats non-food setDefaultAutoSelectFamily",
  "Drink too much Water",
  "Drink too little water",
  "Marks",
  "Scared",
  "Separation anxiety",
  "Sensitovi to noises",
  "Senile",
  "Perfectly Fine",
];

const dogBreedList = [
  "American Cocker Spaniel",
  "American Pit Bull Terrier",
  "Australian Shephred",
  "Beagle",
  "Bichon",
  "Boxer",
  "Chihuahua",
  "Chow Chow",
  "Dachshund",
  "Dalmatian",
  "Dobermann",
  "English Bull Dog",
  "English Cocker Spaniel",
  "French Bull Dog",
  "German Shephred",
  "Golden Retriever",
  "Great Dane",
  "Havanese",
  "Husky",
  "King Charles Cavalier",
  "Labrador Retriever",
  "Malamute",
  "Maltese",
  "Mastiff",
  "Mini Poodle",
  "Pomeranian",
  "Poodle",
  "Pug",
  "Rottweiler",
  "Samoyed",
  "Shiba Inu",
  "Shih Tzu",
  "St. Bernard",
  "Toy Poodle",
  "Yorkshire Terrier",
  "Other",
];

const catBreedList = ["American Shorthair", "Domestic Shorthair", "Other"];
const fishBreedList = ["Saltwater", "Seawater", "Turtles", "Other"];
const reptileBreedList = [
  "Blue Tongued skink",
  "Breaded Gragon",
  "Chinese water Dragon",
  "Crested gecko",
  "Frilled Dragon",
  "Leopard gecko",
  "Monitor Lizards",
  "Tokay gecko",
  "Uromastyx",
  "Veiled Chameleon",
  "Other",
];
const birdBreedList = [
  "Canary",
  "Cockatiel",
  "Conure",
  "Finch",
  "Parakeet",
  "Other",
];
const smallPetBreedList = [
  "Chinchilla",
  "Ferret",
  "Guinea Pig",
  "Hamster",
  "Mouse",
  "Other",
];

const NewPet = () => {
  const navigate = useNavigate();
  const [Category, setCategory] = useState("");
  const [Gender, setGender] = useState("");
  const [Activeness, setActiveness] = useState("");
  const [SpendingTime, setSpendingTime] = useState("");
  const [Sound, setSound] = useState("");
  const [Care, setCare] = useState("");
  const [ChipNumber, setChipNumber] = useState("");
  const [Warning, setWarning] = useState("");
  const [Dietry, setDietry] = useState("");
  const [Breed, setBreed] = useState("");
  const [DOB, setDOB] = useState("");
  const [Name, setName] = useState("");
  const [pet_image, setPet_image] = useState("");
  const [medical_doc, setMedical_doc] = useState("");
  const [life_stage, setlife_stage] = useState("");
  const [document_array, setdocument_array] = useState([]);
  // const [Registration, setRegistration] = useState("");
  // const [Species, setSpecies] = useState("");
  const [Size, setSize] = useState("");
  const [Eight, setEight] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    // console.log(file);

    if (file) {
      const reader = new FileReader();

      reader.onloadstart = () => {
        // console.log("File reading started");
      };

      reader.onloadend = () => {
        // console.log("File reading completed");

        const formData = new FormData();
        formData.append("photos", file);
        // console.log(formData);
        axios
          .post(`${petSetUrl}/v1/pet/upload`, formData, {
            headers: {
              ...commonHeaders,
            },
          })
          .then((response) => {
            // console.log("Document upload success:", response.data);
            setSelectedFile("pets/" + response.data.fileKey);
          })
          .catch((error) => {
            console.error("Error uploading document:", error);
          });
      };

      reader.onerror = (error) => {
        console.error("File reading failed:", error);
      };

      reader.readAsDataURL(file);
    } else {
      console.warn("No file selected");
    }
  };

  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];
  //   setSelectedFile(file);
  // };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onloadstart = () => {
        // console.log("File reading started");
      };

      reader.onloadend = () => {
        // console.log("File reading completed");
        const formData = new FormData();
        formData.append("photos", file);
        // console.log("formdata is", formData);

        axios
          .post(`${petSetUrl}/v1/pet/upload`, formData, {
            headers: {
              ...commonHeaders,
            },
          })
          .then((response) => {
            // console.log("Upload success:", response);
            setSelectedImage("pets/" + response.data.fileKey);
            setdocument_array([
              {
                ext: "pdf",
                key: "pets/" + response.data.fileKey,
                fileName: file.name,
              },
            ]);
          })
          .catch((error) => {
            console.error("Error uploading image:", error);
          });
      };

      reader.onerror = (error) => {
        console.error("File reading failed:", error);
      };

      reader.readAsDataURL(file);
    } else {
      console.warn("No file selected");
    }
  };
  // console.log(selectedImage);
  const Createpet = () => {
    const today = new Date(DOB);
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    const formattedDate = `${day}-${month}-${year}`;
    const uid = localStorage.getItem("uid");
    const Api = `${petSetUrl}/v1/pet/create`;
    const petData = {
      pet_name: Name,
      breed: Breed,
      pet_image: pet_image,
      species: Category,
      gender: Gender,
      size: Size,
      weight: Eight,
      dob: formattedDate,
      pet_breed: "weewe",
      active: Activeness,
      spend_most_time: SpendingTime,
      issue: Sound,
      medical_doc: medical_doc,
      microchip_number: ChipNumber,
      dietary: Dietry,
      special_care: Care,
      warning: Warning,
      life_stage: life_stage,
      document_array: document_array,
      owner_id: uid,
    };

    if (petData.pet_name && petData.breed && petData.dob && petData.gender) {
      axios
        .post(Api, petData, {
          headers: commonHeaders,
        })
        .then((response) => {
          // console.log(response);
          if (response.status === 200) {
            localStorage.setItem("newpet", JSON.stringify(response.data.data));
            Swal.fire("Pet Created Successfully");
            navigate(-1);
          } else {
            Swal.fire("Error In Creating Pet");
            navigate("/NewPet");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      Swal.fire("Name, Gender, Breed, DOB must be filled");
    }
    // }
  };

  return (
    <>
      <div className="lh-1" style={{ padding: "1% 5%" }}>
        <p className="mt-5">Home > Your Account > My Pets </p>
        <h1 className="text-bold">Pet Profile</h1>
      </div>
      <div className="row">
        <div
          style={{ margin: "0 5%", backgroundColor: "#F4F4F5" }}
          className="mt-md-5 col-lg-5 col-md-8 col-11"
        >
          <div className="p-5">
            <label style={{ fontFamily: "black" }} className="small">
              Species <span className="red">*</span>
            </label>{" "}
            <br />
            <select
              className="w-100 border rounded-3 mt-2"
              value={Category}
              onChange={(e) => setCategory(e.target.value)}
              style={{
                padding: "0.375rem 2.25rem 0.375rem 0.75rem",
                borderWidth: "2px",
                borderColor: "#007bff",
                outline: "none",
              }}
            >
              <option value="" disabled>
                Select Species
              </option>
              <option value="Dog">Dog</option>
              <option value="Cat">Cat</option>
              <option value="Fish and Aquatic">Fish and Aquarium</option>
              <option value="Reptiles">Reptiles</option>
              <option value="Bird">Bird</option>
              <option value="Small Pet">Small Pet</option>
            </select>
            <br />
            <label style={{ fontFamily: "black" }} className="mt-2 small">
              Select Image
            </label>
            <br />
            <input
              className="mt-2"
              type="file"
              accept="image/*"
              onChange={handleImageChange}
            />
            <br />
            {Category !== "Fish and Aquatic" && (
              <>
                <label style={{ fontFamily: "black" }} className="mt-2 small">
                  Name <span className="red">*</span>
                </label>
                <br />
                <input
                  type="text"
                  className="w-100 border rounded-3 mt-2"
                  placeholder="Enter pet Name"
                  value={Name}
                  onChange={(e) => setName(e.target.value)}
                  style={{
                    padding: "0.375rem 2.25rem 0.375rem 0.75rem",
                    borderWidth: "2px",
                    borderColor: "#007bff",
                    outline: "none",
                  }}
                />
                <br />
              </>
            )}
            {Category !== "Fish and Aquatic" &&
              Category !== "Reptiles" &&
              Category !== "Bird" && (
                <>
                  <label style={{ fontFamily: "black" }} className="mt-2 small">
                    Select Gender <span className="red">*</span>
                  </label>
                  <br />
                  <select
                    className="w-100 border rounded-3 mt-2"
                    value={Gender}
                    onChange={(e) => setGender(e.target.value)}
                    style={{
                      padding: "0.375rem 2.25rem 0.375rem 0.75rem",
                      borderWidth: "2px",
                      borderColor: "#007bff",
                      outline: "none",
                    }}
                  >
                    <option value="" disabled>
                      Select Gender
                    </option>
                    <option value="Male">Boy</option>
                    <option value="Female">Girl</option>
                  </select>
                  <br />
                </>
              )}
            {Category === "Dog" && (
              <>
                <label style={{ fontFamily: "black" }} className="mt-2 small">
                  Select Size
                </label>
                <br />
                <select
                  className="w-100 border rounded-3 mt-2"
                  value={Size}
                  onChange={(e) => setSize(e.target.value)}
                  style={{
                    padding: "0.375rem 2.25rem 0.375rem 0.75rem",
                    borderWidth: "2px",
                    borderColor: "#007bff",
                    outline: "none",
                  }}
                >
                  <option value="" disabled>
                    Select Size
                  </option>
                  <option value="Small">Small</option>
                  <option value="Medium">Medium</option>
                  <option value="Large">Large</option>
                  <option value="Extra Large">Extra Large</option>
                </select>
                <br />
              </>
            )}
            {Category !== "Fish and Aquatic" && (
              <>
                <label style={{ fontFamily: "black" }} className="mt-2 small">
                  Weight
                </label>
                <br />
                <input
                  type="text"
                  className="w-100 border rounded-3 mt-2"
                  placeholder="Enter Weight"
                  value={Eight}
                  onChange={(e) => setEight(e.target.value)}
                  style={{
                    padding: "0.375rem 2.25rem 0.375rem 0.75rem",
                    borderWidth: "2px",
                    borderColor: "#007bff",
                    outline: "none",
                  }}
                />
                <br />
                <label style={{ fontFamily: "black" }} className="mt-2 small">
                  DOB <span className="red">*</span>
                </label>
                <br />
                <input
                  type="date"
                  className="w-100 border rounded-3 mt-2"
                  placeholder="Enter your phone number"
                  value={DOB}
                  onChange={(e) => setDOB(e.target.value)}
                  style={{
                    padding: "0.375rem 2.25rem 0.375rem 0.75rem",
                    borderWidth: "2px",
                    borderColor: "#007bff",
                    outline: "none",
                  }}
                />
                <br />
              </>
            )}
            <label style={{ fontFamily: "black" }} className="small">
              Breed <span className="red">*</span>
            </label>{" "}
            <br />
            <select
              className="w-100 border rounded-3 mt-2"
              value={Breed}
              onChange={(e) => setBreed(e.target.value)}
              style={{
                padding: "0.375rem 2.25rem 0.375rem 0.75rem",
                borderWidth: "2px",
                borderColor: "#007bff",
                outline: "none",
              }}
            >
              <option value="" disabled>
                Select Breed
              </option>
              {Category === "Dog" && (
                <>
                  {dogBreedList.map((dog, index) => (
                    <option key={index} value={dog}>
                      {dog}
                    </option>
                  ))}
                </>
              )}

              {Category === "Cat" && (
                <>
                  {catBreedList.map((cat, index) => (
                    <option key={index} value={cat}>
                      {cat}
                    </option>
                  ))}
                </>
              )}

              {Category === "Fish and Aquatic" && (
                <>
                  {fishBreedList.map((fish, index) => (
                    <option key={index} value={fish}>
                      {fish}
                    </option>
                  ))}
                </>
              )}

              {Category === "Reptiles" && (
                <>
                  {reptileBreedList.map((reptile, index) => (
                    <option key={index} value={reptile}>
                      {reptile}
                    </option>
                  ))}
                </>
              )}

              {Category === "Bird" && (
                <>
                  {birdBreedList.map((bird, index) => (
                    <option key={index} value={bird}>
                      {bird}
                    </option>
                  ))}
                </>
              )}

              {Category === "Small Pet" && (
                <>
                  {smallPetBreedList.map((smallPet, index) => (
                    <option key={index} value={smallPet}>
                      {smallPet}
                    </option>
                  ))}
                </>
              )}
            </select>
            <br />
            {Category !== "Fish and Aquatic" && Category !== "Bird" && (
              <>
                <label style={{ fontFamily: "black" }} className="mt-2 small">
                  How Active is {Name}
                </label>
                <br />
                <select
                  className="w-100 border rounded-3 mt-2"
                  value={Activeness}
                  onChange={(e) => setActiveness(e.target.value)}
                  style={{
                    padding: "0.375rem 2.25rem 0.375rem 0.75rem",
                    borderWidth: "2px",
                    borderColor: "#007bff",
                    outline: "none",
                  }}
                >
                  <option value="" disabled>
                    Select
                  </option>
                  <option value="Lazy Bones">Lazy Bones</option>
                  <option value="Fairy Active">Fairy Active</option>
                  <option value="Zoomie-lander">Zoomie-lander</option>
                </select>
                <br />
                <label style={{ fontFamily: "black" }} className="mt-2 small">
                  Where does {Name} spend most of their day?
                </label>
                <br />
                <select
                  className="w-100 border rounded-3 mt-2"
                  value={SpendingTime}
                  onChange={(e) => setSpendingTime(e.target.value)}
                  style={{
                    padding: "0.375rem 2.25rem 0.375rem 0.75rem",
                    borderWidth: "2px",
                    borderColor: "#007bff",
                    outline: "none",
                  }}
                >
                  <option value="" disabled>
                    Select
                  </option>
                  <option value="At Homw">At Homw</option>
                  <option value="Outdoors">Outdoors</option>
                  <option value="A mix of both">A mix of both</option>
                </select>
                <br />
                <label style={{ fontFamily: "black" }} className="mt-2 small">
                  {Name} sounds like a charming little thing. Any issue we
                  should Know of?
                </label>
                <br />
                <select
                  className="w-100 border rounded-3 mt-2"
                  value={Sound}
                  onChange={(e) => setSound(e.target.value)}
                  style={{
                    padding: "0.375rem 2.25rem 0.375rem 0.75rem",
                    borderWidth: "2px",
                    borderColor: "#007bff",
                    outline: "none",
                  }}
                >
                  <option value="" disabled>
                    Select
                  </option>
                  {sounds.map((sound, index) => (
                    <option key={index} value={sound}>
                      {sound}
                    </option>
                  ))}
                </select>
                <br />
              </>
            )}
            {Category !== "Fish and Aquatic" && (
              <>
                <label style={{ fontFamily: "black" }} className="mt-2 small">
                  Does {Name} Need Special Care
                </label>
                <br />
                <input
                  type="text"
                  className="w-100 border rounded-3 mt-2"
                  placeholder="Enter Special Care"
                  value={Care}
                  onChange={(e) => setCare(e.target.value)}
                  style={{
                    padding: "0.375rem 2.25rem 0.375rem 0.75rem",
                    borderWidth: "2px",
                    borderColor: "#007bff",
                    outline: "none",
                  }}
                />
                <br />
                <label
                  htmlFor="fileInput"
                  style={{ fontFamily: "black" }}
                  className="mt-2 small"
                >
                  Attach Document:
                </label>
                <br />
                <input
                  className="mt-2"
                  type="file"
                  id="fileInput"
                  onChange={handleFileChange}
                  accept=".pdf"
                />
                <br />
                <label style={{ fontFamily: "black" }} className="mt-2 small">
                  Does {Name} have micro chip number?
                </label>
                <br />
                <input
                  type="text"
                  className="w-100 border rounded-3 mt-2"
                  value={ChipNumber}
                  onChange={(e) => setChipNumber(e.target.value)}
                  style={{
                    padding: "0.375rem 2.25rem 0.375rem 0.75rem",
                    borderWidth: "2px",
                    borderColor: "#007bff",
                    outline: "none",
                  }}
                />
                <br />
                <label style={{ fontFamily: "black" }} className="mt-2 small">
                  Any Warning?
                </label>
                <br />
                <input
                  type="text"
                  className="w-100 border rounded-3 mt-2"
                  value={Warning}
                  onChange={(e) => setWarning(e.target.value)}
                  style={{
                    padding: "0.375rem 2.25rem 0.375rem 0.75rem",
                    borderWidth: "2px",
                    borderColor: "#007bff",
                    outline: "none",
                  }}
                />
                <br />
                <label style={{ fontFamily: "black" }} className="mt-2 small">
                  Any dietry requirement of {Name}?
                </label>
                <br />
                <input
                  type="text"
                  className="w-100 border rounded-3 mt-2"
                  value={Dietry}
                  onChange={(e) => setDietry(e.target.value)}
                  style={{
                    padding: "0.375rem 2.25rem 0.375rem 0.75rem",
                    borderWidth: "2px",
                    borderColor: "#007bff",
                    outline: "none",
                  }}
                />
                <br />
                <label style={{ fontFamily: "black" }} className="mt-2 small">
                  {Name}'s life usage
                </label>
                <br />
                <input
                  className="w-100 border rounded-3 mt-2"
                  value="junior"
                  style={{
                    padding: "0.375rem 2.25rem 0.375rem 0.75rem",
                    borderWidth: "2px",
                    borderColor: "#007bff",
                    outline: "none",
                  }}
                />
                <br />
              </>
            )}
            <button
              className="border-0 rounded-5 px-4 py-2 mt-3 small"
              style={{ fontFamily: "black", backgroundColor: "#E0D9FA" }}
              onClick={() => Createpet()}
            >
              Save Changes
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewPet;
