import Navigation from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Store from "./pages/StorePage";
import StoreItems from "./pages/StoreItems";
import StoreProduct from "./pages/StoreProduct";
import NearMe from "./pages/NearMe";
import Cart from "./pages/Cart";
import Checkout from "./pages/Checkout";
import VideoCall from "./pages/VideoCall";
import EmergenCall from "./pages/EmergenCall";
import VeterinaryDoctor from "./pages/VeterinaryDoctor";
import DoctorProfile from "./pages/DoctorProfile";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import Account from "./pages/Account";
import Notification from "./pages/Notification";
import Information from "./pages/Information";
import AccountAddresses from "./pages/AccountAddresses";
import NewAddress from "./pages/NewAddress";
import MyPets from "./pages/MyPets";
import NewPet from "./pages/NewPet";
import PetRelocation from "./pages/PetRelocation";
import Login from "./pages/Login";
import Registration from "./pages/Registraion";
import ForgetPass from "./pages/ForgetPass";
import Updatepass from "./pages/Updatepass";
import OrderHistory from "./pages/OrderHistory";
import OrderDetail from "./pages/OrderDetail";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ScrollToTop from "./components/ScrolltoTop";
// import ServiceStores from "./pages/Services/Services";
import Services from "./pages/Services/Services";
import SingleStoreServices from "./pages/Services/SingleStoreServices";
import { CartContextProvider } from "./Cart/Cart";
import ServiceProduct from "./components/Services/ServiceProduct";
import SearchProduct from "./pages/SearchProduct";
import PaymentStatus from "./pages/paymentStatus";
import PaymentComponent from "./PaymentComponent";
import Errorpage from "./pages/Errorpage";
import Editpet from "./pages/Editpet";
import EditAddress from "./pages/EditAddress";
import NormalCall from "./pages/NormalCall";
import Blogs from "./pages/Blog/Blogs";
import Single from "./pages/Blog/Single";
const Routing = () => {
  return (
    <CartContextProvider>
      <Router>
        <ScrollToTop />
        <Navigation />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Store" element={<Store />} />
          <Route path="/StoreItems/:slug" element={<StoreItems />} />
          <Route
            path="/Storeproduct/:slug"
            element={<StoreProduct />}
          />
          <Route path="/Services" element={<Services />} />
          <Route path="/Services/Store" element={<SingleStoreServices />} />
          <Route path="/Services/service" element={<ServiceProduct />} />
          <Route path="/ServicesItems" element={<StoreItems />} />
          <Route path="/EmptyServicesItems" element={<StoreItems />} />
          {/* <Route
            path="/Servicesproduct/:storeId/:categoryId/:productId"
            element={<StoreProduct />}
          /> */}
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blogs/blog" element={<Single />} />
          <Route path="/Nearme" element={<NearMe />} />
          <Route path="/Cart" element={<Cart />} />
          <Route path="/EmptyCart" element={<Cart />} />
          <Route path="/Checkout" element={<Checkout />} />
          <Route path="/VideoCall" element={<VideoCall />} />
          <Route path="/EmptyVideoCall" element={<VideoCall />} />
          <Route path="/EmergenCall" element={<EmergenCall />} />
          <Route path="/VeterinaryDoctor" element={<VeterinaryDoctor />} />
          <Route path="/Doctor" element={<DoctorProfile />} />
          <Route path="/CallVeterinary" element={<EmergenCall />} />
          <Route path="/NormalCallCheckout" element={<NormalCall />} />
          <Route path="/Terms" element={<Terms />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/Account" element={<Account />} />
          <Route path="/Notification" element={<Notification />} />
          <Route path="/Information" element={<Information />} />
          <Route path="/AccountAddresses" element={<AccountAddresses />} />
          <Route path="/NewAddress" element={<NewAddress />} />
          <Route path="/MyPets" element={<MyPets />} />
          <Route path="/NewPet" element={<NewPet />} />
          <Route path="/Editpet" element={<Editpet />} />
          <Route path="/PetRelocation" element={<PetRelocation />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/Registration" element={<Registration />} />
          <Route path="/ForgetPassword" element={<ForgetPass />} />
          <Route path="/UpdatePassword" element={<Updatepass />} />
          <Route path="/OrderHistory" element={<OrderHistory />} />
          <Route path="/OrderDetail/:orderID" element={<OrderDetail />} />
          <Route path="SearchProduct" element={<SearchProduct />} />
          <Route path="paymentStatus/:id" element={<PaymentStatus />} />
          <Route path="Payment" element={<PaymentComponent />} />
          <Route path="payment/error" element={<Errorpage />} />
          <Route path="/EditAddress" element={<EditAddress />} />
        </Routes>
        <Footer />
      </Router>
    </CartContextProvider>
  );
};
export default Routing;
