import { Link } from "react-router-dom";
const Updatepass = () => {
  return (
    <>

      <div className="d-flex justify-content-center align-items-center mt-5">
        <div className="w-25">
          <h2 className="text-center">Update Password</h2>
          <div>
            <label style={{ fontFamily: "black" }} className="mt-5 small">
              Password
            </label>
            <br />
            <input
              type="password"
              className="w-100 border rounded-3 mt-2"
              placeholder="Password"
              style={{
                padding: "0.375rem 2.25rem 0.375rem 0.75rem",
                borderWidth: "2px",
                borderColor: "#007bff",
                outline: "none",
              }}
            />
            <br />
            <label style={{ fontFamily: "black" }} className="mt-2 small">
              Confirm Password
            </label>
            <br />
            <input
              type="password"
              className="w-100 border rounded-3 mt-2"
              placeholder="Password"
              style={{
                padding: "0.375rem 2.25rem 0.375rem 0.75rem",
                borderWidth: "2px",
                borderColor: "#007bff",
                outline: "none",
              }}
            />
            <br />
            <p className="small">Password should match</p>
            <button
              className="border-0 rounded-5 px-4 py-2 mt-3 small w-100"
              style={{ fontFamily: "black", backgroundColor: "#E0D9FA" }}
            >
              Sent Reset Link
            </button>
            <p className="text-center small mt-3">
              Already have an account? Login{" "}
              <Link
                to="/Login"
                className="text-decoration-none"
                style={{ color: "#E0D9FA" }}
              >
                here
              </Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Updatepass;
