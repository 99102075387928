import DoctorList from "../components/VeterinaryDoctore/DoctorList";
import FiftyOff from "../components/Home/FiftyOff";

const VeterinaryDoctor = () => {
  return (
    <>
      <div className="lh-1" style={{ padding: "1% 5%" }}>
        <p className="mt-5">Home > Book an appointment > Veterinary Doctors</p>
        <h1 className="text-bold">Veterinary</h1>
      </div>
      <DoctorList />
      <div className="mt-5">
        <FiftyOff />
      </div>
    </>
  );
};

export default VeterinaryDoctor;
