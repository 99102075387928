import { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import { commonHeaders, petSetUrl } from "../components/utils";
const Registration = () => {
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [password, SetPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  // const CreateAccount = () => {
  //   if (
  //     fullname === "" ||
  //     email === "" ||
  //     password === "" ||
  //     confirmPassword === ""
  //   ) {
  //     Swal.fire("All fields required");
  //   } else {
  //     const userData = {
  //       first_name: fullname,
  //       last_name: "",
  //       email: email,
  //       password: password,
  //     };
  //     setLoading(true);
  //     axios
  //       .post(`${petSetUrl}/v1/customer/signup`, userData, {
  //         headers: commonHeaders,
  //       })
  //       .then((response) => {
  //         if (response.status === 200) {
  //           Swal.fire("Account Created Successfully");

  //           navigate("/Login");
  //         } else {
  //           Swal.fire("Error in account create");
  //           navigate("/Registration");
  //         }
  //       })
  //       .catch((error) => {
  //         console.error("Error:", error);
  //         Swal.fire("Failed to create an account. Please try again.");
  //       });
  //     setLoading(false);
  //   }
  // };

  const CreateAccount = () => {
    if (
      fullname === "" ||
      email === "" ||
      password === "" ||
      confirmPassword === ""
    ) {
      Swal.fire("All fields required");
    } else {
      const userData = {
        first_name: fullname,
        last_name: "",
        email: email,
        password: password,
      };
      setLoading(true);
      axios
        .post(`${petSetUrl}/v1/customer/signup`, userData, {
          headers: commonHeaders,
        })
        .then((response) => {
          if (response.status === 200) {
            Swal.fire("Account Created Successfully");
            navigate("/Login");
          } else {
            Swal.fire("Error in account create");
            navigate("/Registration");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          Swal.fire("Failed to create an account. Please try again.");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <div className="loader"></div>
        </div>
      ) : (
        <div className="d-flex justify-content-center align-items-center mt-5">
          <div className="row">
            <div className="col-12">
              <h2 className="text-center">Create an account</h2>
              <div>
                <label style={{ fontFamily: "black" }} className="mt-2 small">
                  Fullname
                </label>
                <br />
                <input
                  type="text"
                  value={fullname}
                  className="w-100 border rounded-3 mt-2"
                  placeholder="John Doe"
                  style={{
                    padding: "0.375rem 2.25rem 0.375rem 0.75rem",
                    borderWidth: "2px",
                    borderColor: "#007bff",
                    outline: "none",
                  }}
                  onChange={(e) => setFullname(e.target.value)}
                />
                <br />
                <label style={{ fontFamily: "black" }} className="mt-2 small">
                  Email
                </label>
                <br />
                <input
                  type="email"
                  value={email}
                  className="w-100 border rounded-3 mt-2"
                  placeholder="Enter your email"
                  style={{
                    padding: "0.375rem 2.25rem 0.375rem 0.75rem",
                    borderWidth: "2px",
                    borderColor: "#007bff",
                    outline: "none",
                  }}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <br />
                <label style={{ fontFamily: "black" }} className="mt-2 small">
                  Password
                </label>
                <br />
                <input
                  type="password"
                  value={password}
                  className="w-100 border rounded-3 mt-2"
                  placeholder="Password"
                  style={{
                    padding: "0.375rem 2.25rem 0.375rem 0.75rem",
                    borderWidth: "2px",
                    borderColor: "#007bff",
                    outline: "none",
                  }}
                  onChange={(e) => SetPassword(e.target.value)}
                />
                <br />
                <label style={{ fontFamily: "black" }} className="mt-2 small">
                  Confirm Password
                </label>
                <br />
                <input
                  type="password"
                  value={confirmPassword}
                  className="w-100 border rounded-3 mt-2"
                  placeholder="Password"
                  style={{
                    padding: "0.375rem 2.25rem 0.375rem 0.75rem",
                    borderWidth: "2px",
                    borderColor: "#007bff",
                    outline: "none",
                  }}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <br />
                <p className="small">Password should match</p>
                <button
                  onClick={CreateAccount}
                  className="border-0 rounded-5 px-4 py-2 mt-3 small w-100"
                  style={{ fontFamily: "black", backgroundColor: "#E0D9FA" }}
                >
                  Create
                </button>
                <p className="text-center small mt-3">
                  Already have an account? Login{" "}
                  <Link
                    to="/Login"
                    className="text-decoration-none"
                    style={{ color: "#E0D9FA" }}
                  >
                    here
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Registration;
