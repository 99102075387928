import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { commonHeaders, petSetUrl } from "../utils";
import axios from "axios";
import LoginModal from "../../pages/LoginModal";
import CartContext from "../../Cart/Cart";
import { useContext } from "react";

const EmergencyCalldetail = () => {
  const { user } = useContext(CartContext);
  const [EmergencyCall, setEmergencyCall] = useState();
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  const closeLoginModal = () => {
    setIsLoginModalOpen(false);
  };
  const nav = useNavigate();
  useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: commonHeaders,
    };
    const Api = `${petSetUrl}/v1/video/service?type=Emergency Video Call`;
    fetch(Api, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        // console.log(responseData.data);
        setEmergencyCall(responseData.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const currentDate = new Date(); // Get the current date
  const currentHour = currentDate.getHours();
  const currentMinutes = currentDate.getMinutes();
  const currentSeconds = currentDate.getSeconds();

  // Format the current time
  const formattedTime = `${currentHour}:${currentMinutes}:${currentSeconds}`;

  // Get the current day and date
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const currentDay = daysOfWeek[currentDate.getDay()];
  const currentDateFormatted = currentDate.toISOString().split("T")[0];

  // const handleCheckOut = () => {
  //   const params = {
  //     customer_uid: localStorage.getItem("uid"),
  //     isEmergency: true,
  //     slotTiming: "07:04 PM",
  //     slotDate: "2023-01-26",
  //     slotDay: "Thursday",
  //     price: "210.0",
  //     seller_id: EmergencyCall?.store?.tenant_id ,
  //     store_id: EmergencyCall?.store_id,
  //     selectedSlotDate: "2023-01-26",
  //     pet: "Dog",
  //     cart_id: "006e63e5-ebd0-414e-a9be-272519f2ccae",
  //   };
  // };

  const createCart = async () => {
    try {
      const params = {
        shop_id: EmergencyCall?.store?.sid,
        uid: localStorage.getItem("uid"),
        products: [],
        slots: [],
        dayboarding: [],
      };

      const response = await axios.post(`${petSetUrl}/v1/cart/add`, params, {
        headers: commonHeaders,
      });

      // console.log(response);
      return response.data.cart_id;
    } catch (error) {
      console.error(error);
      throw error; // Rethrow the error to handle it at a higher level if needed
    }
  };

  const handleCheckOut = async () => {
    try {
      const cartId = await createCart();
      // const slotData = localStorage.getItem("DotorSlotData");
      // const doctorSlots = JSON.parse(slotData);

      // const petdata = localStorage.getItem("newpet");
      // const selectedpet = JSON.parse(petdata);
      // console.log(selectedpet);
      const params = {
        service_id: EmergencyCall?.service_id,
        customer_uid: localStorage.getItem("uid"),
        isEmergency: true,
        slotTiming: formattedTime,
        slotDate: currentDateFormatted,
        slotDay: currentDay,
        price: EmergencyCall?.service_price,
        seller_id: EmergencyCall?.store?.tenant_id,
        store_id: EmergencyCall?.store_id,
        // selectedSlotDate: selectedSlotDate,
        pet: localStorage.getItem("EmergencyPetSpecies"),
        cart_id: cartId,
        pet_id: localStorage.getItem("EmergencyPetId"),
      };

      const response = await axios.post(
        `${petSetUrl}/v1/video/slot/add`,
        params,
        {
          headers: commonHeaders,
        }
      );

      // console.log(response);
      alert("Emergency call added");
      nav("/Payment", {
        state: {
          OrderId: response?.data?.order?.cartCartId,
          total: response?.data?.order?.total_price,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <div className="border p-4">
        <div className="row">
          <p className="col-7">Duration</p>
          <h6 className="col-5">30min</h6>
        </div>
        <div className="row ">
          <p className="col-7">Payment</p>
          <h6 className="col-5">
            AED{" "}
            {parseFloat(
              (EmergencyCall?.service_price / (1 + 5 / 100)).toFixed(2)
            )}
          </h6>
        </div>
        <div className="row border-bottom pb-2 ">
          <p className="col-7">VAT</p>
          <h6 className="col-5">
            AED{" "}
            {(
              EmergencyCall?.service_price -
              EmergencyCall?.service_price / (1 + 5 / 100)
            ).toFixed(2)}
          </h6>
        </div>
        <div className="row pt-2">
          <p className="col-7">Total</p>
          <h6 className="col-5">AED {EmergencyCall?.service_price}</h6>
        </div>
        {/* <Link to=""> */}
        <button
          // onClick={handleCheckOut}
          onClick={() => {
            user ? handleCheckOut() : setIsLoginModalOpen(true);
          }}
          className="border-0 rounded-5 p-2 px-5 w-100"
          style={{ backgroundColor: "#E0D9FA", fontFamily: "black" }}
        >
          Checkout
        </button>
        {/* </Link> */}
      </div>

      <LoginModal isOpen={isLoginModalOpen} onRequestClose={closeLoginModal} />
    </>
  );
};

export default EmergencyCalldetail;
