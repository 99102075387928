import pet from "../../images/pet.png";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { commonHeaders, petSetUrl } from "../utils";
import paw from "../../images/logo1.png";

const AllPets = () => {
  const navigate = useNavigate();
  const [Allpets, setAllpets] = useState([]);
  const [selectedpet, setselectedpet] = useState(
    localStorage.getItem("selectedpetId")
  );

  useEffect(() => {
    const uid = localStorage.getItem("uid");
    const Api = `${petSetUrl}/v1/pet/get?owner_id=${uid}`;
    const requestOptions = {
      method: "GET",
      headers: commonHeaders,
    };

    fetch(Api, requestOptions)
      .then((response) => response.json())
      .then((responseData) => {
        // console.log(responseData);
        setAllpets(responseData.data);
      })
      .catch((error) => {
        console.error("Error fetching store data from the API:", error);
      });
  }, []);

  const handleDelete = (petId) => {
    const DeleteApi = `${petSetUrl}/v1/pet/delete?pid=${petId}`;
    const requestOptions = {
      method: "GET",
      headers: commonHeaders,
    };

    fetch(DeleteApi, requestOptions)
      .then((response) => response.json())
      .then((responseData) => {
        // console.log(responseData);
        setAllpets((prevPets) => prevPets.filter((pet) => pet.pid !== petId));
        Swal.fire("Pet deleted successfully");
      })
      .catch((error) => {
        console.error("Error fetching store data from the API:", error);
      });
  };

  const handleRadioChange = (petId) => {
    const uid = localStorage.getItem("uid");
    const Api = `${petSetUrl}/v1/pet/get?owner_id=${uid}`;
    const requestOptions = {
      method: "GET",
      headers: commonHeaders,
    };

    fetch(Api, requestOptions)
      .then((response) => response.json())
      .then((responseData) => {
        // console.log(responseData);
        const selected = responseData.data.find((item) => item.pid === petId);

        localStorage.setItem("newpet", JSON.stringify(selected));
        localStorage.setItem("selectedpetId", petId);
        setselectedpet(selected);

        window.location.reload();
      })
      .catch((error) => {
        console.error("Error fetching store data from the API:", error);
      });
  };

  return (
    <>
      <div className="container-fluid mt-5" style={{ padding: "0 5%" }}>
        <div className="row">
          {Allpets && Allpets.length > 0 ? (
            Allpets.map((item, index) => (
              <div className="col-md-4 mb-4" key={index}>
                <div className="card" style={{ backgroundColor: "#F4F4F5" }}>
                  <div className="card-body">
                    <div className="d-flex">
                      <img
                        // src={item.pet_image}
                        src={
                          item.pet_image
                            ? item.pet_image?.substring(0, 4) === "http"
                              ? item.pet_imag
                              : "https://petsetgostorage.blob.core.windows.net/petsetgo-u2/" +
                                item.pet_image
                            : pet
                        }
                        alt=""
                        height="40px"
                        className="mt-1"
                        // onError={cat}
                      />
                      <div className="lh-1 ms-3">
                        <h6 className="small">
                          {item.pet_name || "My Aquarium"}
                        </h6>
                        <p>{item.breed}</p>
                      </div>
                    </div>
                    <div className="row mt-3 ms-2">
                      <p
                        className="col-3 small"
                        style={{ cursor: "pointer", color: "#F97316" }}
                        onClick={() =>
                          item.pid &&
                          navigate("/Editpet", {
                            state: {
                              petId: item.pid,
                            },
                          })
                        }
                      >
                        Edit
                      </p>

                      <p
                        className="col-3 small text-danger"
                        style={{ cursor: "pointer", color: "#F97316" }}
                        onClick={() => handleDelete(item.pid)}
                      >
                        Delete
                      </p>
                    </div>
                    <div className="col-12">
                      <input
                        type="radio"
                        id={`petRadio-${index}`}
                        name="petRadio"
                        checked={selectedpet === item.pid}
                        onChange={() => handleRadioChange(item.pid)}
                      />
                      <label htmlFor={`petRadio-${index}`} className="ms-2">
                        Select pet
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div>
              <p>Pets not defined</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AllPets;
