import StoreProductHead from "../components/StorePage/StoreProductHead";
import StoreProductDetail from "../components/StorePage/StorProductDetail";
// import StoreProductArticlce from "../components/StorePage/StoreProductArticles";
import FiftyOff from "../components/Home/FiftyOff";
import ServiceProduct from "../components/Services/ServiceProduct";
import { useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { petSetUrl, commonHeaders } from "../components/utils";
const StoreProduct = () => {
  const location = useLocation();
  // const { storeId } = useParams();
  const storeId = location?.state?.store_id;
  const [storeData, setstoreData] = useState({});
  const StorProductHeadComponent = location.pathname !== "/Servicesproduct" && (
    <StoreProductHead />
  );
  const StorProductDetailComponent = location.pathname !==
    "/Servicesproduct" && <StoreProductDetail />;
  // const StorProductArticlesComponent = location.pathname !==
  //   "/Servicesproduct" && <StoreProductArticlce />;
  const ServiceProductComponent = location.pathname === "/Servicesproduct" && (
    <ServiceProduct />
  );

  const getStore = () => {
    const storedLatitude = localStorage.getItem("lat");
    const storedLongitude = localStorage.getItem("lng");
    const Api = `${petSetUrl}//v1/store/get?lng=${storedLongitude}&lat=${storedLatitude}`;
    const requestOptions = {
      method: "GET",
      headers: commonHeaders,
    };
    fetch(Api, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        const matchingRecord = responseData.shops.find(
          (record) => record.sid === storeId
        );
        setstoreData(matchingRecord);
      })
      .catch((error) => {
        console.error("Error fetching store data from the API:", error);
      });
  };
  useEffect(() => {
    getStore();
  }, []);

  return (
    <>
      <div className="mt-5" style={{ padding: "1% 0%" }}>
        <p className="mb-4" style={{ margin: "0% 5%" }}>
          Home > Store > {storeData.store_name}
        </p>
        {StorProductHeadComponent}
        {StorProductDetailComponent}
        {/* {StorProductArticlesComponent} */}
        {ServiceProductComponent}
        <div className="mt-5">
          <FiftyOff />
        </div>
      </div>
    </>
  );
};

export default StoreProduct;
