const EmptyCart = () => {
  return (
    <>
      <div
        className="container-fluid p-5"
        style={{ backgroundColor: "#E4E4E7" }}
      >
        <div className="container d-flex justify-content-center align-items-center flex-column">
          <p className="w-75">
            You dont have nay items in your carts. Please continue shopping.
          </p>
        </div>
      </div>
    </>
  );
};
export default EmptyCart;
