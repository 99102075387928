import { Accordion } from "react-bootstrap";
import Checkoutaddress from "./Checkoutaddress";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { petSetUrl, commonHeaders } from "../utils";
const Checkoutdetail = () => {
  const nav = useNavigate();
  const [userName, setUserName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    const Api = `${petSetUrl}/v1/customer/get?uid=${localStorage.getItem(
      "uid"
    )}`;
    const requestOptions = {
      method: "GET",
      headers: commonHeaders,
    };
    fetch(Api, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        // console.log(responseData);
        // localStorage.setItem("firstname", responseData.data.first_name);
        // localStorage.setItem("lastname", responseData.data.last_name);
        // localStorage.setItem("Email", responseData.data.email);
        // localStorage.setItem("Phone", responseData.data.phone);
        setUserName(responseData.data.first_name);
        setPhone(responseData.data.phone);
        setEmail(responseData.data.email);
        // redirect(-1)
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleUserNameChange = (event) => {
    const newValue = event.target.value;
    setUserName(newValue);
  };

  const handlePhoneChange = (event) => {
    const newValue = event.target.value;
    setPhone(newValue);
  };

  const handleEmailChange = (event) => {
    const newValue = event.target.value;
    setEmail(newValue);
  };

  const handleUpdateClick = () => {
    // localStorage.setItem("firstname", userName);
    // localStorage.setItem("Phone", phone);
    // localStorage.setItem("Email", email);
    // window.location.reload();
    nav("/Information");
  };
  return (
    <>
      <Accordion defaultActiveKey="0" flush className="border">
        <Accordion.Item eventKey="0">
          <Accordion.Header style={{ border: "none", boxShadow: "none" }}>
            User Information
          </Accordion.Header>
          <Accordion.Body>
            <label style={{ fontFamily: "black" }} className="small">
              Full Name
            </label>
            <br />
            <input
              className="w-50 border rounded-3 mt-2"
              type="text"
              value={userName}
              onChange={handleUserNameChange}
              disabled
              style={{
                padding: "0.375rem 2.25rem 0.375rem 0.75rem",
                borderWidth: "2px",

                outline: "none",
              }}
            />{" "}
            <br />
            <label style={{ fontFamily: "black" }} className="small">
              Phone
            </label>
            <br />
            <input
              className="w-50 border rounded-3 mt-2"
              type="text"
              value={phone}
              onChange={handlePhoneChange}
              disabled
              style={{
                padding: "0.375rem 2.25rem 0.375rem 0.75rem",
                borderWidth: "2px",

                outline: "none",
              }}
            />
            <br />
            <label style={{ fontFamily: "black" }} className="small">
              Email
            </label>
            <br />
            <input
              className="w-50 border rounded-3 mt-2"
              type="text"
              value={email}
              onChange={handleEmailChange}
              disabled
              style={{
                padding: "0.375rem 2.25rem 0.375rem 0.75rem",
                borderWidth: "2px",
                outline: "none",
              }}
            />
            <br />
            {/* <button onClick={handleUpdateClick}>Update Values</button> */}
            <button
              className=" border-0 rounded-5 p-2 px-5 mt-4 "
              style={{ backgroundColor: "#E0D9FA", fontFamily: "black" }}
              onClick={handleUpdateClick}
            >
              Update
            </button>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion defaultActiveKey="1" flush className="border">
        <Accordion.Item eventKey="1">
          <Accordion.Header style={{ border: "none", boxShadow: "none" }}>
            Addresses
          </Accordion.Header>
          <Accordion.Body>
            <Checkoutaddress />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      {/* <Accordion defaultActiveKey="1" flush className="border mt-3">
        <Accordion.Item eventKey="1">
          <Accordion.Header >
            Payment Method
          </Accordion.Header>
          <Accordion.Body style={{ Height: "auto" }}>
            <Checkoutpayment />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion> */}
    </>
  );
};
export default Checkoutdetail;
