import FiftyOff from "../components/Home/FiftyOff";
const Terms = () => {
  return (
    <>
      <div className="termpage">
        <div className="mt-10 title">Home > Terms</div>
        <div className="title">Pet Set GO</div>
        <div className="terms">
          <span className="dtext" style={{ alignSelf: "center" }}>
            Terms and Conditions
          </span>
          <span>
            Please read the following Terms and Conditions (defined below)
            carefully – they are legally binding. These Conditions apply to the
            exclusion of any other terms that You seek to impose or incorporate,
            or which are implied by law, trade custom, practice or course of
            dealing.
          </span>
          <span>
            All references to “You” or “Your,” as applicable, mean the person
            that accesses, uses, and/or participates in the Platform in any
            manner. If you use the Platform, you represent and warrant that you
            have the authority to legally bound and your acceptance of the Terms
            of Conditions.
          </span>
          <h4>BASIS OF CONTRACT</h4>
          <span>
            These Terms and Conditions (the "Terms") are a binding legal
            agreement between You and Pet Set GO, with company number 9f257 and
            registered office at Office In5 Tech, EO 5, Dubai Internet City,
            Dubai, United Arab Emirates (“Pet Set GO," "we," "us" and "our").
            They govern your use of our website (www.Petsetgo.com) (the "Site")
            together with our mobile applications, resources and services and
            connect You with Service Providers and veterinarians to find each
            other, communicate, and facilitate pet products and Services (the
            “Pet Set GO Service").
          </span>
          <h4>1. DEFINITIONS</h4>
          <span>
            As used in this Agreement, the following terms shall have the
            following meanings specified or indicated below, and such meanings
            shall be equally applicable to the singular and plural forms of such
            defined terms.
          </span>
          <span>
            The section headings contained in this Agreement are for convenient
            purposes only and shall not affect the interpretation of this
            Agreement.
          </span>
          <h5>Applicable Law: </h5>
          <span>
            all national, state, local, municipal legislation, regulations,
            statutes, by-laws, consents and any other instrument having the
            force of law as may be issued and in force from time to time in the
            United Arab Emirates (and any amendment or subordinate provisions)
            relating to or connected with supply of the Goods and Services
            contemplated under this Contract.
          </span>
          <h5>Animal/Pet:</h5>
          <span>
            means your pet in respect of which You are seeking goods and
            services for, which may include large, medium and small animals and
            those that are kept as pets as well as working animals.
          </span>
          <h5>Business Day: </h5>
          <span>
            a day (other than a Saturday, Sunday or public holiday) when banks
            in Dubai, United Arab Emirates are open for business.
          </span>
          <h5>Conditions: </h5>
          <span>these terms and conditions.</span>
          <h5>Contract: </h5>
          <span>
            the contract between You and Pet Set GO for the sale and purchase of
            Goods and Services provided by third-parties Service Providers in
            accordance with these Terms and Conditions.
          </span>
          <h5>Customer: </h5>
          <span>
            You the person or firm who purchasers the Goods or Services via out
            Pet Set GO platform. This incl udes a na t ura l person, corpora t e
            or unincorporated body (whether or not having separate legal
            personality).
          </span>
          <h5>Defective Goods:</h5>
          <span>
            when it is not reasonably fit, suitable, and safe for its intended
            or foreseeable purposes.
          </span>
          <h5>Delivery Date: </h5>
          <span>
            the date specified for Delivery of an Order as set out in the Order.
          </span>
          <h5>Delivery Location: </h5>
          <span>
            the address specified for Delivery of each Order as set out in the
            Order receipt.
          </span>
          <h5>Force Majeure Event: </h5>
          <span>has the meaning given to it in Clause 21.</span>
          <h5>Goods: </h5>
          <span>the goods (or any part of them) set out in the Order.</span>
          <h5>Intellectual Property Rights: </h5>
          <span>
            Includes patents, rights to inventions, copyright and related
            rights, moral rights, trademarks and service marks, business names
            and domain names, rights in get-up, goodwill and the right to sue
            for passing off or unfair competition, rights in designs, rights in
            computer software, database rights, rights to use, and protect the
            confidentiality of, confidential information (including know-how and
            trade secrets), and all other intellectual property rights, in each
            case whether registered or unregistered and including all
            applications and rights to apply for and be granted, renewals or
            extensions of, and rights to claim priority from, such rights and
            all similar or equivalent rights or forms of protection which
            subsist or will subsist now or in the future in any part of the
            world.
          </span>
          <h5>Material Breach: </h5>
          <span>
            material Breach means a breach (including an anticipatory breach)
            that is serious in the widest sense of having a serious effect on
            Pet Set Go business.
          </span>
          <h5>Notice: </h5>
          <span>
            a receiving party will notify the disclosing party of the
            information it is providing.
          </span>
          <h5>Order: </h5>
          <span>
            Your orders for products or service through the Digital
            Platform/App/Website.
          </span>
          <h5>Party: </h5>
          <span>
            You and Pet Set GO individually, and Parties means You and Pet Set
            GO collectively.
          </span>
          <h5>Pet Owner: </h5>
          <span>
            A User who owns a pet and is seeking products and services using the
            Pet Set GO platform.
          </span>
          <h5>Pet Set GO Platform: </h5>
          <span>
            includes Pet Set GO’s App, website, call centre and technology
            platform collectively the Pet Set GO platform Proprietary shopping
            system and web-based administration panel. The shopping system is
            composed of an Android mobile application, an iOS mobile
            application, and a Web Shop, together known as the “Apps”.
          </span>
          <h5>Services: </h5>
          <span>
            includes pet grooming, boarding and day care, training, relocation
            and veterinary Services.
          </span>
          <h5>Service Provider: </h5>
          <span>
            The third-party Service Providers or Businesses in Which supplies
            Goods and Services on the Pet Set Go Platform to You.
          </span>
          <h5>Terms: </h5>
          <span>The terms and Conditions</span>
          <h5>We: </h5>
          <span>Pet Set GO and You.</span>
          <h5>You/Your: </h5>
          <span>
            means the person seeking veterinary services for an Animal or wishes
            to buy products from us.
          </span>
          <h5>We: </h5>
          <span>Pet Set GO.</span>
          <h4>2.1 TERMS OF CONTRACT</h4>
          <span>
            The following are the agreed Terms and Conditions set forth below
            (“Terms”) constitute a legally binding agreement between Pet Set GO,
            a Company incorporated and registered in United Arab Emirates, In5
            Tech, EO 5, Dubai Internet City, Dubai, United Arab Emirates and
            You.
          </span>
          <span>
            These Terms of Use contain provisions that define your limits, legal
            rights and obligations with respect to your use of and participation
            in (i) the Pet Set GO Website, Communication System and Mobile
            Application, including the classified advertisements, forums,
            various emails, chat functions and Internet links, and all content
            and Pet Set GO services available through the domain and sub-domains
            of Pet Set GO located at www. petsetgo.com as well as the Mobile
            Application and Communication System associated with petsetgo.com
            (collectively referred to herein as the “Platform”), and (ii) the
            online transactions between You the users of the Platform who are
            offered services by businesses (“Service Provider”) and are
            obtaining services and products through the Platform (such services,
            collectively, the “Services”). The Terms of Use described below
            apply to all Users of the Platform, including Users who are also
            contributors of video content, information, private and public
            messages, advertisements, and other materials or Services on the
            Platform.
          </span>
          <span>
            You acknowledge that the Platform serves as a venue for the online
            distribution and publication of user submitted information between
            You and Service Providers, and, by using, visiting, registering for,
            and/ or otherwise participating in this Platform, including the
            Services presented, promoted, and displayed on the Platform, and by
            proceeding with registration with Pet Set GO You hereby certify
            that: (1) You are a User (2) You have the authority to enter into
            these Terms and Conditions (3) You authorise the transfer of payment
            for Products and Services requested through the use of the Platform,
            and (4) You agree to be bound by all terms and conditions of these
            Terms of Use and any other documents incorporated by reference
            herein. If you do not agree to any of the provisions set forth in
            the Terms of Use, kindly discontinue viewing or participating in
            this Platform immediately. You further acknowledge that Pet Set GO
            will be in no way be responsible or liable for any loss or damages
            with respect to any transactions performed between You and any
            Service Provider made via the Pet Set GO Platform.
          </span>
          <h4>NON-OWNERSHIP IN GOODS AND SERVICES</h4>
          <span>
            Pet Set GO is as an intermediary service provider which Connects
            Businesses and Pet Owners and provides comparisons for different
            offers for pet products, boarding services, pet day care services,
            grooming services, veterinary services, and any other related
            services the platform decides to incorporate in the future as well.
          </span>
          <span>
            Pet Set GO does not provide, possess, or have any control over any
            of the Services or Goods advertised on the Platform. All booking
            contracts between You and the Service Providers are exclusively Your
            responsibility, as are any Pet Services and Products that are
            purchased, control, or offer via the Pet Set GO platform.
          </span>
          <span>
            The booking of a Product or Service takes place solely on the
            platforms of Pet Set GO. Your terms and Terms and privacy policies
            apply to Your bookings, so you must agree to, and understand those
            terms.
          </span>
          <span>
            Pet Set GO will display offers for Goods and Services, e.g.,
            Discounts, Bundle offers, monthly subscriptions and others. Pet Set
            GO does not provide, own or control any of the Services or Goods
            that are located through our Platform unless it is explicitly
            stated. The Products and Services are owned, controlled or made
            available by third parties Service Providers. Service Providers are
            responsible for the Pet services and Goods and all booking
            contracts. Your terms and privacy policies apply to your booking, so
            you must agree to, and understand those terms.
          </span>
          <span>
            All interaction between You and the third-party Service Providers
            accessed through our Platform is at your own risk, and Pet Set GO
            shall not hold any responsibility or liability should anything go
            wrong Pet Set GO has no control over the Products or Service.
          </span>
          <span>
            Pet Set GO may host content, including prices, images and other
            information, made available by or obtained from the third-party
            Service Providers, as well as content provided by Users and other
            sources such as comments, ratings, reviews and other information.
            Pet Set GO is in no way responsible or liable for the accuracy,
            quality, completeness, reliability, timeliness or trustworthiness
            off the Content, and Pet Set GO has no influence over the Content.
            Materials posted on the Pet Set GO Platform are not intended to
            amount to advice on which reliance should be placed. We therefore
            disclaim all liability and responsibility arising from any reliance
            placed on such materials by any visitor to the Pet Set GO Platform,
            or by anyone who may be informed of any of its contents.
          </span>
          <span>
            Ratings displayed on this Platform are intended as only general
            guidelines, and we do not guarantee the accuracy of the ratings or
            of any User Content.
          </span>
          <span>
            Pet Set GO does not endorse such third-party Services and content
            and you agree that, in no event, will Pet Set GO be responsible or
            liable for any products or services of such third-party Service
            Providers.
          </span>
          <span>
            When purchasing goods or services provided by a third-party Service
            Provider, you agree and understand that you are doing so subject to
            the relevant third-party Service Provider’s terms and conditions and
            at your own risk. You agree that Pet Set GO will not be liable for:
            (i) any act or omission of a third-party Service Provider and/or
            (ii) any damage or loss that arises in connection with the ordering,
            purchase and/or provision of a third-party Service Provider’s goods
            or service.
          </span>
          <h4>MODIFICATIONS TO TERMS OF USE</h4>
          <span>
            Pet Set GO reserves the right to amend the Terms & Conditions, and
            any other documents incorporated by reference herein, at any time,
            and Pet Set GO will post notice of the changes and the amended Terms
            at the domain of https://www.petsetgo.com/terms, and/or may
            communicate the amendments through any method of written contact
            that Pet Set GO has established with You.
          </span>
          <span>
            It is your responsibility to review the Terms of Conditions for any
            changes. Your use of the Platform following any amendment of the
            Terms of Use will signify your assent to and acceptance of any
            revised Terms of Conditions. If you do not agree to abide by these
            or any future Terms and conditions, please do not use or access the
            Platform
          </span>
          <h4>ACCESS AND USE OF THE PLATFORM</h4>
          <span>
            The Platform is currently made available to You for commercial use
            only.
          </span>
          <span>
            We do not guarantee that access to the Platform, or any content on
            it, will always be available or be uninterrupted. We may suspend,
            withdraw, discontinue or change all or any part of the Platform
            without notice to You.
          </span>
          <span>
            You must not misuse the Platform by knowingly introducing viruses,
            trojans, worms, logic bombs or any other material which is malicious
            or technologically harmful. You must not attempt to gain
            unauthorised access to the Platform, the server on which the
            Platform is stored, or any server, computer or database connected to
            the Platform. You must not attack the Platform via a
            denial-of-service attack or a distributed denial-of service attack.
            We will report any such breach to the relevant law enforcement
            authorities, and we will co-operate with those authorities, by
            disclosing your identity to them. In the event of such a breach,
            your right to use the Platform will cease immediately.
          </span>
          <h4>REGISTRATION & YOUR PERSONAL INFORMATION</h4>
          <span>
            It is essential for us to maintain accurate records of our customers
            and their Animals. In order to do this, we will periodically ask You
            to confirm the details we hold for You. Up-to-date contact details
            are particularly important. If Your details change at any point,
            please inform us so we may ensure our database is as up to date as
            possible.
          </span>
          <span>
            We may contact You either by letter, phone, text or electronic means
            in order to advise You of outstanding accounts, reminders of Your
            Animal's preventative health care treatments and any marketing
            offers that might be of interest to you or your Animal. Please
            notify us if You wish no contact to be made or make Your selection
            clear when You are asked online.
          </span>
          <span>
            Our "Privacy Policy" is available at www.petsetgo.com/privacy
          </span>
          <span>
            Your privacy and personal information are important to us. Any
            personal information that You provide to us will be dealt with in
            line with our Privacy Policy, which explains what personal
            information we collect from You, how and why we collect, store, use
            and share such information, Your rights in relation to Your personal
            information and how to contact us and supervisory authorities if You
            have a query or complaint about the use of Your personal
            information.
          </span>
          <span>
            You shall create a User account that enables You access to the Pet
            Set GO Platform. Any decision by a User to make use of or accept
            Services is a decision made in Your sole discretion. Each Service
            provided by a third-party Service Provider to You shall constitute a
            separate agreement between such persons.
          </span>
          <span>
            You further understand that to enable a third-party Service Provider
            to provide You with relevant goods or services, Pet Set GO may be
            required to share personal information in the manner described in
            Pet Set GO’s Privacy Notice.
          </span>
          <span>
            In order to use most aspects of the Pet Set GO Platform, You must
            register for and maintain an active personal User account
            (“Account”). You must be at least 1f years of age to obtain an
            Account. Account registration requires You to submit to Pet Set GO
            certain personal information, such as Your name, address, mobile
            phone number, gender and age and information about Your Pet. You
            agree to maintain accurate, complete, and up-to-date information in
            Your Account. Your failure to maintain accurate, complete, and
            up-to-date Account information may result in Your inability to
            access and use the Pet Set GO Platform, including Your ability to
            request access to Your personal information or to opt in or out of
            marketing preferences, or Pet Set GO’s termination of this Agreement
            with You.
          </span>
          <span>
            You agree to maintain the security and secrecy of Your Account
            username and password at all times. You agree and understand that
            You are responsible for all activity that occurs under Your Account,
            even as a result of loss, damage or theft of the device through
            which You access the Pet Set GO Platform.
          </span>
          <span>
            Unless otherwise permitted by Pet Set GO in writing, You may only
            possess one Account.
          </span>
          <h4>SERVICES</h4>
          <span>
            You acknowledge that Pet Set GO provides a platform for pet owners
            and pet Service Providers to connect and arrange for pet-related
            services. These services include, but are not limited to, pet
            grooming, pet boarding and day care, pet walking, and pet training,
            relocation, and veterinary services. By using the Pet Set GO, you
            understand and agree that Pet Set GO is not a party to any
            agreements entered into between pet owners and third-party Service
            Providers, and we do not endorse or guarantee any services provided
            by Service Providers.
          </span>
          <span>
            Pet Set GO will not be liable for any damages or accidents arising
            out of or in connection to the sale of Goods and Services provided
            by third-party Service Providers.
          </span>
          <span>
            All animals boarding must, to the best of Your knowledge, be in good
            health and all medical conditions fully explained to the third-party
            Service Providers prior to boarding. We reserve the right to refuse
            to accept any pet that we consider shows any signs of illness or
            injury, or parasitic infection.
          </span>
          <span>
            For rabbits, we require a copy of your up-to-date vaccination
            certificate.
          </span>
          <span>
            All rabbits should arrive and depart in a suitable carrier provided
            by the client, smaller pets e.g, gerbils, mice, birds etc will
            arrive in their own escape proof housing. Dogs must arrive and
            depart in a leash.
          </span>
          <span>
            Should Your Pet become ill and require medical treatment whilst
            boarding with the third-party Service Providers, we will endeavour
            to keep You fully informed via your emergency contact details and
            arrange treatment with our regular veterinary practice. You will be
            required to pay for all veterinary fees on collection of Your pet,
            including transportation to and from the vet.
          </span>
          <span>
            Whilst we will take every precaution to fully care for and protect
            Your pet, we cannot be held responsible and will accept no liability
            for any illness, injury or the unlikely event of death during their
            stay. You will be responsible for any costs incurred arising out of
            these scenarios.
          </span>
          <h4>GOODS</h4>
          <span>
            When You decide to place an order for Goods with us, this is when
            You offer to buy such goods from us.
          </span>
          <span>
            We may contact You to say that we do not accept Your order. If we do
            this, we will try to tell you promptly why we do not accept Your
            order e.g., because we do not have the goods in stock, available or
            because we are unable to sell them to You or provide you with a
            certain service.
          </span>
          <span>
            Any goods supplied to You will be: of satisfactory quality; and fit
            for the purpose as expressly agreed by third- party Service
            Providers providing the veterinary services under this Contract and
            must only be used in accordance with the instructions supplied with
            them including within the packaging of the goods and as directed by
            the third-party Service Providers providing any veterinary services
            relating to the same.
          </span>
          <span>
            If the third-party Service Providers cannot supply certain Goods
            they may need to substitute them with alternative goods of equal or
            better standard and value. In this case:
          </span>
          <span>
            they will let You know if they intend to do this but this may not
            always be possible; and
          </span>
          <span>
            You can refuse to accept such substitutes; in which case we will
            offer You a refund or a replacement and let you know how long such
            an offer remains open for.
          </span>
          <span>
            If there is something wrong with the goods or services provided to
            You under this contract, please contact us using the contact details
            at the top of this page. You may ask:
          </span>
          <span>
            (a)us to replace the goods; (b)us to repeat the services; for a
            price reduction; or
          </span>
          <span>
            to reject the goods or services (or both) we have provided to You
            under this contract,
          </span>
          <span>
            We will discuss this with You in good faith and ensure that your
            matter is resolved in accordance with Your legal rights.
          </span>
          <span>
            A re-stocking fee may be charged if goods ordered by You, for
            example repeat Goods, are not collected within a reasonable time. A
            higher re-stocking fee may be applied for special orders which are
            not collected. The full retail price will be charged for uncollected
            special-order goods in addition to a re-stocking fee.
          </span>
          <h4>LICENSE</h4>
          <span>
            Subject to your compliance with this Agreement, Pet Set GO grants
            you a limited, non-exclusive, non- sub-licensable, revocable,
            non-transferrable license to: (i) access and use the Pet Set GO
            Platform on Your personal device solely in connection with your use
            of the Pet Set GO Platform; and (ii) access and use any content,
            information and related materials that may be made available through
            the Pet Set GO Platform, in each case solely for your personal,
            non-commercial use. Pet Set GO and its licensors reserves all rights
            not expressly granted herein.
          </span>
          <h4>USERS OBLIGATIONS</h4>
          <span>
            You are responsible for reviewing and choosing Service Providers
            that You feel are qualified and suitable to provide the Services
            that You are seeking.
          </span>
          <span>
            You must comply with all Applicable laws and regulations in
            connection with the use of the Pet Set GO platform and the Services
            provided by Service Providers.
          </span>
          <span>
            You are also responsible for ensuring that Your own pets are
            properly cared for and treated during the provision of Services.
            Additionally, You must provide accurate and complete information
            when creating their account and requesting Services and must
            promptly update Your account information as needed.
          </span>
          <span>
            You must also act in a respectful and professional manner when
            interacting with Service Providers and other Service Users on the
            Pet Set GO platform.
          </span>
          <h4>ANIMAL INSURANCE</h4>
          <span>
            We strongly support the principle of insuring Your Animal against
            unexpected illness or accidents and in no circumstances will Pet Set
            GO be liable to insure Your Pet for any illness or accidents. In all
            instances You will have to deal with Your insurers directly.
          </span>
          <span>
            You acknowledge that it is Your responsibility to ensure you have
            carefully read and adhered to the terms and conditions of Your
            Animal Insurance Company because Pet Set GO will not be held liable
            for Your failure to do so, nor will Pet Set GO send you any
            reminders.
          </span>
          <span>
            Any anticipated shortfall in the cost of treatment that is not
            covered by Your insurance company will be payable by You to
            third-party Service Providers at the time of making the first direct
            claim. Any additional shortfall that is owing after the claim has
            been settled will also be payable by You to us at that time.
          </span>
          <span>
            You will be responsible for settling Your account in the normal way
            directly with the third-party Service Providers at the time of
            treatment.
          </span>
          <h4>FEES AND PAYMENTS</h4>
          <span>
            All fees, deductible amounts and other payments charged through the
            Pet Set GO platform are listed and payable in Dirhams (AED).
          </span>
          <span>
            As a User, you agree to pay any amounts charged by Captains for
            providing Services to you (“Charges”).
          </span>
          <span>
            All Charges are due immediately and payment will be facilitated by
            Pet Set GO using the preferred payment method designated in your
            Account, after which you may be sent a payment receipt on behalf of
            a third-party Service Provider by email. If your primary Account
            payment method is determined to be expired, invalid or otherwise not
            able to be charged, you agree that Pet Set GO may, as the
            third-party Service Providers payment collection agent, use a
            secondary payment method in your Account, if available.
          </span>
          <span>
            You acknowledge and agree that Charges may be varied or revised at
            any time without your consent and that in certain geographical areas
            Charges may increase substantially during times of high demand. Pet
            Set GO will use reasonable endeavours to inform you of Charges that
            may apply, provided that you will be responsible for Charges
            incurred under your Account regardless of your awareness of such
            Charges, as applicable.
          </span>
          <span>
            After you have received Services from a third- party Service
            Provider, Pet Set GO will accept payment of the applicable Charges
            on behalf of the third-party Service Provider. Payment of the
            Charges in the above manner shall in all cases be considered the
            same as payment made directly by You to the third-party Service
            Provider. Charges will be inclusive of applicable taxes where
            required by law, including any applicable VAT. Charges paid by you
            are final and non- refundable, unless otherwise determined by Pet
            Set GO or the relevant third-party Service Provider.
          </span>
          <span>The price for Goods:</span>
          <span>
            shall be the price set out in the Order or, if no price is quoted,
            the price set out in the third- party Service Providers published
            price list as at the date of the order; and shall be exclusive of
            all costs and charges of packaging, insurance, transport of the
            Goods, which shall be invoiced to User.
          </span>
          <span>
            When we come to charge you for the goods and services provided when
            we have previously only provided an estimate, we will charge you for
            the veterinary services based on the time and skill level required
            for the services actually provided. We also charge according to the
            goods and consumables used.
          </span>
          <span>
            Our charges may be based on the allocation of appointments, or/and
            the consultation process, with or without an Animal present. We may
            also charge you fees for missed appointments where these have been
            pre-booked includ ing for operations and consultations.
          </span>
          <span>
            All fees charged to you are exclusive of VAT. Once Pet Set GO is
            eligible to apply for VAT it will be applied according the
            applicable laws of United Arab Emirates without notice.
          </span>
          <span>
            We will invoice you and your account is due for settlement at the
            end of the consultation, the discharge of your Animal, or upon
            collection of Goods applicable. You may request a detailed and
            itemised breakdown for your invoice.
          </span>
          <span>
            You acknowledge and agree that if You are the registered owner of
            the Animal according to our records, You are liable for any fees
            incurred in the diagnosis and treatment of Your Pet even if Your
            Animal is brought to us on your behalf by a relative, friend, or
            agent or otherwise by a third party. We will endeavour to obtain
            written confirmation from the relative, friend, or agent who has
            brought Your Animal to us that they have Your consent in respect of
            the treatment to be provided to Your Pet. However, You acknowledge
            that this may not always be possible. Regardless of this, You agree
            to be liable in respect of any such fees incurred in the diagnosis
            and treatment of Your Animal.
          </span>
          <span>
            Please do keep us updated if the registered owner of Your Pet
            changes so that we can keep our records up to date. If You have not
            updated us prior to charges being incurred under these terms, we may
            approach the registered owner on our system for payment.
          </span>
          <span>
            We accept cash and the following credit cards and debit cards:
            MasterCard, Amex, Visa, and Delta. We do not accept cheques.
          </span>
          <span>
            Any credit or debit card payment not honoured, and any cash tendered
            that is found to be counterfeit will result in the original account
            being restored to the original sum with further charges added in
            respect of bank charges and administration costs together with
            interest on the invoice amount.
          </span>
          <span>We reserves the right to:</span>
          <span>
            (a) increase the charges for the Goods and Services without written
            notice to reflect any increase in the cost of the Goods to You that
            is due to:
          </span>
          <span>
            any factor beyond our control (including foreign exchange
            fluctuations, increases in taxes and duties, and increases in
            labour, materials and other manufacturing costs);
          </span>
          <span>
            any request by You to change the delivery date(s), quantities or
            types of Goods ordered, or the Goods Specification; or any delay
            caused by any instructions of You in respect of the Goods or Your
            failure to give us adequate or accurate information or instructions
            in respect of the Goods.
          </span>
          <span>
            13.15 In respect of Goods, we shall invoice You at the time of the
            booking order.
          </span>
          <h4>OVERNIGHT STAYS</h4>
          <span>
            Where Your Animal requires an overnight stay, we will discuss this
            with You in advance. We understand that different levels of care are
            needed in differing circumstances, and we will discuss with You
            where Your Animal will stay, the cost, monitoring requirements, pain
            relief and other medical requirements as well as emotional
            requirements of Your Animal. Please ask for a copy of the Service
            Providers policy on overnight care for further details.
          </span>
          <span>
            As with the out of hours care, overnight stays may lead to higher
            costs. Where we are not able to provide this service ourselves, we
            will notify You.
          </span>
          <span>
            Pet Set GO is not liable for animals that are being held overnight,
            transported by third-party Service Providers, advice given by a
            third-party veterinarian to You, Animals that are scheduled for any
            services whether in person, online or through the Pet Set GO app.
            You and the third-party Service provider are the only parties
            responsible for the welfare of the Your Animal.
          </span>
          <h4>TELEPHONE RECORDINGS</h4>
          <span>
            Please note that all our calls both incoming and outgoing are
            recorded for training and monitoring purposes.
          </span>
          <h4>DATA PROTECTION</h4>
          <span>The following definitions apply in this Clause 16:</span>
          <span>
            Controller, Processor, Data Subject, Personal Data, Personal Data
            Breach, processing and appropriate technical and organisational
            measures: as defined in the Data Protection Legislation.
          </span>
          <span>
            Data Protection Legislation: all applicable data protection and
            privacy legislation in force from time to time in the Unit including
            the United Arab Emirates, The Federal Decree Law No. 45 of 2021 and
            the guidance and codes of practice issued by the Information
            Commissioner or other relevant regulatory authority and applicable
            to a party.
          </span>
          <span>
            Domestic Law: the law of the United Arab Emirates or a part of the
            United Arab Emirates.
          </span>
          <span>
            Both parties will comply with all applicable requirements of the
            Data Protection Legislation. This Clause 16 is in addition to, and
            does not relieve, remove or replace, a party's obligations or rights
            under the Data Protection Legislation.
          </span>
          <span>
            The parties acknowledge that for the purposes of the Data Protection
            Legislation, Pet Set GO is the Controller and You are the Processor.
          </span>
          <span>
            Without prejudice to the generality of Clause 16.2, Pet Set GO will
            ensure that it has all necessary appropriate consents and notices in
            place to enable lawful transfer of the Personal Data and lawful
            collection of the Personal Data provided by You for the duration and
            purposes of the Contract.
          </span>
          <span>
            Without prejudice to the generality of Clause 16.2, You shall, in
            relation to any Personal Data processed in connection with the
            performance by You of your obligations under the Contract:
          </span>
          <span>
            process that Personal Data only on the documented written
            instructions of Pet Set GO unless the You are required by Domestic
            Law to otherwise process that Personal Data. Where You are relying
            on Domestic Law as the basis for processing Personal Data, You shall
            promptly notify Pet Set GO of this before performing the processing
            required by the Domestic Law unless the Domestic Law prohibits You
            from so notifying Pet Set GO;
          </span>
          <span>
            ensure that You have in place appropriate technical and
            organisational measures, reviewed and approved by Pet Set GO, to
            protect against unauthorised or unlawful processing of Personal
          </span>
          <span>
            Data and against accidental loss or destruction of, or damage to,
            Personal Data, appropriate to the harm that might result from the
            unauthorised or unlawful processing or accidental loss, destruction
            or damage and the nature of the data to be protected, having regard
            to the state of technological development and the cost of
            implementing any measures (those measures may include, where
            appropriate, pseudonymising and encrypting Personal Data, ensuring
            confidentiality, integrity, availability and resilience of its
            systems and services, ensuring that availability of and access to
            Personal Data can be restored in a timely manner after an incident,
            and regularly assessing and evaluating the effectiveness of the
            technical and organisational measures adopted by it);
          </span>
          <span>
            ensure that all personnel who have access to and/ or process
            Personal Data are obliged to keep the Personal Data confidential;
            and not transfer any Personal Data outside of the United Arab
            Emirates unless the prior written consent of Pet Set GO has been
            obtained and the following conditions are fulfilled:
          </span>
          <span>
            Pet Set GO has provided appropriate safeguards in relation to the
            transfer;
          </span>
          <span>
            the Data Subject has enforceable rights and effective legal
            remedies;
          </span>
          <span>
            You have complied with Your obligations under the Data Protection
            Legislation by providing an adequate level of protection to any
            Personal Data that is transferred; and
          </span>
          <span>
            You complied with reasonable instructions notified to You in advance
            by the Pet Set GO with respect to the processing of the Personal
            Data;
          </span>
          <span>
            notify Pet Set GO without undue delay on becoming aware of a
            Personal Data Breach;
          </span>
          <span>
            at the written direction of Pet Set GO, delete or return Personal
            Data and copies thereof to Pet Set GO on termination of the Contract
            unless required by Domestic Law to store the Personal Data; and
            maintain complete and accurate records and information to
            demonstrate You are compliant with this Clause 16.
          </span>
          <span>
            Pet Set GO does not consent to You appointing any third-party
            processor of Personal Data under the Contract
          </span>
          <span>
            Pet Set GO may, at any time on not less than 30 days' notice, revise
            this Clause 16 by replacing it with any applicable controller to
            processor standard clauses or similar terms adopted under the Data
            Protection Legislation or forming part of an applicable
            certification scheme (which shall apply when replaced by attachment
            to the Contract).
          </span>
          <h4>CONFIDENALITY</h4>
          <span>
            Each party undertakes that it shall not at any time, and for a
            period of 2 years after termination or expiry of the Contract,
            disclose to any person any confidential information concerning the
            business, assets, affairs, customers, clients or suppliers of the
            other party or of any member of the group of companies to which the
            other party belongs, except as permitted by Clause 17.2.
          </span>
          <span>
            Each party may disclose the other party's confidential information:
          </span>
          <span>
            to its employees, officers, representatives, contractors or
            subcontracts or advisers who need to know such information for the
            purposes of exercising the party's rights or carrying out its
            obligations under or in connection with the Contract. Each party
            shall ensure that its employees, officers, representatives or
            advisers to whom it discloses the other party's confidential
            information comply with this Clause 14; and as may be required by
            law, a court of competent jurisdiction or any governmental or
            regulatory authority.
          </span>
          <span>
            No party shall use any other party's confidential information for
            any purpose other than to exercise its rights and perform its
            obligations under or in connection with the Contract.
          </span>
          <h4>INDEMNITY</h4>
          <span>
            You agree to indemnify and hold Pet Set GO and their officers,
            directors, employees and agents harmless from any and all claims,
            demands, losses, liabilities, and expenses including but not limited
            to any direct, indirect or consequential losses, loss of profit,
            loss of reputation and all interest, penalties and professional and
            legal costs and expenses arising out of or in connection with: (i)
            your use of the Pet Set GO Platform and the Services or services or
            goods obtained through your use of the Pet Set GO Platform; (ii)
            your breach or violation of this Agreement or(iii) your violation of
            the rights of any third party Service Providers.
          </span>
          <h4>WARRANTY DISCLAIMER FOR THE PET SET GO PLATFORM</h4>
          <span>
            The information and materials found on the Pet Set GO Platform that
            third-party Service Providers have provided, including but not
            limited to text, graphics, information, links, and other items is,
            is made available on the Pet Set GO platform, as well as reviews,
            profiles, recommendations, statements, offers, or other information
            or content are theirs and third-party Service Providers are solely
            responsible for such content, including any Goods and Services
            provided by third-party Service Providers to You and are made
            available through the Pet Set GO Platform.
          </span>
          <span>
            To the fullest extent permitted by the Applicable law, Pet Set GO
            does not: (1) guarantee the accuracy of data and/or materials on the
            Pet Set GO Platform; (2) accept responsibility for any views
            expressed by third parties; and/or (3) warrant that Your use of the
            Pet Set GO platform will be secure, free from computer viruses,
            uninterrupted, always available, or error-free.
          </span>
          <span>
            To the extent permitted by the Applicable law, Pet Set GO expressly
            disclaims all warranties and conditions, whether express, implied or
            statutory, with respect to the Pet Set GO platform, including any
            implied warranties relating to satisfactory quality or fitness for
            purpose. Pet Set GO specifically disclaims all warranties in
            relation to the suitability of any of Your Goods and Services and/or
            Your provision of any Goods and Service(s).
          </span>
          <h4>LIMITATION OF LIABILITY</h4>
          <span>
            In no event shall Pet Set GO be liable to You for any indirect,
            incidental, or consequential losses, business losses, or loss of
            revenue, contracts, data, or other comparable losses arising out of
            Your access to or use of the Pet Set GO App, unless such liability
            is expressly prohibited by applicable law.
          </span>
          <span>
            References to liability in this Clause 1f include every kind of
            liability arising under or in connection with the Contract including
            liability in contract, tort (including negligence),
            misrepresentation, restitution or otherwise.
          </span>
          <span>
            Neither party to this contract may benefit from the limitations and
            exclusions set out in this clause in respect of any liability
            arising from its deliberate default.
          </span>
          <span>
            At all times, Pet Set GO serves as an intermediary service provider
            and merely facilitates the interaction between Service Providers and
            Pet Owners via the Pet Set GO Platform.
          </span>
          <span>
            You acknowledge that when using the Pet Set GO App, should You have
            any dispute with a third-party Service Provider, You agree that Pet
            Set GO shall not be responsible nor liable for resolving such
            dispute and that You shall attempt to resolve such dispute with the
            applicable third-party Service Provider.
          </span>
          <span>
            Pet Set GO will never be held responsible for any direct or indirect
            consequences of a Service Provider failing to comply with the
            Applicable laws, regulations, local by-laws and codes of practice
            applicable to You.
          </span>
          <span>
            In addition, Pet Set GO makes no representation, warranty, or
            guarantee regarding the reliability, timeliness, quality,
            suitability or availability of Pet Set GO platform, Services or
            Goods requested through the use of the Pet Set GO platform, or that
            the Pet Set GO platform will be uninterrupted or error-free.
          </span>
          <span>
            Pet Set GO is not responsible for the welfare of Your Animal or
            conduct, whether online or offline, of any Your or any other
            third-party Service Provider. You are encouraged to use a reasonable
            degree of sensibility and caution when interacting with third- party
            Service Providers.
          </span>
          <span>
            By using the Pet Set GO Platform and participating in the Services,
            You agree to accept such risks and agree that Pet Set GO is not
            responsible for the acts or omissions of any third-party Service
            Providers towards You or Your animal.
          </span>
          <span>
            Pet Set GO expressly disclaim any liability arising from the
            unauthorised use of Your User account. Should You suspect that any
            unauthorised party may be using Your User account or You suspect any
            other breach of security, You agree to notify us immediately.
          </span>
          <span>
            Pet Set GO does not accept responsibility or liability for Your pets
            that escape, are injured or become lost, fatal or otherwise, when
            pets are left out or given access to a fenced in area. This includes
            electronic, wood, metal, or any other fence type.
          </span>
          <span>
            All dogs must be always on a leash when Your Pet is in care of
            Service Providers.
          </span>
          <span>
            Pet Set GO will not be held liable for any damages to property or
            pets if You allow any other person, whether it’s a neighbour,
            friend, family member or other person to enter the home during the
            time the Service Provider is sitting for Your pet(s). If You do
            allow access to someone other than the Pet Sitter during the
            duration of the Service Providers job, You must notify the office
            and the Pet Sitter.
          </span>
          <span>
            Pet Set GO will not be liable for any loss or damage in the event
            Client’s home is burglarised. Client specifically agrees that he or
            she will secure the home prior to leaving and will provide Service
            Providers with written instructions on how to properly secure the
            home.
          </span>
          <span>
            It is Your sole responsibility to make sure that Your home and yard
            are “pet-proof”. Service Providers will not be responsible for any
            furniture damage or other damage caused to Your home by pet unless
            due to negligence of Servicer Providers. Pet Set GO and Service
            Providers are not liable for Pets that are left outside or may
            escape from Your property when Pet Sitter is not in attendance. Pet
            Set GO shall not be liable for the injury, disappearance, death, or
            fines of any pet that escapes the property.
          </span>
          <span>
            In the event of inclement weather or natural disaster, Service
            Providers will use their best judgment in caring for Your pet(s) and
            home but will not be held responsible for any damage to Your home or
            injury to Your pet arising from such decision.
          </span>
          <span>
            Should Your pet be unclaimed at the end of the agreed boarding
            period, the third-party Service Provider will make every endeavour
            to contact You, should no response be given after 7 days of the end
            of the agreed boarding period, the third-party Service Provider
            reserves the right to actively seek to rehome Your pet.
          </span>
          <span>
            Whilst we will take every precaution to fully care for and protect
            Your Pet(s) we cannot be held responsible and will accept no
            liability for any illness, injury or the unlikely event of death
            during their stay. The Client will be responsible for any costs
            incurred arising out of these scenarios.
          </span>
          <span>
            Pet Set GO will not be held liable for any advice given by
            third-party Service Providers whether online or in person. You must
            expertise reasonable caution and sensibility when following the
            advice by third- party Service Providers. It is at Your own
            discretion whether or not You follow the advice given by third-
            party Service Providers. You acknowledge that Pet Set GO will not be
            held liable for any negligent advice given by any third-party
            Service Provider.
          </span>
          <h4>CONSENTS</h4>
          <span>
            Written signed consent for procedures is required in all cases of
            admission veterinary practices/or hospital and we will endeavour to
            discuss alterations prior to action being taken or other services
            being provided. If treatment or surgery is recommended every effort
            will be made to contact You at the contact details provided on the
            registration form to obtain your consent, but in case of contact
            cannot be made, You agree to accept the decision of Vet selected by
            the Service Providers.
          </span>
          <span>
            Consent is also required for the use of medications in animals. In
            this case a lifetime consent form will be provided to allow this to
            be done without asking for specific permission in each and every
            separate case. This is required as the majority of veterinary drugs
            are not licensed for use in small mammals, reptiles, birds, fish,
            amphibians and invertebrates. In some instances we may also suggest
            human alternative medicines which are not licensed for use in
            Animals but have proven activity and in such circumstances we will
            obtain Your consent in advance. In all circumstances, where licences
            are not available for the use of medicines with specific Animals, we
            must use drugs which have a proven activity and efficiency despite a
            lack of licence.
          </span>
          <h4>YOUR PET</h4>
          <span>
            It is of the upmost importance that You provide full and accurate
            information in relation to the needs and requirements of Your pet.
            The Service Provider will rely upon the information You give at Your
            initial booking in connection with subsequent bookings unless You
            advise the Service Provider that Your pet’s condition or
            requirements have changed.
          </span>
          <span>
            We cannot accept bookings for badly behaved or aggressive animals.
            The Service Provider may terminate an engagement immediately if it
            is reasonably thought that Your pet is badly behaved or aggressive
            either towards humans, animals or property. You will be liable if
            your pet injures a third- party Service Provider or their employees.
          </span>
          <span>
            Third-party Service Providers may request a Proof of current
            vaccinations, in particular of the case of rabbits for Nobivac and
            either Filacav or Eravac.
          </span>
          <span>
            You must ensure that Your Pet is in good health and free from
            parasites and is adequately wormed. If Your third-party Service
            Provider believes your Pet is not in good health, we reserve the
            right to seek veterinary advice on your behalf and You will be
            liable for the veterinary fees.
          </span>
          <span>
            If it becomes necessary, your animal will be taken to a veterinary
            surgeon. You are responsible for any fees that are reasonably
            incurred during your absence or during the course of an assignment,
            including transportation.
          </span>
          <h4>CANCELLATIONS</h4>
          <span>
            If Your booking is cancelled after we have confirmed Your booking,
            and you have not yet paid the booking fee, You may be liable for
            administration fee between 0-6 dirhams (AED).
          </span>
          <span>
            In the event of cancellation prior to the acceptance of the order or
            booking, the fee paid can be used towards a subsequent booking.
            Cancellations made after the acceptance of the order of booking will
            result in your fee being forfeited. After the commencement of the
            services, if changes are made to the booking, then You shall pay the
            agreed Service Provider’s fees in total plus any expenses.
          </span>
          <span>
            The cancellation policy for the Service Provider will be as
            follows:-
          </span>
          <span>
            If cancellation is given by you prior to the order or booking being
            accepted there will be no charge.
          </span>
          <span>
            If cancellation is given after the order or booking has been
            accepted a fee will be charged at a administration fee between 0-10
            dirhams (AED).
          </span>
          <span>
            If cancellation is given 4f hours or less prior to the order being
            delivered or booking scheduled, the total fee will be charged.
          </span>
          <span>
            In the unlikely event that the Service Provider cannot honour Your
            booking, the Service Provider will substitute an alternative pet
            service, or Your monies will be refunded.
          </span>
          <h4>TERMINATION</h4>
          <span>
            Without affecting any other right or remedy available to it, Pet Set
            GO may terminate the Contract with immediate effect by giving
            written notice to You if:
          </span>
          <span>
            You commit a material breach of any term of the Contract and (if
            such breach is remediable) fails to remedy that breach within a
            period of 5 business days after being notified in writing to do so;
          </span>
          <span>
            You take any step or action in connection with its entering
            administration, provisional liquidation or any composition or
            arrangement with its creditors (other than in relation to a solvent
            restructuring), obtaining a moratorium, being wound up (whether
            voluntarily or by order of the court, unless for the purpose of a
            solvent restructuring), having a receiver appointed to any of its
            assets or ceasing to carry on business;
          </span>
          <span>
            Without limiting its other rights or remedies, Pet Set GO may
            terminate the Contract with immediate effect by giving written
            notice to the You if the You fail to pay any amount due under the
            Contract on the due date for payment.
          </span>
          <span>
            Pet Set GO, in its sole discretion, reasonably believes that You
            have committed a violation of Applicable Law in relation to supply
            of Goods or Services or breached your obligations under this
            Contract.
          </span>
          <span>
            On termination of the Contract for any reason You shall immediately
            pay to Pet Set Go all of the outstanding unpaid invoices in respect
            of Goods and Services supplied but for which no invoice has been
            submitted,Pet Set GO shall submit an invoice, which shall be payable
            by You immediately on receipt.
          </span>
          <span>
            Termination or expiry of the Contract, however arising, shall not
            affect any of the parties' rights and remedies that have accrued as
            at termination or expiry. Pet Set GO has the right to claim damages
            in respect of any breach of the Contract which existed at or before
            the date of termination or expiry.
          </span>
          <span>
            Any provision of the Contract that expressly or by implication is
            intended to come into or continue in force on or after termination
            or expiry of the Contract shall remain in full force and effect.
          </span>
          <h4>FORCE MAJEURE</h4>
          <span>
            Force Majeure Event means any circumstance not within a party's
            reasonable control including, without limitation:
          </span>
          <span>
            acts of God, flood, drought, earthquake or other natural disaster
            epidemic or pandemic
          </span>
          <span>
            terrorist attack, civil war, civil commotion or riots, war, threat
            of or preparation for war, armed conflict, imposition of sanctions,
            embargo, or breaking off diplomatic relations nuclear, chemical or
            biological contamination or sonic boom
          </span>
          <span>
            any law or any action taken by a government or public authority,
            including without limitation imposing an export or import
            restriction, quota or prohibition
          </span>
          <span>collapse of buildings, fire, explosion or accident</span>
          <span>
            any labour or trade dispute, strikes, industrial action or lockouts
          </span>
          <span>interruption or failure of utility service.</span>
          <span>
            Provided it has complied with clause 23.3, if either party is
            prevented, hindered or delayed in or from performing any of its
            obligations under this agreement by a Force Majeure Event (Affected
            Party), the Affected Party shall not be in breach of this agreement
            or otherwise liable for any such failure or delay in the performance
            of such obligations. The time for performance of such obligations
            shall be extended accordingly.
          </span>
          <span>The Affected Party shall:</span>
          <span>
            as soon as reasonably practicable after the start of the Force
            Majeure Event shall immediately notify the other party in writing of
            the Force Majeure Event, the date on which it started, its likely or
            potential duration, and the effect of the Force Majeure Event on its
            ability to perform any of its obligations under the agreement
          </span>
          <span>
            Use all reasonable endeavours to mitigate the effect of the Force
            Majeure Event on the performance of its obligations.
          </span>
          <span>
            If the Force Majeure Event prevents, hinders or delays the Affected
            Party's performance of its obligations for a continuous period of
            more than 14 days, the party not affected by the Force Majeure Event
            may terminate this agreement by giving 30 days written notice to the
            Affected Party.
          </span>
          <h4>GENERAL</h4>
          <h5>ENTIRE AGREEMENT:</h5>
          <span>
            This Contract (and any document referred to in it) constitutes the
            entire agreement of the Parties relating to the subject matter of
            this Contract.
          </span>
          <span>
            Each party acknowledges that in entering into the Contract it does
            not rely on any statement, representation, assurance or warranty
            (whether made innocently or negligently) that is not set out in the
            Contract. Each party agrees that it shall have no claim for
            innocentor negligent misrepresentation based on any statement in the
            Contract.
          </span>
          <h5>AMENDMENT: </h5>
          <span>
            Pet Set GO may amend, add too, or delete any term or condition of
            these Conditions at its discretion at any time.
          </span>
          <h5>RIGHT TO ASSIGN:</h5>
          <span>
            You may not assign, transfer, mortgage, charge, subcontract,
            delegate, declare a trust over or deal in any other manner with any
            or all of its rights or obligations under the Contract without the
            prior written consent of Pet Set GO.
          </span>
          <span>
            Pet Set GO may at any time assign, transfer, subcontract, delegate,
            declare a trust over or deal in any other manner with all or any of
            its rights or obligations under the Contract.
          </span>
          <h5>WAIVERS:</h5>
          <span>
            A waiver of any right under this Contract must be conceited in
            writing to Pet Set GO.
          </span>
          <span>
            A delay or failure to exercise, or the single or partial exercise
            of, any right or remedy shall not waive that or any other right or
            remedy, nor shall it prevent or restrict the further exercise of
            that or any other right or remedy.
          </span>
          <h5>SEVERANCE AND ILLEGALITY: </h5>
          <span>
            If any provision of this Contract is determined to be invalid,
            illegal or void by any court or administrative body of competent
            jurisdiction than the rest of this Contract shall still remain in
            full force and effect. Where foregoing applies, the Parties shall
            cooperate to promptly amend or replace the affected provision with a
            new provision that achieves a legal result that is as similar as
            possible.
          </span>
          <h5>RELATIONSHIP BETWEEN THE PARTIES: </h5>
          <span>
            You and Pet Set GO are and shall remain independent parties. Nothing
            in this Contract shall be construed to make either Party an agent,
            employee, franchisee, joint venture or legal representative of the
            other Party. Neither Party shall have, or shall represent itself to
            have, any authority to bind the other Party or act on its behalf.
          </span>
          <h5>RIGHTS OF THIRD PARTIES: </h5>
          <span>
            This Contract does not create any rights which are enforceable by
            any person who is not a Party to this Contract except those of the
            third-party Service Providers.
          </span>
          <h5>NOTICES: </h5>
          <span>
            Any notice or other communication given under or in connection with
            this Contract must be in English and in writing. Any notice or
            communication which is not delivered on a Business Day, or which is
            delivered after 5pm (local time of the recipient) on a Business Day,
            shall be deemed to have been delivered on the next Business Day.
          </span>
          <h5>GOVERNING LANGUAGE: </h5>
          <span>
            This Contract is drawn up in the English language and the English
            language version of this Contract shall always prevail over any
            translation.
          </span>
          <h5>GOVERNING LAW: </h5>
          <span>
            This Contract and any disputes, claims or controversies arising out
            of, relating to or in connection with the present Contract,
            including any question regarding its formation, existence, validity,
            enforceability, performance, interpretation, breach or termination
            shall be governed by and construed in accordance with the laws of
            the Emirate of Dubai and the applicable federal laws of the United
            Arab Emirates.
          </span>
          <h5>JURISDICTION:</h5>
          <span>
            Each Party hereto irrevocably agrees that the onshore Dubai Courts
            shall have exclusive jurisdiction to finally settle any dispute or
            claim arising out of the formation, performance, interpretation,
            nullification, termination or invalidation of this Contract or
            arising therefrom or relating thereto in any manner whatsoever.
          </span>
          <span>
            A delay or failure to exercise, or the single or partial exercise
            of, any right or remedy shall not waive that or any other right or
            remedy, nor shall it prevent or restrict the further exercise of
            that or any other right or remedy.
          </span>
          <h5>INTELLECTUAL PROPERTY:</h5>
          <span>
            All Intellectual Property Rights in or arising out of or in
            connection with the Pet Set GO platform shall be owned by Pet Set
            GO.
          </span>
          <span>
            All content provided by Pet Set GO, including but not limited to
            text, reviews, comments, descriptions, software, apps, websites,
            documents, products, logos, graphics, and images, as well as the
            company's services, are protected by copyright and trademark
            protection laws.
          </span>
          <span>
            Without the express written consent of Pet Set GO or one of its
            affiliated third-party licensed partners, none of the materials
            listed here may be altered or used.
          </span>
          <span>
            You consent that Your feedback or enhancement suggestions regarding
            any Pet Set GO service can be used without requiring payment from
            Pet Set GO. Pet Set GO owns all rights to its trademarks names,
            logos, brands, images, software and other services that are
            connected to us.
          </span>
          <span>
            The Pet Set GO Platform and all rights therein are and shall remain
            Pet Set GO’s property or the property of Pet Set GO’s licensors.
            Neither this Agreement nor your use of the Pet Set GO Platform
            convey or grant to you any rights: (i) in or related to the Pet Set
            GO Platform except for the limited license granted above; or (ii) to
            use or reference in any manner Pet Set GO’s company names, logos,
            product and service names, trademark’s or services marks or those of
            Pet Set GO’s licensors.
          </span>
          <h5>TITLE OF RISK: </h5>
          <span>
            The risk in the Goods shall pass to You on completion of delivery.
          </span>
          <h5>SEVERANCE:</h5>
          <span>
            If any provision or part-provision of the Contract is or becomes
            invalid, illegal or unenforceable, it shall be deemed deleted, but
            that shall not affect the validity and enforceability of the rest of
            the Contract. If any provision or part provision of the Contract is
            deemed deleted under this Clause 22.16 the parties shall negotiate
            in good faith to agree a replacement provision that, to the greatest
            extent possible, achieves the commercial result of the original
            provision.
          </span>
        </div>
      </div>
      <div className="mt-5">
        <FiftyOff />
      </div>
    </>
  );
};
export default Terms;
