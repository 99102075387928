import React from 'react';
import Swal from 'sweetalert2';
const Errorpage = () => {
    // useEffect(()=>{
        Swal.fire("Error in payment process")
    // },[])
  return (
    <>
    <div style={{marginTop:"10%"}}>
        error page
    </div>
    </>
  );
};

export default Errorpage;
