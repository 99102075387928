import { useLocation, useParams, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import NoImg from "../../images/no_image.avif";
import { commonHeaders, petSetUrl } from "../utils";
import { FaPlusSquare } from "react-icons/fa";
import { Alert } from "react-bootstrap";
import CartContext from "../../Cart/Cart";
import LoginModal from "../../pages/LoginModal";
import sale from "../../images/sale.jpeg";
import Swal from "sweetalert2";
import axios from "axios";

const DogFood = (props) => {
  const { user, addProduct, state, cartDelete, setStore } =
    useContext(CartContext);
  const child_id = props.cid;
  const SortOption = props.sortOption;
  const subCategoryId = props.selectedSubcategoryId;
  const location = useLocation();
  // console.log(child_id);
  // const Store = props.store_id;
  // const location = useLocation();
  const storeDetail = location?.state?.data;
  const id = location?.state?.store_id;
  // const { id } = useParams();
  const nav = useNavigate();
  const [foodData, setfoodData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alertVisible, setalertVisible] = useState(false);
  const [alertMessage, setalertMessage] = useState("");
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [Discount, setDiscount] = useState();

  const getStore = () => {
    const storedLatitude = localStorage.getItem("lat");
    const storedLongitude = localStorage.getItem("lng");
    const Api = `${petSetUrl}/v1/store/get?lng=${storedLongitude}&lat=${storedLatitude}`;
    const requestOptions = {
      method: "GET",
      headers: commonHeaders,
    };
    fetch(Api, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        const matchingRecord = responseData.shops.find(
          (record) => record.sid === id
        );
        setDiscount(matchingRecord.discounts[0]);
      })
      .catch((error) => {
        console.error("Error fetching store data from the API:", error);
      });
  };
  useEffect(() => {
    getStore();
  }, []);

  const handleGoBack = () => {};

  // const handleProceedTo = (item) => {
  //   const data = {
  //     shop_id: id,
  //     customer_uid: user.uid,
  //     price: item.price,
  //     productID: item.pid,
  //     quantity: 1,
  //     discount_price: 0.0,
  //     media: item.img_id,
  //     title: item.title,
  //     Weight: item.weight,
  //   };
  //   cartDelete();
  //   setStore(storeDetail);
  //   addProduct(data);
  //   showAlert("Product added to the cart");
  // };

  // const handleAddProduct = (productDetail) => {
  //   const hasData =
  //     (state?.slots && state?.slots?.length > 0) ||
  //     (state?.products && state?.products?.length > 0);

  //   if (hasData && state?.shop_id !== productDetail.store_id) {
  //     Swal.fire({
  //       title:
  //         "Your items from another store are in the cart, upon proceeding your items from another store will be cleared",
  //       text: "Choose an action:",
  //       icon: "info",
  //       showCancelButton: true,
  //       confirmButtonText: "Proceed",
  //       cancelButtonText: "Go Back",
  //     }).then((result) => {
  //       if (result.isConfirmed) {
  //         handleProceedTo(productDetail);
  //       } else if (result.dismiss === Swal.DismissReason.cancel) {
  //         handleGoBack();
  //       }
  //     });
  //   } else {
  //     try {
  //       const item = {
  //         shop_id: id,
  //         customer_uid: user.uid,
  //         price: productDetail.price,
  //         productID: productDetail.pid,
  //         quantity: 1,
  //         discount_price: 0.0,
  //         media: productDetail.img_id,
  //         title: productDetail.title,
  //         Weight: productDetail.weight,
  //       };
  //       const existItem = state.products.find(
  //         (prod) => prod.productID === item.productID
  //       );
  //       if (existItem) {
  //         showAlert("Item already in the cart");
  //         // nav("/Cart");
  //       } else {
  //         setStore(storeDetail);
  //         addProduct(item);
  //         // nav("/Cart");
  //         showAlert("Product added to the cart");
  //       }
  //     } catch (error) {
  //       console.error("Error adding product to cart:", error);
  //       showAlert("Error adding product to the cart");
  //     }
  //   }
  // };

  const handleProceedTo = (item) => {
    // const data = {
    //   shop_id: id,
    //   customer_uid: user.uid,
    //   price: item.price,
    //   productID: item.pid,
    //   quantity: 1,
    //   discount_price: 0.0,
    //   media: item.img_id,
    //   title: item.title,
    //   Weight: item.weight,
    // };
    // cartDelete();
    // setStore(storeDetail);
    // // console.log(item);
    // addProduct(data);
    // showAlert("Product added to the cart");

    const data = {
      shop_id: id,
      uid: localStorage.getItem("uid"),
      products: [
        {
          productID: item.pid,
          quantity: 1,
          price: item.price,
          discount_price: 0.0,
        },
      ],
      slots: [],
      dayboarding: [],
    };
    axios
      .post(`${petSetUrl}/v1/cart/add`, data)
      .then((response) => {
        // console.log(response);
        // Swal.fire("cart added");
        showAlert("Product added to the cart");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleAddProduct = (productDetail) => {
    // const hasData =
    //   (state?.slots && state?.slots?.length > 0) ||
    //   (state?.products && state?.products?.length > 0);

    // if (hasData && state?.shop_id !== productDetail.store_id) {
    //   Swal.fire({
    //     title:
    //       "Your items from another store are in the cart, upon proceeding your items from another store will be cleared",
    //     text: "Choose an action:",
    //     icon: "info",
    //     showCancelButton: true,
    //     confirmButtonText: "Proceed",
    //     cancelButtonText: "Go Back",
    //   }).then((result) => {
    //     if (result.isConfirmed) {
    //       handleProceedTo(productDetail);
    //     } else if (result.dismiss === Swal.DismissReason.cancel) {
    //       handleGoBack();
    //     }
    //   });
    // } else {
    //   try {
    //     const item = {
    //       shop_id: id,
    //       customer_uid: user.uid,
    //       price: productDetail.price,
    //       productID: productDetail.pid,
    //       quantity: 1,
    //       discount_price: 0.0,
    //       media: productDetail.img_id,
    //       title: productDetail.title,
    //       Weight: productDetail.weight,
    //     };
    //     const existItem = state.products.find(
    //       (prod) => prod.productID === item.productID
    //     );
    //     if (existItem) {
    //       showAlert("Item already in the cart");
    //     } else {
    //       setStore(storeDetail);
    //       addProduct(item);
    //       showAlert("Product added to the cart");
    //     }
    //   } catch (error) {
    //     console.error("Error adding product to cart:", error);
    //     showAlert("Error adding product to the cart");
    //   }
    // }

    axios
      .get(`${petSetUrl}/v1/cart/get?uid=${localStorage.getItem("uid")}`, {
        headers: commonHeaders,
      })
      .then((response) => {
        // console.log(response.data.data);
        if (response?.data?.data?.shop_id !== productDetail.store_id) {
          Swal.fire({
            title:
              "Your items from another store are in the cart, upon proceeding your items from another store will be cleared",
            text: "Choose an action:",
            icon: "info",
            showCancelButton: true,
            confirmButtonText: "Proceed",
            cancelButtonText: "Go Back",
          }).then((result) => {
            if (result.isConfirmed) {
              handleProceedTo(productDetail);
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              handleGoBack();
            }
          });
        }

        if (response?.data?.data?.shop_id === productDetail.store_id) {
          const data = {
            cart_id: response?.data?.data?.cart_id,

            productID: productDetail.pid,
            quantity: 1,
            price: productDetail?.price,
            discount_price: 0.0,
          };
          axios
            .post(`${petSetUrl}/v1/cart/product/add`, data)
            .then((response) => {
              // console.log(response);
              showAlert("Product added to the cart");
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {
        console.log(error);
        const data = {
          shop_id: productDetail.store_id,
          uid: localStorage.getItem("uid"),
          products: [
            {
              productID: productDetail.pid,
              quantity: 1,
              price: productDetail?.price,
              discount_price: 0.0,
            },
          ],
          slots: [],
          dayboarding: [],
        };
        axios
          .post(`${petSetUrl}/v1/cart/add`, data)
          .then((response) => {
            // console.log(response);
            showAlert("Product added to the cart");
          })
          .catch((error) => {
            console.log(error);
          });
      });
  };
  useEffect(() => {
    setLoading(true);

    const fetchProducts = async () => {
      try {
        let ChildCategory;
        if (subCategoryId === "All Items") {
          ChildCategory = `${petSetUrl}/v1/store/product/get/category?store_id=${id}&cid=${child_id}&type=child&offset=0&limit=100`;
        } else if (subCategoryId !== null) {
          ChildCategory = `${petSetUrl}/v1/store/product/get/category?store_id=${id}&cid=${child_id}&type=child&offset=0&subCategory=${subCategoryId}&limit=100`;
        } else {
          ChildCategory = `${petSetUrl}/v1/store/product/get/category?store_id=${id}&cid=${child_id}&type=child&offset=0&limit=100`;
        }

        const requestOptions = {
          method: "GET",
          headers: commonHeaders,
        };

        const response = await fetch(ChildCategory, requestOptions);
        const responseData = await response.json();

        let filteredData = [];
        // let filteredData = responseData.products;

        if (subCategoryId !== null) {
          filteredData = responseData.products;
        } else {
          filteredData = responseData.products;
        }

        // if (subCategoryId !== null) {
        //   filteredData = filteredData.filter(
        //     (product) => product.subCategory === subCategoryId
        //   );
        // }

        let sortedData = filteredData;

        switch (SortOption) {
          case "low_to_high":
            sortedData.sort((a, b) => a.price - b.price);
            break;
          case "high_to_low":
            sortedData.sort((a, b) => b.price - a.price);
            break;
          default:
        }

        setfoodData(sortedData);
      } catch (error) {
        console.error("Error fetching store data from the API:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [child_id, id, SortOption, subCategoryId]);

  // useEffect(() => {
  //   setLoading(true);
  //   const ChildCategory = `${petSetUrl}/v1/store/product/get/category?store_id=${id}&cid=${child_id}&type=child&offset=0&limit=100`;
  //   const requestOptions = {
  //     method: "GET",
  //     headers: commonHeaders,
  //   };
  //   fetch(ChildCategory, requestOptions)
  //     .then((response) => {
  //       return response.json();
  //     })
  //     .then((responseData) => {
  //       let sortedData = responseData.products;
  //       switch (SortOption) {
  //         case "low_to_high":
  //           sortedData.sort((a, b) => a.price - b.price);
  //           break;
  //         case "high_to_low":
  //           sortedData.sort((a, b) => b.price - a.price);
  //           break;
  //         default:
  //       }
  //       setfoodData(sortedData);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching store data from the API:", error);
  //     })
  //     .finally(() => {
  //       setTimeout(() => {
  //         setLoading(false);
  //       }, 2000);
  //     });
  // }, [child_id, id, SortOption]);

  const showAlert = (message) => {
    setalertMessage(message);
    setalertVisible(true);

    setTimeout(() => {
      setalertVisible(false);
    }, 3000);
  };

  const closeLoginModal = () => {
    setIsLoginModalOpen(false);
  };

  return (
    <>
      {alertVisible && (
        <Alert
          variant="primary" // Change the variant based on your requirements (success, danger, warning, etc.)
          style={{
            position: "fixed",
            top: "15%",
            right: "0px",
            zIndex: 1000, // Ensure the alert appears above other elements
          }}
        >
          {alertMessage}
        </Alert>
      )}
      {loading ? (
        <div
          className="loader"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        ></div>
      ) : (
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
          {foodData && foodData.length > 0 ? (
            foodData.map((item, index) => (
              <div className="col" key={index}>
                <div className="card border-0">
                  {item.price > Discount?.discountCap ? (
                    <img
                      src={sale}
                      alt=""
                      style={{
                        width: "50px",
                        height: "50px",
                        position: "absolute",
                        // left: "20%",
                      }}
                    />
                  ) : null}
                  <div
                    style={{
                      width: "200px",
                      height: "300px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      src={item.img_id}
                      alt=""
                      className="foodimg"
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        objectFit: "cover",
                      }}
                      loading="lazy"
                      onError={(e) => {
                        e.target.src = NoImg;
                      }}
                    />
                  </div>

                  <LoginModal
                    isOpen={isLoginModalOpen}
                    onRequestClose={closeLoginModal}
                  />

                  <div className="card-body">
                    <h5 className="card-title small">AED {item.price}</h5>
                    <h6
                      className="card-title text-dark w-100"
                      style={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {item.title}
                    </h6>
                    <small
                      className="card-text small"
                      style={{ color: "#A3A3A3" }}
                    >
                      {item.weight}
                    </small>{" "}
                    <br />
                    <div className="d-flex justify-content-between">
                      {/* <Link
                        to={/Storeproduct/${id}/${item.product_id}}
                        className="text-decoration-none"
                      > */}
                      <button
                        className="productbtn border-0 rounded-5 mt-2 px-3"
                        onClick={() => {
                          nav(
                            `/Storeproduct/${item.title
                              .toLowerCase()
                              .replace(/ /g, "-")}`,
                            {
                              state: {
                                data: item,
                                store: storeDetail,
                                store_id: id,
                                product_id: item.product_id,
                              },
                            }
                          );
                        }}
                      >
                        View Product
                      </button>
                      {/* </Link> */}

                      <p
                        style={{
                          cursor: "pointer",
                          display: item.stock === 0 ? "none" : "block",
                        }}
                        // onClick={() => handleAddProduct(item)}
                        onClick={() => {
                          user
                            ? handleAddProduct(item)
                            : setIsLoginModalOpen(true);
                        }}
                      >
                        <FaPlusSquare
                          style={{
                            color: "#e57373",
                            width: "24px",
                            height: "24px",
                          }}
                        />
                      </p>
                    </div>
                    {item.stock === 0 ? (
                      <h6 className="mt-2" style={{ color: "#e57373" }}>
                        Out of Stock
                      </h6>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>Product not found</p>
          )}
        </div>
      )}
    </>
  );
};

export default DogFood;
