import { useState, useEffect, useContext } from "react";
import { commonHeaders, petSetUrl } from "../utils";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import CartContext from "../../Cart/Cart";
import LoginModal from "../../pages/LoginModal";
const DoctorService = ({ specialization }) => {
  const nav = useNavigate();
  const [EmergencyCall, setEmergencyCall] = useState();
  const { user } = useContext(CartContext);
  const [pet, setPet] = useState("");
  const [selectedPet, setSelectedPet] = useState(null);

  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  const closeLoginModal = () => {
    setIsLoginModalOpen(false);
  };

  const [pets, setPets] = useState([]);
  // console.log(selectedPet.species);
  const handlePetChange = (e) => {
    const selectedPetName = e.target.value;
    const selectedPetDetails = pets.find(
      (item) => item.pet_name === selectedPetName
    );
    setSelectedPet(selectedPetDetails);
    setPet(selectedPetName);
  };

  const getPets = async (id) => {
    try {
      const response = await axios.get(
        `${petSetUrl}/v1/pet/get?owner_id=${id}`,
        {
          headers: commonHeaders,
        }
      );
      if (response.data) {
        // console.log(response.data.data);
        setPets(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (user?.uid) {
      getPets(user.uid);
    }

    const requestOptions = {
      method: "GET",
      headers: commonHeaders,
    };
    const Api = `${petSetUrl}/v1/video/service?type=Appoint Video Call`;
    fetch(Api, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        // console.log(responseData.data);
        setEmergencyCall(responseData.data);
        localStorage.setItem("serviceId", responseData?.data?.service_id);
        localStorage.setItem("service", responseData?.data?.service_title);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const createCart = async () => {
    try {
      const params = {
        shop_id: EmergencyCall?.store?.sid,
        uid: localStorage.getItem("uid"),
        products: [],
        slots: [],
        dayboarding: [],
      };

      const response = await axios.post(`${petSetUrl}/v1/cart/add`, params, {
        headers: commonHeaders,
      });

      // console.log(response);
      return response.data.cart_id;
    } catch (error) {
      console.error(error);
      throw error; // Rethrow the error to handle it at a higher level if needed
    }
  };

  const handleCheckOut = async () => {
    try {
      const cartId = await createCart();
      const slotData = localStorage.getItem("DotorSlotData");
      const doctorSlots = JSON.parse(slotData);

      // const petdata = localStorage.getItem("newpet");
      // const selectedpet = JSON.parse(petdata);
      // console.log(selectedpet);

      const params = {
        // customer_uid: localStorage.getItem("uid"),
        // isEmergency: false,
        // resource_id:localStorage.getItem("resourceId"),
        // resource_name:localStorage.getItem("resourceName"),
        // service_id:localStorage.getItem("serviceId"),
        // service:localStorage.getItem("service"),
        // slotTiming: localStorage.getItem("Doctor select time"),
        // slotDate: "2023-12-29",
        // slotDay: doctorSlots.day,
        // price: EmergencyCall?.service_price,
        // seller_id: EmergencyCall?.store?.tenant_id,
        // store_id: EmergencyCall?.store?.sid,
        // selectedSlotDate: "2023-12-29",
        // pet: "Dog",
        // cart_id: cartId,
        service_id: localStorage.getItem("serviceId"),
        customer_uid: localStorage.getItem("uid"),
        isEmergency: false,
        resource_id: localStorage.getItem("resourceId"),
        slotTiming: localStorage.getItem("Doctor select time"),
        slotDate: "2023-12-29",
        slotDay: doctorSlots.day,
        price: EmergencyCall?.service_price,
        seller_id: EmergencyCall?.store?.tenant_id,
        store_id: EmergencyCall?.store?.sid,
        resource_name: localStorage.getItem("resourceName"),
        pet: selectedPet.species,
        cart_id: cartId,
        pet_id: selectedPet?.pid,
      };

      const response = await axios.post(
        `${petSetUrl}/v1/video/slot/add`,
        params,
        {
          headers: commonHeaders,
        }
      );

      // console.log(response);
      alert("Appointment added");
      nav("/NormalCallCheckout", {
        state: {
          OrderId: response?.data?.order?.cartCartId,
          total_price: response?.data?.order?.total_price,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="row border p-4">
        <h6>Services</h6>

        {/* <img className="col-4 col-sm-2 mt-2" src={cat} alt="" width="100px" />
        <div className="col-12 col-sm-8 mt-3 mt-sm-0 lh-1">
          <p className="fs-6">{specialization}</p>
          <p className="small" style={{ color: "#737373" }}>
            John Doe
          </p>
          <p className="small" style={{ color: "#737373" }}>
            +971 000 0000 000
          </p>
          <p className="small" style={{ color: "#737373" }}>
            1564, 4th Street, Downtown Dubai, UAE
          </p>
        </div> */}
        <p className="fs-6">{specialization}</p>
        <p>Normal Veterinary Call</p>
        <h6 className="col-12 col--sm-2">AED {EmergencyCall?.service_price}</h6>
        {/* <Link to="/NormalCallCheckout"> */}

        <div className="col-12">
          <h6 style={{ fontSize: "14px" }}>Select Pet</h6>
          {user ? (
            <select
              className="form-select w-50 rounded-3 border mt-4"
              style={{ boxShadow: "none", border: "none" }}
              value={pet}
              onChange={handlePetChange}
            >
              <option value="" disabled selected>
                Select Your Pet
              </option>
              {pets
                ? pets.map((item, i) => (
                    <option value={item.pet_name} key={i}>
                      {item.pet_name}
                    </option>
                  ))
                : null}
            </select>
          ) : (
            ""
          )}
        </div>

        <button
          className="w-25 mt-3 border-0 rounded-5 Availablebutton px-4"
          // onClick={handleCheckOut}
          onClick={() => {
            user ? handleCheckOut() : setIsLoginModalOpen(true);
          }}
        >
          Proceed to Payment
        </button>
        {/* </Link> */}
      </div>
      <LoginModal isOpen={isLoginModalOpen} onRequestClose={closeLoginModal} />
    </>
  );
};
export default DoctorService;
