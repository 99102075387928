import { format, parse } from "date-fns";
import { useContext, useState } from "react";
import CartContext from "../../Cart/Cart";
// import { useLocation } from "react-router-dom";
const AvailableSlots = (props) => {
  const { currentdate, setcurrentdate, formattedDate } =
    useContext(CartContext);
  const [selectedButton, setSelectedButton] = useState("");
  const formattedTimes = props.data.map(([timeString]) => {
    const parsedTime = parse(timeString, "hh:mm:ss a", new Date());
    return format(parsedTime, "hh:mm a");
  });
  // console.log(selectedButton);
  const handleButtonClick = (item) => {
    setSelectedButton(item);
    localStorage.setItem("select date", currentdate);
    localStorage.setItem("select time", item);
  };
  const slotsArray = formattedTimes.map((item, i) => {
    return (
      <button
        className={`col-5 mb-2 border-0 rounded-5 Availablebutton ${
          selectedButton === item ? "selected" : ""
        }`}
        onClick={() => handleButtonClick(item)}
        key={i}
      >
        {item}
      </button>
    );
  });
  const ServicetimeSlots = <div className="row g-2">{slotsArray}</div>;

  return (
    <>
      <div className="border p-4 row">
        <h6 className="col-12">Available Slots</h6>
        <div className="row mt-3">
          <input
            type="date"
            className="border rounded-3 col-12"
            value={currentdate}
            onChange={(e) => setcurrentdate(e.target.value)}
            min={formattedDate}
            style={{
              padding: "0.375rem 2.25rem 0.375rem 0.75rem",
              borderWidth: "2px",
              borderColor: "#007bff",
              outline: "none",
            }}
          />
        </div>
        {ServicetimeSlots}
      </div>
    </>
  );
};

export default AvailableSlots;
