import PopularStores from "../components/Home/PopularStores";
import FiftyOff from "../components/Home/FiftyOff";
import { Link, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { commonHeaders, petSetUrl } from "../components/utils";
const Store = () => {
  const location = useLocation();
  // const prevData = location.state.data;
  // console.log(prevData);
  // const queryParams = new URLSearchParams(location.search);
  // const selectedService = queryParams.get("service");
  const [Data, setData] = useState({});
  const [selectedOption, setSelectedOption] = useState("");
  // const [Groomcat, serGroomcat] = useState("");
  // const [Filter, setFilter] = useState([]);

  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;
  const [currentdate, setcurrentdate] = useState(formattedDate);
  useEffect(() => {
    const storedLatitude = localStorage.getItem("lat");
    const storedLongitude = localStorage.getItem("lng");
    // const storeApiUrl = `https://test-api.petsetgo.com//v1/store/get?lng=${storedLongitude}&lat=${storedLatitude}`;

    const storeApiUrl = `${petSetUrl}/v1/store/tag/store/get?des=Shop&lat=${storedLatitude}&lng=${storedLongitude}`;
    const requestOptions = {
      method: "GET",
      headers: commonHeaders,
    };
    fetch(storeApiUrl, requestOptions)
      .then((response) => response.json())
      .then((storeResponseData) => {
        let filteredData = storeResponseData.tags[0].stores;
        const stores = filteredData.filter(
          (store) =>
            store?.lat_lng?.coordinates &&
            store?.store_open &&
            store?.store_close
        );
        setData(stores);
        // console.log(stores);
      })
      .catch((error) => {
        console.error("Error fetching store data from the API:", error);
      });

    // fetch(categoryApiUrl, requestOptions)
    //   .then((response) => response.json())
    //   .then((categoryResponseData) => {
    //     const requiredData = categoryResponseData.data.find(
    //       (item) => item.des === "Shop"
    //     );
    //     if (requiredData && Array.isArray(requiredData.childCategories)) {
    //       setFilter(requiredData.childCategories);
    //     } else {
    //       console.error("Required data is missing or invalid.");
    //     }
    //   })
    //   .catch((error) => {
    //     console.error("Error fetching category data:", error);
    //   });
  }, [selectedOption]);

  // const renderFilterButtons = location.pathname !== "/Services" && (
  //   <div className="d-flex flex-column mt-4" style={{ padding: "0 5%" }}>
  //     <div className="d-flex justify-content-between scrollable-container">
  //       {Filter.map((item) => (
  //         <button
  //           className="border-0 rounded-5 ms-2 px-2 storebutton"
  //           style={{ minWidth: "maxContent", fontFamily: "Regular" }}
  //         >
  //           {item.des}
  //         </button>
  //       ))}
  //     </div>
  //   </div>
  // );

  const renderFilterSearch = location.pathname !== "/Store" && (
    // <div
    //   className="border mb-3 ps-lg-4"
    //   style={{
    //     height: "80px",
    //     display: "grid",
    //     alignItems: "center",
    //     textAlign: "center",
    //     width: "90%",
    //     margin: "5% 5% 0 5%",
    //   }}
    // >
    //   <div className="container row ps-5">
    //     <div className="col-lg-4 col-md-4 col-sm-12 ">
    //       <input
    //         type="date"
    //         className="w-100 border rounded-3"
    //         value={currentdate}
    //         onChange={(e) => setcurrentdate(e.target.value)}
    //         min={formattedDate}
    //         style={{
    //           padding: "0.375rem 2.25rem 0.375rem 0.75rem",
    //           borderWidth: "2px",
    //           borderColor: "#007bff",
    //           outline: "none",
    //         }}
    //       />
    //     </div>
    //     <div className="col-lg-4 col-md-4 col-sm-12 ">
    //       <select
    //         className="form-select w-100 rounded-3 border"
    //         style={{ boxShadow: "none", border: "none" }}
    //         onChange={(e) => setSelectedOption(e.target.value)}
    //       >
    //         <option value="" disabled selected>
    //           Chose Pickup
    //         </option>
    //         <option value="Pickup">Pickup Required</option>
    //         <option value="Dropoff">Dropoff Required</option>
    //         <option value="Both">Both Required</option>
    //         <option value="self">Pickup & Dropoff - self</option>
    //       </select>
    //     </div>
    //     <div className="col-lg-4 col-md-4 col-sm-12 ">
    //       <button
    //         className="btn text-dark w-100 rounded-5"
    //         style={{ backgroundColor: "#E0D9FA" }}
    //       >
    //         Search
    //       </button>
    //     </div>
    //   </div>
    // </div>
    <div className="border mb-3 ps-lg-4">
      <div className="container">
        <div className="row gx-3">
          <div className="col-lg-4 col-md-6 col-sm-12 mb-2">
            <input
              type="date"
              className="form-control rounded-3"
              value={currentdate}
              onChange={(e) => setcurrentdate(e.target.value)}
              min={formattedDate}
            />
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 mb-2">
            <select
              className="form-select rounded-3"
              onChange={(e) => setSelectedOption(e.target.value)}
            >
              <option value="" disabled defaultValue>
                Choose Pickup
              </option>
              <option value="Pickup">Pickup Required</option>
              <option value="Dropoff">Dropoff Required</option>
              <option value="Both">Both Required</option>
              <option value="self">Pickup & Dropoff - self</option>
            </select>
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12">
            <button
              className="btn text-dark w-100 rounded-5"
              style={{ backgroundColor: "#E0D9FA" }}
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  const GroomingSubCategory = location.search === "?service=Grooming" && (
    <div className="pb-2 pt-1" style={{ paddingLeft: "5%" }}>
      <button
        className="productbtn border-0 rounded-5 px-2 "
        // onClick={() => serGroomcat("In Store")}
      >
        In Store Grooming
      </button>
      <button
        className="productbtn border-0 rounded-5 px-2 ms-2"
        // onClick={() => serGroomcat("Mobile")}
      >
        Mobile Grooming
      </button>
    </div>
  );
  return (
    <>
      <div>
        <div className="lh-1" style={{ padding: "0 5%" }}>
          <Breadcrumb separator=">" style={{ marginTop: "8%" }}>
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item active>Stores</Breadcrumb.Item>
          </Breadcrumb>
          {/* <p style={{ marginTop: "4%" }}>
            Home {">"} Stores
            {selectedService === null ? "Stores" : selectedService}{" "}
            {Groomcat}
          </p> */}
          <h1 className="text-bold">
            {" "}
            Stores
            {/* {selectedService === null ? "Stores" : selectedService} {Groomcat} */}
          </h1>
        </div>
        {GroomingSubCategory}
        {/* {renderFilterButtons} */}
        {renderFilterSearch}
        <PopularStores storeData={Data} />
        <FiftyOff />
      </div>
    </>
  );
};

export default Store;
