import Doctor from "../images/Doctor.png";
import location1 from "../images/location1.png";
import star1 from "../images/star1.png";
// import AvailableSlots from "../components/Services/AvailableSlots";
import DoctortimeSlots from "../components/VeterinaryDoctore/DoctortimeSlots";
import DoctorService from "../components/Doctor/DoctorService";
import { useLocation } from "react-router";
import { useEffect, useState } from "react";
import { commonHeaders, petSetUrl } from "../components/utils";
const DoctorProfile = () => {
  const location = useLocation();
  const ResourceID = location.state?.data;

  const [Doctordetail, setDoctordetail] = useState([]);

  useEffect(() => {
    const Api = `${petSetUrl}/v1/video/resources/get?role=Emergency`;
    const requestOptions = {
      method: "GET",
      headers: commonHeaders,
    };
    fetch(Api, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        const DoctorDetail = responseData.data.find((item) => {
          return item.resource_id === ResourceID;
        });
        // console.log("Doctor detail", DoctorDetail);
        setDoctordetail(DoctorDetail);
        localStorage.setItem("resourceId", DoctorDetail?.resource_id);
        localStorage.setItem("resourceName", DoctorDetail?.name);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [ResourceID]);
  return (
    <>
      <div
        className="ps-md-5 mt-5 mb-5 d-lg-flex d-md-flex d-sm-block"
        style={{ marginTop: "1%" }}
      >
        <div className="mt-3">
          <img
            src={
              Doctordetail.image === "http"
                ? Doctordetail.image
                : Doctordetail.image === ""
                ? Doctor
                : "https://petsetgostorage.blob.core.windows.net/petsetgo-u2/" +
                  Doctordetail.image
            }
            alt=""
            width="100px"
            height="100px"
            className="bg-white ms-4"
          />
        </div>
        <div className="ms-5" style={{ marginTop: "1%" }}>
          <p>Home > Book an appointment > Veterinary Doctors</p>
          <h1>{Doctordetail.name}</h1>
          <div className="d-flex">
            <p>
              <img className="mb-1" src={location1} alt="" width="16px" />{" "}
              <span className="small">7.4KM away</span>
            </p>
            <p className="ms-2">
              {Array(5)
                .fill(null)
                .map((_, starIndex) => (
                  <img
                    className="mb-1"
                    key={starIndex}
                    src={star1}
                    alt=""
                    width="16px"
                  />
                ))}
              <span className="small">5.0</span>
            </p>
            <p className="ms-2">{Doctordetail.specialty}</p>
          </div>
        </div>
      </div>
      <div className="row" style={{ padding: "0 5%" }}>
        <div className="col-12 col-md-4">
          <DoctortimeSlots />
        </div>
        <div className="col-12 ms-0 mt-3 mt-md-0 ms-md-4 col-md-7">
          <DoctorService specialization={Doctordetail.specialty} />
        </div>
      </div>
    </>
  );
};
export default DoctorProfile;
