import "./single.scss";
// import Topbar from "../../Sections/Topbar/Topbar";
// import Footer from "../../Sections/Footer/Footer";
import bpic1 from "../../images/bpic1.png"
import bpic2 from "../../images/bpic2.png"
// import bpic1 from "../../Assets/bpic1.png";
// import bpic2 from "../../Assets/bpic2.png";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import ReactMarkdown from "react-markdown";

export default function Single() {
  const loc = useLocation();
  const [data, setData] = useState(loc.state ? loc.state?.data : "");
  // console.log(data);

  return (
    <div className="detail">
      {/* <Topbar /> */}
      <div className="data">
        <span className="gramb">{data?.attributes?.title || "title"}</span>

        <img
          src={
            "https://cms.petsetgo.com" +
              data?.attributes?.media?.data[0]?.attributes?.url || ""
          }
          alt=""
          className="img"
        />
        <div className="blog">
          {/* <p className="grama">{data?.attributes?.content || "content"}</p> */}
          <ReactMarkdown className="grama">
            {data?.attributes?.content || "content"}
          </ReactMarkdown>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}