import { useState, useContext } from "react";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import CartContext from "../Cart/Cart";
import { useNavigate } from "react-router-dom";
import GoogleButton from "react-google-button";
import { auth } from "../firbase.Config";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { commonHeaders, petSetUrl } from "../components/utils";

Modal.setAppElement("#root");

const LoginModal = ({ isOpen, onRequestClose }) => {
  const navigate = useNavigate();
  const { user, setUser } = useContext(CartContext);
  const [Email, SetEmail] = useState("");
  const [Password, SetPassword] = useState("");

  const handleSignInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();

    try {
      const result = await signInWithPopup(auth, provider);
      localStorage.setItem("user", JSON.stringify(result.user));
      localStorage.setItem("uid", result.user.uid);
      localStorage.setItem("firstname", result.user.displayName);
      localStorage.setItem("Email", result.user.email);
      localStorage.setItem("Phone", result.user.providerData[0].phoneNumber);
      setUser(result.user);
      Swal.fire("Successfully signed in with Google");
      onRequestClose();

      const data = {
        uid: result.user.uid,
        first_name: result.user.displayName,
        email: result.user.email,
      };
      axios
        .post(`${petSetUrl}/v1/customer/sociallogin`, data, {
          headers: commonHeaders,
        })
        .then((response) => {
          // console.log(response);
          localStorage.setItem("uid", response.data.data.uid);
          localStorage.setItem("firstname", response.data.data.first_name);
          localStorage.setItem("lastname", response.data.data.last_name);
          localStorage.setItem("Email", response.data.data.email);
          localStorage.setItem("Phone", response.data.data.phone);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error("Error signing in with Google:", error.message);
    }
  };

  const validate = () => {
    if (Email.length === 0 || Password.length === 0) {
      Swal.fire("All fields required");
    } else {
      const LoginData = {
        email: Email,
        password: Password,
      };
      axios
        .post(`${petSetUrl}/v1/customer/login`, LoginData, {
          headers: commonHeaders,
          // headers: {
          //   "Content-Type": "application/json",
          //   Authorization: "Bearer 22ba20b1-cee6-42ff-9bf0-5f3ef9055c11",
          // },
        })
        .then((response) => {
          if (response.status === 200) {
            Swal.fire("Login Successfully");
            localStorage.setItem("uid", response.data.data.uid);
            localStorage.setItem("firstname", response.data.data.first_name);
            localStorage.setItem("lastname", response.data.data.last_name);
            localStorage.setItem("Email", response.data.data.email);
            localStorage.setItem("Phone", response.data.data.phone);
            localStorage.setItem("user", JSON.stringify(response.data.data));
            setUser(response.data.data);
            onRequestClose();
            // console.log(response.data.data);
            // navigate("/");
          } else {
            Swal.fire("Incorrect email or password");

            navigate("/Login");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Login Modal"
      // className="Login_Modal"
      // style={{
      //   content: {
      //     width: "40%",
      //     display: "flex",
      //     flexDirection: "column",
      //     justifyContent: "center",
      //     alignItems: "center",
      //     margin: "auto",
      //     marginTop: "4%",
      //   },
      //   overlay: {
      //     backgroundColor: "rgba(0, 0, 0, 0.7)",
      //   },
      //   "@media (max-width: 768px)": {
      //     content: {
      //       width: "100%",
      //     },
      //   },
      // }}
      style={{
        content: {
          width: "80%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          margin: "auto",
          marginTop: "4%",
        },
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.7)",
        },
        // "@media (max-width: 768px)": {
        //   content: {
        //     width: "100%",
        //   },
        // },
      }}
    >
      <h2 className="text-center">
        Log in to your <br /> account
      </h2>
      <div>
        <label style={{ fontFamily: "black" }} className="mt-2 small">
          Email
        </label>
        <br />
        <input
          type="email"
          value={Email}
          className="w-100 border rounded-3 mt-2"
          style={{
            padding: "0.375rem 2.25rem 0.375rem 0.75rem",
            borderWidth: "2px",
            borderColor: "#007bff",
            outline: "none",
          }}
          onChange={(e) => SetEmail(e.target.value)}
        />

        <br />
        <label style={{ fontFamily: "black" }} className="mt-2 small">
          Password
        </label>
        <br />
        <input
          type="password"
          value={Password}
          className="w-100 border rounded-3 mt-2"
          style={{
            padding: "0.375rem 2.25rem 0.375rem 0.75rem",
            borderWidth: "2px",
            borderColor: "#007bff",
            outline: "none",
          }}
          onChange={(e) => SetPassword(e.target.value)}
        />
        <br />
        <Link
          to="/ForgetPassword"
          className="text-decoration-none"
          style={{ color: "#E0D9FA" }}
        >
          <p className="mt-3 text-end small">Forget your password?</p>
        </Link>
        <button
          className="border-0 rounded-5 px-4 py-2 mt-3 small w-100"
          style={{ fontFamily: "black", backgroundColor: "#E0D9FA" }}
          onClick={validate}
        >
          Login
        </button>
        <p className="text-center small mt-3">
          No account? Create one{" "}
          <Link
            to="/Registration"
            className="text-decoration-none"
            style={{ color: "#E0D9FA" }}
          >
            here
          </Link>
        </p>
      </div>
      <GoogleButton
        label="Contnue with Google"
        type="light"
        onClick={handleSignInWithGoogle}
      />
    </Modal>
  );
};

export default LoginModal;
