import "./blogs.scss";
// import Topbar from "../../Sections/Topbar/Topbar";
// import Footer from "../../Sections/Footer/Footer";
// import blog from "../../Assets/blog.png";
import blog from '../../images/blog.png'
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ColorRing } from "react-loader-spinner";

export default function Blogs() {
  const nav = useNavigate();
  const [index, setIndex] = useState(1);
  const [blogs, setBlogs] = useState(null);

  const LimitedText = (text) => {
    const words = text?.split(" ");
    if (words?.length > 35) {
      const limitedText = words?.slice(0, 35).join(" ");
      return <div>{limitedText} ...</div>;
    }
    return <div>{text}</div>;
  };

  const next = () => {
    setIndex(index + 1);
  };
  const prev = () => {
    index > 1 ? setIndex(index - 1) : setIndex(1);
  };

  useEffect(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `https://cms.petsetgo.com/api/blogs?sort=desc&populate=*&pagination[page]=${index}&pagination[pageSize]=10`,
      headers: {
        accept: "application/json",
        Authorization:
          "Bearer 983c41cdf0c329784bdbde473a2e7146559e94bd135032de86eda6291302310da86249e439452bb1fd7442076eeece9b5764c2b864df3b294a39d64c76f45c2d3ada8e68cf542c952aa04fd6afade2ffd41ac2b45c721a94a3d998d180d24fc86752a926b547a9e58eee1c3b036b176d08d875d0bfc8d65135d7b4d10e1c8de3",
      },
    };

    axios
      .request(config)
      .then((response) => {
        if (response?.data?.data.length > 0) {
          const resData = response?.data?.data;
          const sortData = resData.sort(
            (a, b) =>
              new Date(b.attributes.updatedAt) -
              new Date(a.attributes.updatedAt)
          );
          // console.log(sortData);
          setBlogs(sortData);
        } else {
          setIndex(1);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [index]);

  function date(dateString) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const formatDate = (date) => {
      const month = months[date.getMonth()];
      const day = String(date.getDate()).padStart(2, "0");
      const year = date.getFullYear();
      return `${month} ${day} ${year}`;
    };

    const dateObject = new Date(dateString);
    return formatDate(dateObject);
  }

  return (
    <div className="page">
      {/* <Topbar /> */}
      <div className="data">
        <span className="gramb">Does your world revolves around your pet?</span>
        <div className="blogs">
          <span className="grama">Blogs</span>
          {blogs ? (
            blogs?.map((item, i) => {
              return (
                <div
                  className="blog point"
                  key={i}
                  onClick={() =>
                    nav("./blog", {
                      state: {
                        data: item,
                      },
                    })
                  }
                >
                  <div className="bloginfo">
                    <div className="top">
                      <span className="grama">
                        {item.attributes?.categories?.data[0]?.attributes
                          ?.title || "No Catog Mentiond"}
                      </span>
                      <span className="grama date">
                        {date(item?.attributes?.publishedAt) || ""}
                      </span>
                    </div>
                    <span className="gramb">
                      {item?.attributes?.title || ""}
                    </span>
                    <span className="grama">
                      {LimitedText(item?.attributes?.content) || ""}
                    </span>
                  </div>
                  <div className="imgwrap">
                    {item?.attributes?.media?.data?.[0]?.attributes?.url ? (
                      <img
                        src={
                          "https://cms.petsetgo.com" +
                          item?.attributes?.media?.data[0]?.attributes?.url
                        }
                        alt=""
                        className="img"
                      />
                    ) : null}
                  </div>
                </div>
              );
            })
          ) : (
            <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="blocks-loading"
              wrapperStyle={{ alignSelf: "center" }}
              wrapperClass="blocks-wrapper"
              colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
            />
          )}
          <div className="btns">
            <button className="btn point" onClick={prev}>
              &larr;
            </button>
            <button className="btn point" onClick={next}>
              {index}
            </button>
            <button className="btn point" onClick={next}>
              &rarr;
            </button>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}