import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import CartContext from "../Cart/Cart";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { auth } from "../firbase.Config";
// import { FcGoogle } from "react-icons/fc";
import GoogleButton from "react-google-button";
import { commonHeaders, petSetUrl } from "../components/utils";

const Login = () => {
  const { setUser } = useContext(CartContext);
  const [Email, SetEmail] = useState("");
  const [Password, SetPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const handleSignInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();

    try {
      const result = await signInWithPopup(auth, provider);
      localStorage.setItem("user", JSON.stringify(result.user));
      localStorage.setItem("uid", result.user.uid);
      localStorage.setItem("firstname", result.user.displayName);
      localStorage.setItem("Email", result.user.email);
      localStorage.setItem("Phone", result.user.providerData[0].phoneNumber);

      setUser(result.user);
      Swal.fire("Successfully signed in with Google");
      navigate("/");

      const data = {
        uid: result.user.uid,
        first_name: result.user.displayName,
        email: result.user.email,
      };
      axios
        .post(`${petSetUrl}/v1/customer/sociallogin`, data, {
          headers: commonHeaders,
        })
        .then((response) => {
          // console.log(response);
          localStorage.setItem("uid", response.data.data.uid);
          localStorage.setItem("firstname", response.data.data.first_name);
          localStorage.setItem("lastname", response.data.data.last_name);
          localStorage.setItem("Email", response.data.data.email);
          localStorage.setItem("Phone", response.data.data.phone);

          window.location.reload();
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error("Error signing in with Google:", error.message);
    }
  };

  const validate = () => {
    setLoading(true); // Set loading to true when the API request starts

    if (Email.length === 0 || Password.length === 0) {
      Swal.fire("All fields required");
      setLoading(false); // Set loading to false in case of validation error
    } else {
      const LoginData = {
        email: Email,
        password: Password,
      };

      axios
        .post(`${petSetUrl}/v1/customer/login`, LoginData, {
          headers: commonHeaders,
        })
        .then((response) => {
          if (response.status === 200) {
            Swal.fire("Login Successfully");
            localStorage.setItem("uid", response.data.data.uid);
            localStorage.setItem("firstname", response.data.data.first_name);
            localStorage.setItem("lastname", response.data.data.last_name);
            localStorage.setItem("Email", response.data.data.email);
            localStorage.setItem("Phone", response.data.data.phone);
            localStorage.setItem("user", JSON.stringify(response.data.data));
            setUser(response.data.data);
            navigate("/");

            const data = {
              uid: response.data.data.uid,
              first_name: response.data.data.first_name,
              email: response.data.data.email,
            };
            axios
              .post(`${petSetUrl}/v1/customer/sociallogin`, data, {
                headers: commonHeaders,
              })
              .then((response) => {
                // console.log(response);
                localStorage.setItem("uid", response.data.data.uid);
                localStorage.setItem(
                  "firstname",
                  response.data.data.first_name
                );
                localStorage.setItem("lastname", response.data.data.last_name);
                localStorage.setItem("Email", response.data.data.email);
                localStorage.setItem("Phone", response.data.data.phone);

                window.location.reload();
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            Swal.fire("Incorrect email or password");
            navigate("/Login");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        })
        .finally(() => {
          setLoading(false); // Set loading to false regardless of success or failure
        });
    }
  };

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <div className="loader"></div>
        </div>
      ) : (
        <div className="d-flex justify-content-center align-items-center mt-5">
          <div className="row">
            <div className="col-12">
              <h2 className="text-center">
                Log in to your <br /> account
              </h2>
              <div>
                <label style={{ fontFamily: "black" }} className="mt-2 small">
                  Email
                </label>
                <br />
                <input
                  type="email"
                  value={Email}
                  className="w-100 border rounded-3 mt-2"
                  style={{
                    padding: "0.375rem 2.25rem 0.375rem 0.75rem",
                    borderWidth: "2px",
                    borderColor: "#007bff",
                    outline: "none",
                  }}
                  onChange={(e) => SetEmail(e.target.value)}
                />

                <br />
                <label style={{ fontFamily: "black" }} className="mt-2 small">
                  Password
                </label>
                <br />
                <input
                  type="password"
                  value={Password}
                  className="w-100 border rounded-3 mt-2"
                  style={{
                    padding: "0.375rem 2.25rem 0.375rem 0.75rem",
                    borderWidth: "2px",
                    borderColor: "#007bff",
                    outline: "none",
                  }}
                  onChange={(e) => SetPassword(e.target.value)}
                />
                <br />
                <Link
                  to="/ForgetPassword"
                  className="text-decoration-none"
                  style={{ color: "#E0D9FA" }}
                >
                  <p className="mt-3 text-end small">Forget your password?</p>
                </Link>
                <button
                  className="border-0 rounded-5 px-4 py-2 mt-3 small w-100"
                  style={{ fontFamily: "black", backgroundColor: "#E0D9FA" }}
                  onClick={validate}
                >
                  Login
                </button>
                <p className="text-center small mt-3">
                  No account? Create one{" "}
                  <Link
                    to="/Registration"
                    className="text-decoration-none"
                    style={{ color: "#E0D9FA" }}
                  >
                    here
                  </Link>
                </p>
              </div>
              <GoogleButton
                label="Contnue with Google"
                type="light"
                onClick={handleSignInWithGoogle}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Login;
