import React from "react";
import "./home.css";
import head_back from "../../images/head_back.png";
import head_image from "../../images/head_image.png";
import { Link } from "react-router-dom";
import downloadback from "../../images/head_back.png";
import downloadMobile from "../../images/download_mobile_image.png";
import downloadcat from "../../images/download_cat_image.png";

const Head = () => {
  return (
    <>
      <div className="headsection">
        <div className="head_text">
          <h6 className="mb-3 free_shooping">FREE SHIPPING</h6>
          <h1 className="head_heading2">Fully catering to your pet’s needs.</h1>
          <p className="paragraph">
            Welcome to PET SET GO Dive into our extensive catalog featuring a
            diverse range of products & services that cater to the unique needs
            and preferences of every pet. From premium pet food and treats to
            stylish accessories, cozy beds, entertaining toys, grooming,
            veterinary, and video call, we've curated a collection that promises
            to pamper your pets in every way imaginable.
          </p>
          <Link to="/Store">
            <button className="head_button">SHOP NOW</button>
          </Link>
        </div>
        {/* <div className="head_image d-none d-md-block">
          <img className="head_image1" src={head_back} alt="" />
          <img className="head_image2" src={head_image} alt="" />
        </div> */}
        <div className="Download_App_images d-none d-md-block">
          <img className="image1" src={downloadback} alt="" />
          <img className="image4" src={downloadcat} alt="" />
          <img className="image5" src={downloadMobile} alt="" />
        </div>
      </div>
    </>
  );
};

export default Head;
