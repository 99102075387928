import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { commonHeaders, petSetUrl } from "../../components/utils";
import storeimage from "../../images/store.png";
import location1 from "../../images/location1.png";
import star1 from "../../images/star1.png";
import Loader from "../../components/Home/Loader";
import AvailableSlots from "../../components/Services/AvailableSlots";
import FiftyOff from "../../components/Home/FiftyOff";
import CartContext from "../../Cart/Cart";

const SingleStoreServices = () => {
  const { ServiceType, currentdate } = useContext(CartContext);
  const location = useLocation();
  const nav = useNavigate();

  const [storeData, setStoreData] = useState(location?.state?.data || {});
  const [servicesData, setServicesData] = useState([]);
  const [slotsData, setSlotsData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = async (cid, sid, type) => {
    try {
      const response = await axios.get(
        `${petSetUrl}/v1/service/get/category?store_id=${sid}&cid=${cid}&type=${type}`,
        { headers: commonHeaders }
      );
      if (response) {
        setServicesData(response.data.service);
      }
    } catch (error) {
      console.error("Error fetching services:", error);
      setServicesData([]);
    } finally {
      setLoading(false);
    }
  };

  const fetchSlots = async (slot) => {
    try {
      const response = await axios.get(
        `${petSetUrl}/v1/store/slots?slot_id=${slot}&date=${currentdate}&type=${ServiceType}`,
        { headers: commonHeaders }
      );
      if (response) {
        setSlotsData(response.data.slots);
      }
    } catch (error) {
      console.error("Error fetching slots:", error);
      setSlotsData([]);
    }
  };

  useEffect(() => {
    const cid = storeData?.store_tag?.categoryCid;
    const sid = storeData?.store_tag?.storeSid;

    fetchData(cid, sid, "main");
  }, [location]);

  useEffect(() => {
    const slot = storeData?.bookingSlot_updateds?.[0]?.slot_id;
    fetchSlots(slot);
  }, [location, currentdate, ServiceType]);

  return (
    <>
      {/* <div style={{ height: "40px" }} className="border-bottom"></div> */}
      <div
        className="mb-5"
        style={{ height: "100px", backgroundColor: "#F2EDFF" }}
      ></div>
      <div className="mt-5 ps-5">
        <div className="ps-lg-5 mb-5 d-lg-flex d-md-flex d-sm-block ps-sm-0">
          <div className="mt-2">
            <img
              src={
                storeData.media === "http"
                  ? storeData.media
                  : storeData.media === ""
                  ? storeimage
                  : `https://petsetgostorage.blob.core.windows.net/petsetgo-u2/${storeData.media}`
              }
              alt=""
              width="100px"
              height="100px"
              className="bg-white border border-dark"
            />
          </div>
          <div className="ms-md-4 ms-sm-0 lh-1">
            <p>Home &gt; Services &gt; {storeData.store_name}</p>
            <h1>{storeData.store_name}</h1>
            <div className="d-flex">
              <p>
                <img className="mb-1" src={location1} alt="" width="16px" />{" "}
                <span className="small">
                  {" "}
                  {storeData.distance.toFixed(2)} KM away
                </span>
              </p>
              <p className="ms-2">
                {Array(Math.floor(storeData.averageRatings))
                  .fill(null)
                  .map((_, starIndex) => (
                    <img
                      className="mb-1"
                      key={starIndex}
                      src={star1}
                      alt=""
                      width="16px"
                    />
                  ))}
                <span className="small">
                  {" "}
                  {storeData.averageRatings.toFixed(1)}
                </span>
              </p>
            </div>
          </div>
        </div>

        <div className="row row-cols-lg-2 services-container ">
          <div className="ms-md-4 serviceproduct">
            <div className="row row-cols-sm-1 row-cols-md-2 row-cols-lg-3 g-4">
              {loading ? (
                <Loader />
              ) : (
                servicesData.map((item, index) => (
                  <div className="col" key={index}>
                    <div className="card border-0" key={index}>
                      <img
                        className="border"
                        src={
                          item.media.substring(0, 4) === "http"
                            ? item.media
                            : item.media === ""
                            ? storeimage
                            : `https://petsetgostorage.blob.core.windows.net/petsetgo-u2/${item.media}`
                        }
                        alt="media not found"
                        width="285px"
                        height="240px"
                      />
                      <h4 className="mt-2">AED: {item.service_price}</h4>
                      <div className="title">
                        <h6
                          className="mb-3"
                          style={{
                            // overflow: "hidden",
                            // textOverflow: "ellipsis",
                            // whiteSpace: "nowrap",
                            maxWidth: "285px",
                          }}
                        >
                          {item.service_title}
                        </h6>{" "}
                        <button
                          className="productbtn"
                          style={{ width: "285px" }}
                          onClick={() =>
                            nav(`/Services/service`, {
                              state: {
                                data: {
                                  item: item,
                                  slots: slotsData,
                                  store: storeData,
                                },
                              },
                            })
                          }
                        >
                          View Service
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>

          <div className="ms-3 ms-md-4 serviceslots d-md-block">
            <AvailableSlots data={slotsData} />
          </div>
        </div>
      </div>

      <div className="mt-5">
        <FiftyOff />
      </div>
    </>
  );
};

export default SingleStoreServices;
