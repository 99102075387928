import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
const firebaseConfig = {
  //prod configs
  apiKey: "AIzaSyDXqf1oQ_nGFcGnQDyNSzO35wGxT2__rPI",
  authDomain: "petsetgo-staging.firebaseapp.com",
  projectId: "petsetgo-staging",
  storageBucket: "petsetgo-staging.appspot.com",
  messagingSenderId: "1041343953167",
  appId: "1:1041343953167:web:6703205440aec6be88a67a",
  measurementId: "G-0SM79JP7SV",
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
