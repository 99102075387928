import { format, parse } from "date-fns";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { commonHeaders, petSetUrl } from "../utils";
const DoctortimeSlots = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;
  const [currentdate, setcurrentdate] = useState(formattedDate);
  const [DoctorSlots, setDoctorSlots] = useState(formattedDate);
  const [DoctorSlotsData, setDoctorSlotsData] = useState();
  const [selectedButton, setSelectedButton] = useState("");

  const location = useLocation();
  const resource_id = location.state.data;
  useEffect(() => {
    const Api = `${petSetUrl}/v1/video/resource/slots?resource_id=${resource_id}&date=${currentdate}`;
    const requestOptions = {
      method: "GET",
      headers: commonHeaders,
    };
    fetch(Api, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        setDoctorSlots(responseData.slots);
        setDoctorSlotsData(responseData.data);
        localStorage.setItem(
          "DotorSlotData",
          JSON.stringify(responseData.data)
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, [resource_id, currentdate]);

  const formattedTimes =
    Array.isArray(DoctorSlots) && DoctorSlots.length > 0
      ? DoctorSlots.map(([timeString]) => {
          const parsedTime = parse(timeString, "hh:mm:ss a", new Date());
          return format(parsedTime, "hh:mm a");
        })
      : ["No slots found"];
  // const DoctortimeSlot = location.pathname === "/Doctor" && (
  //   <div className="mt-3">
  //     <div className="row g-2">
  //       <button
  //         className="col-3 mb-2 ms-2 border-0 rounded-5 Availablebutton"
  //         style={{ width: "20%" }}
  //       >
  //         11:00 AM
  //       </button>

  //     </div>
  //   </div>
  // );
  const handleButtonClick = (item) => {
    setSelectedButton(item);
    // localStorage.setItem("select date", currentdate);
    localStorage.setItem("Doctor select time", item);
  };
  const slotsArray = formattedTimes.map((item, i) => {
    return (
      <button
        className={`col-3 mb-2 ms-2 border-0 rounded-5 Availablebutton ${
          selectedButton === item ? "selected" : ""
        }`}
        onClick={() => handleButtonClick(item)}
        key={i}
      >
        {item}
      </button>
    );
  });
  const DoctortimeSlot = <div className="row g-2">{slotsArray}</div>;

  return (
    <>
      <div className="border p-4 row">
        <h6 className="col-12">Available Slots</h6>
        <div className="row mt-3">
          <input
            type="date"
            className="border rounded-3 col-12"
            value={currentdate}
            onChange={(e) => setcurrentdate(e.target.value)}
            min={formattedDate}
            style={{
              padding: "0.375rem 2.25rem 0.375rem 0.75rem",
              borderWidth: "2px",
              borderColor: "#007bff",
              outline: "none",
            }}
          />
        </div>
        {DoctortimeSlot}
      </div>
    </>
  );
};

export default DoctortimeSlots;
