import { useState, useEffect, useContext } from "react";
import star1 from "../../images/star1.png";
import increment from "../../images/increment.png";
import decrement from "../../images/decrement.png";
import { useParams } from "react-router-dom";
import CartContext from "../../Cart/Cart";
import { useNavigate, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import LoginModal from "../../pages/LoginModal";
import { commonHeaders, petSetUrl } from "../utils";
import axios from "axios";
// import downloadback from "./../../images/head_back.png";
// import downloadMobile from "./../../images/download_mobile_image.png";
// import downloadcat from "./../../images/download_cat_image.png";

const StoreProductHead = () => {
  const location = useLocation();
  const singleproduct = location?.state?.data;
  const singleStoreDetail = location?.state?.store;
  // console.log(singleproduct);
  const nav = useNavigate();
  const [count, setCount] = useState(1);
  const { user, addProduct, state, cartDelete, setStore } =
    useContext(CartContext);
  // const { storeId, productId } = useParams();
  const storeId = location?.state?.store_id;
  const productId = location?.state?.product_id;

  // const [productDetail, setproductDetail] = useState([]);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const handleGoBack = () => {
    // console.log("Go back clicked");
  };

  // const handleProceedTo = (item) => {
  //   const data = {
  //     shop_id: storeId,
  //     uid: user.uid,
  //     price: item.price,
  //     productID: item.pid,
  //     quantity: count,
  //     discount_price: 0.0,
  //     media: item.img_id,
  //     title: item.title,
  //     Weight: item.weight,
  //   };
  //   cartDelete();
  //   setStore(singleStoreDetail);
  //   addProduct(data);
  // };

  // const handleAddProduct = () => {
  //   const hasData =
  //     (state?.slots && state?.slots?.length > 0) ||
  //     (state?.products && state?.products?.length > 0);

  //   if (hasData && state?.shop_id !== singleStoreDetail.store_id) {
  //     Swal.fire({
  //       title:
  //         "Your items from another store are in the cart, upon proceeding your items from another store will be cleared",
  //       text: "Choose an action:",
  //       icon: "info",
  //       showCancelButton: true,
  //       confirmButtonText: "Proceed",
  //       cancelButtonText: "Go Back",
  //     }).then((result) => {
  //       if (result.isConfirmed) {
  //         handleProceedTo(singleproduct);
  //       } else if (result.dismiss === Swal.DismissReason.cancel) {
  //         handleGoBack();
  //       }
  //     });
  //   } else {
  //     try {
  //       const item = {
  //         shop_id: storeId,
  //         uid: user.uid,
  //         price: singleproduct.price,
  //         productID: singleproduct.pid,
  //         quantity: count,
  //         discount_price: 0.0,
  //         media: singleproduct.img_id,
  //         title: singleproduct.title,
  //         Weight: singleproduct.weight,
  //       };
  //       const existItem = state.products.find(
  //         (prod) => prod.productID === item.productID
  //       );
  //       if (existItem) {
  //         Swal.fire("Item already exist in cart");
  //       } else {
  //         setStore(singleStoreDetail);
  //         addProduct(item);
  //         nav("/Cart");
  //       }
  //     } catch (error) {
  //       console.error("Error adding product to cart:", error);
  //     }
  //   }
  // };

  const handleProceedTo = (item) => {
    const data = {
      shop_id:  singleproduct.store_id || singleStoreDetail.sid,
      uid: localStorage.getItem("uid"),
      products: [
        {
          productID: singleproduct.pid,
          quantity: count,
          price: singleproduct?.price,
          discount_price: 0.0,
        },
      ],
      slots: [],
      dayboarding: [],
    };
    axios
      .post(`${petSetUrl}/v1/cart/add`, data)
      .then((response) => {
        // console.log(response);
        Swal.fire("cart added");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleAddProduct = () => {
    axios
      .get(`${petSetUrl}/v1/cart/get?uid=${localStorage.getItem("uid")}`, {
        headers: commonHeaders,
      })
      .then((response) => {
        // console.log(response.data.data);
        if (
          
          response?.data?.data?.shop_id !== singleproduct.store_id ||
          response?.data?.data?.shop_id !== singleStoreDetail.sid
        ) {
          Swal.fire({
            title:
              "Your items from another store are in the cart, upon proceeding your items from another store will be cleared",
            text: "Choose an action:",
            icon: "info",
            showCancelButton: true,
            confirmButtonText: "Proceed",
            cancelButtonText: "Go Back",
          }).then((result) => {
            if (result.isConfirmed) {
              handleProceedTo(singleproduct);
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              handleGoBack();
            }
          });
        }

        if (
          
          response?.data?.data?.shop_id === singleproduct.store_id ||
          response?.data?.data?.shop_id === singleStoreDetail.sid
        ) {
          const data = {
            cart_id: response?.data?.data?.cart_id,

            productID: singleproduct.pid,
            quantity: count,
            price: singleproduct?.price,
            discount_price: 0.0,
          };
          axios
            .post(`${petSetUrl}/v1/cart/product/add`, data)
            .then((response) => {
              // console.log(response);
              Swal.fire("cart updated");
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {
        console.log(error);
        // if (error?.response?.data?.message === "cart not found") {
        const data = {
          shop_id: singleproduct.store_id || singleStoreDetail.sid ,
          uid: localStorage.getItem("uid"),
          products: [
            {
              productID: singleproduct.pid,
              quantity: count,
              price: singleproduct?.price,
              discount_price: 0.0,
            },
          ],
          slots: [],
          dayboarding: [],
        };
        axios
          .post(`${petSetUrl}/v1/cart/add`, data)
          .then((response) => {
            // console.log(response);
            Swal.fire("cart added");
          })
          .catch((error) => {
            console.log(error);
          });
        // }
      });
  };

  const closeLoginModal = () => {
    setIsLoginModalOpen(false);
  };
  // useEffect(() => {
  //   // const productApi = `${petSetUrl}/v1/store/product/get/category?store_id=${storeId}&cid=${categoryId}&type=child&offset=0&limit=100`;
  //   const productApi = `${petSetUrl}/v1/store/product/get?store_id=${storeId}`;
  //   const requestOptions = {
  //     method: "GET",
  //     headers: commonHeaders,
  //   };
  //   fetch(productApi, requestOptions)
  //     .then((response) => {
  //       return response.json();
  //     })
  //     .then((responseData) => {
  //       const product = responseData.products.find((item) => {
  //         return item.product_id === productId;
  //       });
  //       console.log(product);
  //       setproductDetail(product);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching store data from the API:", error);
  //     });
  // }, [storeId, productId]);
  return (
    <>
      <div
        className="row row-cols-1 row-cols-md-2 g-4"
        style={{ padding: "0% 5%" }}
      >
        <div
          style={{
            width: "45%",
            height: "400px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
          }}
        >
          <img
            src={singleproduct.img_id}
            alt=""
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              objectFit: "cover",
            }}
            loading="lazy"
            // width="200px"
            // height="400px"
            // style={{ display: "block", maxWidth: "100%", height: "auto" }}
          />
        </div>
        <div className="mt-8">
          <p className="">
            {Array(5)
              .fill(null)
              .map((_, starIndex) => (
                <img
                  className="mb-1"
                  key={starIndex}
                  src={star1}
                  alt=""
                  width="16px"
                />
              ))}
            <span className="small">{singleproduct.averageRatings}</span>
          </p>
          <h3>{singleproduct.title}</h3>
          {/* {singleproduct && singleproduct.description ? (
            <p className="mt-5 w-75" style={{ fontSize: "16px" }}>
              {singleproduct.description.substring(0, 200)}...
            </p>
          ) : null} */}
          <h5 className="mt-2">AED {singleproduct.price}</h5>

          <h6 className="mt-5">QUANTITY</h6>
          <div
            className="d-flex justify-content-between border px-2"
            style={{ height: "40px", width: "40%", alignItems: "center" }}
          >
            <div>
              <img src={increment} alt="" onClick={() => setCount(count + 1)} />
            </div>
            {count}
            <div>
              <img
                src={decrement}
                alt=""
                onClick={() => setCount(count > 1 ? count - 1 : 1)}
              />
            </div>
          </div>

          <LoginModal
            isOpen={isLoginModalOpen}
            onRequestClose={closeLoginModal}
          />

          <button
            className="border-0 rounded-5 mt-5 foodbtn w-100"
            style={{
              fontFamily: "black",
            }}
            onClick={() => {
              user ? handleAddProduct() : setIsLoginModalOpen(true);
            }}
            disabled={singleproduct.stock === 0}
          >
            {singleproduct.stock === 0 ? "Out of Stock" : "Add to Cart"}
          </button>
        </div>
      </div>
    </>
  );
};
export default StoreProductHead;
