import { useLocation } from "react-router-dom";
import NoImg from "../images/no_image.avif";
import { FaPlusSquare } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import CartContext from "../Cart/Cart";
import { useState } from "react";
import { useContext } from "react";
import Swal from "sweetalert2";
import LoginModal from "./LoginModal";
import { petSetUrl, commonHeaders } from "../components/utils";
import axios from "axios";

const SearchProduct = () => {
  const { user, addProduct, state, setStore, cartDelete } =
    useContext(CartContext);
  const location = useLocation();
  const nav = useNavigate();
  const product = location.state?.data;
  const Query = location.state?.query;
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  // console.log(product);

  const handleAddProduct = (productDetail, id) => {
    try {
      const item = {
        shop_id: id,
        uid: user.uid,
        price: productDetail.price,
        productID: productDetail.pid,
        quantity: 1,
        discount_price: 0.0,
        media: productDetail.img_id,
        title: productDetail.title,
        Weight: productDetail.weight,
      };
      const existItem = state.products.find(
        (prod) => prod.productID === item.productID
      );
      if (existItem) {
        Swal.fire("Item already exist in cart");
      } else {
        addProduct(item);
        nav("/Cart");
      }
    } catch (error) {
      console.error("Error adding product to cart:", error);
    }
  };

  const handleGoBack = () => {};

  const handleProceedTo = (item, id) => {
    // cartDelete();
    // setStore(item);
    // handleAddProduct(item, id);
    const data = {
      shop_id: id,
      uid: localStorage.getItem("uid"),
      products: [
        {
          productID: item.pid,
          quantity: 1,
          price: item.price,
          discount_price: 0.0,
        },
      ],
      slots: [],
      dayboarding: [],
    };
    axios
      .post(`${petSetUrl}/v1/cart/add`, data)
      .then((response) => {
        // console.log(response);
        Swal.fire("cart added");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleStoreNav = (item, id) => {
    // const hasData =
    //   (state?.slots && state?.slots?.length > 0) ||
    //   (state?.products && state?.products?.length > 0);

    // if (hasData && state?.shop_id !== item.sid) {
    //   Swal.fire({
    //     title: "You already have items in cart from another Store",
    //     text: "Choose an action:",
    //     icon: "info",
    //     showCancelButton: true,
    //     confirmButtonText: "Proceed To",
    //     cancelButtonText: "Go Back",
    //   }).then((result) => {
    //     if (result.isConfirmed) {
    //       handleProceedTo(item, id);
    //     } else if (result.dismiss === Swal.DismissReason.cancel) {
    //       handleGoBack();
    //     }
    //   });
    // } else {
    //   setStore(item);
    //   handleAddProduct(item, id);
    // }

    axios
      .get(`${petSetUrl}/v1/cart/get?uid=${localStorage.getItem("uid")}`, {
        headers: commonHeaders,
      })
      .then((response) => {
        // console.log(response.data.data);
        if (response?.data?.data?.shop_id !== id) {
          Swal.fire({
            title:
              "Your items from another store are in the cart, upon proceeding your items from another store will be cleared",
            text: "Choose an action:",
            icon: "info",
            showCancelButton: true,
            confirmButtonText: "Proceed",
            cancelButtonText: "Go Back",
          }).then((result) => {
            if (result.isConfirmed) {
              handleProceedTo(item, id);
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              handleGoBack();
            }
          });
        }

        if (response?.data?.data?.shop_id === id) {
          const data = {
            cart_id: response?.data?.data?.cart_id,

            productID: item.pid,
            quantity: 1,
            price: item?.price,
            discount_price: 0.0,
          };
          axios
            .post(`${petSetUrl}/v1/cart/product/add`, data)
            .then((response) => {
              // console.log(response);
              Swal.fire("Cart Updated");
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {
        console.log(error);
        const data = {
          shop_id: id,
          uid: localStorage.getItem("uid"),
          products: [
            {
              productID: item.pid,
              quantity: 1,
              price: item?.price,
              discount_price: 0.0,
            },
          ],
          slots: [],
          dayboarding: [],
        };
        axios
          .post(`${petSetUrl}/v1/cart/add`, data)
          .then((response) => {
            // console.log(response);
            // showAlert("Product added to the cart");
            Swal.fire("Cart Created");
          })
          .catch((error) => {
            console.log(error);
          });
      });
  };

  const closeLoginModal = () => {
    setIsLoginModalOpen(false);
  };

  return (
    <>
      <div style={{ padding: "3% 5%" }}>
        <h1 className="mb-4">
          {" "}
          Search Result for: <small>"{Query}"</small>
        </h1>
        <div className="row row-cols-1 row-cols-md-3 row-cols-lg-4 g-4">
          {product && product.length > 0 ? (
            product.map((item, index) => (
              <div className="col" key={index}>
                <div className="card border-0">
                  <div
                    style={{
                      width: "200px",
                      height: "300px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      src={item.img_id}
                      alt=""
                      className="card-img-top foodimg"
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        objectFit: "cover",
                      }}
                      loading="lazy"
                      onError={(e) => {
                        e.target.src = NoImg;
                      }}
                    />
                  </div>

                  <LoginModal
                    isOpen={isLoginModalOpen}
                    onRequestClose={closeLoginModal}
                  />

                  <div className="card-body">
                    <h5 className="card-title small">AED {item.price}</h5>
                    <h6
                      className="card-title text-dark w-100"
                      style={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {item.title}
                    </h6>
                    <small
                      className="card-text small"
                      style={{ color: "#A3A3A3" }}
                    >
                      {item.weight}
                    </small>{" "}
                    <br />
                    {item?.store?.store_name && (
                      <small>Sold by: {item.store.store_name}</small>
                    )}{" "}
                    <br />
                    <div className="d-flex justify-content-between">
                      {/* <Link
                        to={`/Storeproduct/${item.store_id}/${item.product_id}`}
                        className="text-decoration-none"
                      > */}
                      <button
                        className="productbtn border-0 rounded-5 mt-2 px-3"
                        // onClick={() => {
                        //   nav(`/Storeproduct/${item.store_id}/${item.product_id}`, {
                        //     state: {
                        //       data: item,
                        //     },
                        //   });
                        // }}
                        onClick={() => {
                          nav(
                            `/Storeproduct/${item.title
                              .toLowerCase()
                              .replace(/ /g, "-")}`,
                            {
                              state: {
                                data: item,
                                // store: storeDetail,
                                store_id: item.store_id,
                                product_id: item.product_id,
                              },
                            }
                          );
                        }}
                      >
                        View Product
                      </button>
                      {/* </Link> */}

                      <p
                        style={{
                          cursor: "pointer",
                          display: item.stock === 0 ? "none" : "block",
                        }}
                        // onClick={() => handleStoreNav(item, item.store_id)}
                        onClick={() => {
                          user
                            ? handleStoreNav(item, item.store_id)
                            : setIsLoginModalOpen(true);
                        }}
                      >
                        <FaPlusSquare
                          style={{
                            color: "#e57373",
                            width: "24px",
                            height: "24px",
                          }}
                        />
                      </p>
                    </div>
                    {item.stock === 0 ? (
                      <h6 className="mt-2" style={{ color: "#e57373" }}>
                        Out of Stock
                      </h6>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>Product not found</p>
          )}
        </div>
      </div>
    </>
  );
};

export default SearchProduct;
