import axios from "axios";
import { createContext, useEffect, useReducer, useState } from "react";
import { commonHeaders, petSetUrl } from "../components/utils";
import parse from "date-fns/parse";
import format from "date-fns/format";
import isValid from "date-fns/isValid";

const CartContext = createContext();
export const CartContextProvider = ({ children }) => {
  const [ServiceType, setServiceType] = useState(null);
  const [store, setStore] = useState(null);
  const [user, setUser] = useState(null);
  const [coupon, setCoupon] = useState({
    type: "",
    price: 0.0,
  });
  // const [addedItems, setAddedItems] = useState(0);
  // const [cartdata, setcartdata] = useState([]);

  useEffect(() => {
    const storedCart = JSON.parse(localStorage.getItem("cart"));
    if (storedCart) {
      dispatch({ type: "LOAD_CART_FROM_STORAGE", payload: storedCart });
    }

    const person = JSON.parse(localStorage.getItem("user"));
    setUser(person);
  }, []);
  // useEffect(() => {
  //   const fetchCartData = async () => {
  //     try {
  //       const response = await axios.get(
  //        ` ${petSetUrl}/v1/cart/get?uid=${localStorage.getItem("uid")}`,
  //         {
  //           headers: commonHeaders,
  //         }
  //       );

  //       if (response && response.data) {
  //         console.log(response.data.data);
  //         setcartdata(response.data.data);
  //         // setStore(response.data.data.store);

  //         const initialState = {
  //           shop_id: response.data.data.shop_id,
  //           uid: response.data.data.uid,
  //           products: response.data.data.cartProducts,
  //           slots: response.data.data.slotReservations,
  //           dayboarding: [],
  //           cart: true,
  //           cart_id: response.data.data.cart_id,
  //         };

  //         dispatch({ type: "LOAD_CART_FROM_STORAGE", payload: initialState });
  //       }
  //     } catch (error) {
  //       const initialState = {
  //         shop_id: "",
  //         uid: "",
  //         products: [],
  //         slots: [],
  //         dayboarding: [],
  //         cart: false,
  //         cart_id: "",
  //       };
  //       console.log(error);
  //     }
  //   };

  //   fetchCartData();
  // }, []);

  // console.log(user);

  // const createCart = async (cart, item, type) => {
  //   // console.log(user);
  //   let data = {
  //     shop_id: cart.shop_id,
  //     uid: cart.uid,
  //     products: cart.products,
  //     slots: cart.slots,
  //     dayboarding: cart.dayboarding,
  //   };
  //   try {
  //     const response = await axios.post(${petSetUrl}/v1/cart/add, data, {
  //       headers: commonHeaders,
  //     });
  //     if (response.data) {
  //               console.log(response.data);
  //       state.cart = true;
  //       state.cart_id = response.data.cart_id;
  //       item.cart_id = response.data.cart_id;
  //       if (type === "prod") {
  //         addProductInCard(item);
  //       } else {
  //         addReservation(item);
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  // const getCart = async (id) => {
  //   try {
  //     const response = await axios.get(${petSetUrl}/v1/cart/get?uid=${id}, {
  //       headers: commonHeaders,
  //     });

  //       if (response && response.data) {
  //         console.log(response.data.data);
  //         setcartdata(response.data.data);

  //         const initialState = {
  //           shop_id: response.data.data.shop_id,
  //           uid: response.data.data.uid,
  //           products: response.data.data.cartProducts,
  //           slots: response.data.data.slotReservations,
  //           dayboarding: [],
  //           cart: true,
  //           cart_id: response.data.data.cart_id,
  //         };
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  // useEffect(() => {
  //   getCart(localStorage.getItem("uid"));
  // }, []);


  const createCart = async (cart, item, type) => {
    let data = {
      shop_id: cart.shop_id,
      uid: cart.uid,
      products: cart.products,
      slots: cart.slots,
      dayboarding: cart.dayboarding,
    };
    try {
      const response = await axios.post(`${petSetUrl}/v1/cart/add`, data, {
        headers: commonHeaders,
      });
      if (response.data) {
       // console.log(response.data);
        dispatch({ type: "SET_CART_ID", payload: response.data.cart_id });

        item.cart_id = response.data.cart_id;
        if (type === "prod") {
          addProductInCard(item);
        } else {
          addReservation(item);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addReservation = async (service) => {
    try {
      const response = await axios.post(
       ` ${petSetUrl}/v1/service/slots/reserve/add`,
        service,
        {
          headers: commonHeaders,
        }
      );
      if (response) {
        // console.log("service added")
        // dispatch({
        //   type: "ADD_RESERVATION_SUCCESS",
        //   payload: response.data.cart_id,
        // });
        return response.data.cart_id;
      }
    } catch (error) {
      console.log(error);
      
      // console.log("error service added")
    }
  };

  const addProductInCard = async (product) => {
    try {
      const response = await axios.post(
        `${petSetUrl}/v1/cart/product/add`,
        product,
        {
          headers: commonHeaders,
        }
      );
      if (response) {
        // console.log(response.data);
        // console.log("product added")
        // dispatch({
        //   type: "ADD_PRODUCT_SUCCESS",
        //   payload: response.data.cart_id,
        // });
        return response.data.cart_id;
      }
    } catch (error) {
      console.log(error);
      // console.log("error product added")
    }
  };
  const initialState = {
    shop_id: "",
    uid: "",
    products: [],
    slots: [],
    dayboarding: [],
    cart: false,
    cart_id: "",
  };

  const reducer = (state, action) => {
    switch (action.type) {
      // case "ADD_RESERVATION_SUCCESS": {
      //   const newCartState = {
      //     ...state,
      //     addReservation: null, // Clear the addReservation flag
      //     // Handle other state updates as needed
      //   };
      //   // Save the updated cart state to local storage or handle it accordingly
      //   // localStorage.setItem("cart", JSON.stringify(newCartState));
      //   return newCartState;
      // }

      // case "ADD_PRODUCT_SUCCESS": {
      //   const newCartState = {
      //     ...state,
      //     addProduct: null, // Clear the addProduct flag
      //     // Handle other state updates as needed
      //   };
      //   // Save the updated cart state to local storage or handle it accordingly
      //   // localStorage.setItem("cart", JSON.stringify(newCartState));
      //   return newCartState;
      // }
      case "SET_CART_ID": {
        const newState = {
          ...state,
          cart: true,
          cart_id: action.payload,
        };

        // Save the updated cart state to local storage
        localStorage.setItem("cart", JSON.stringify(newState));

        return newState;
      }
      case "LOAD_CART_FROM_STORAGE": {
        return action.payload;
      }
      case "Add_Service": {
        let data = action.payload;
        // console.log(data);
        if (data.slotTiming) {
          try {
            const parsedSlotTiming = parse(
              data.slotTiming,
              "HH:mm:ss",
              new Date()
            );
            if (isValid(parsedSlotTiming)) {
              const formattedSlotTiming = format(parsedSlotTiming, "h:mm a");
              data.slotTiming = formattedSlotTiming;
            } else {
              // console.error("Invalid time value:", data.slotTiming);
            }
          } catch (error) {
            console.error("Error parsing time value:", data.slotTiming);
          }
        }
        // if (data.slotTiming) {
        //   const parsedSlotTiming = parse(
        //     data.slotTiming,
        //     "HH:mm:ss",
        //     new Date()
        //   );
        //   const formattedSlotTiming = format(parsedSlotTiming, "h:mm a");
        //   data.slotTiming = formattedSlotTiming;
        // }
        let newCart = {
          ...state,
          shop_id: data.store_id,
          uid: data.customer_uid,
          slots: [...state.slots, data],
        };
        if (!state.cart) {
          createCart(newCart, data, "serv");
          return newCart;
          // break;
        } else {
          // console.log("cart already there");
          data.cart_id = state.cart_id;
          addReservation(data);
          // return { ...state, slots: [...state.slots, data] };
          const updatedState = { ...state, slots: [...state.slots, data] };

          // Update local storage
          localStorage.setItem("cart", JSON.stringify(updatedState));
          return updatedState;

          // break;
        }
      }
      case "Add_Product": {
        let data = action.payload;
        // console.log(data);
        let newCart = {
          ...state,
          shop_id: data.shop_id,
          uid: data.customer_uid,
          products: [...state.products, data],
        };
        if (!state.cart) {
          createCart(newCart, data, "prod");
          return newCart;
          // break;
        } else {
          // console.log("cart already there");
          data.cart_id = state.cart_id;
          addProductInCard(data);
          // return { ...state, products: [...state.products, data] };
          const updatedState = {
            ...state,
            products: [...state.products, data],
          };

          // Update local storage
          localStorage.setItem("cart", JSON.stringify(updatedState));
          return updatedState;
          // break;
        }
      }

      case "Del_Service": {
        let id = action.payload;
        let slots = state.slots.filter((slot) => slot.service_id !== id);
        // console.log(slots);
        // return { ...state, slots: [...slots] };
        const updatedState = { ...state, slots: [...slots] };

        // Update local storage
        localStorage.setItem("cart", JSON.stringify(updatedState));
        return updatedState;
      }

      case "Del_Product": {
        let id = action.payload;
        // console.log(id);
        let products = state.products.filter((prod) => prod.productID !== id);
        // console.log(products);
        // return { ...state, products: [...products] };
        const updatedState = { ...state, products: [...products] };

        // Update local storage
        localStorage.setItem("cart", JSON.stringify(updatedState));
        return updatedState;
      }

      case "Del_Cart": {
        let id = action.payload;
        // return {
        //   shop_id: "",
        //   uid: "",
        //   products: [],
        //   slots: [],
        //   dayboarding: [],
        //   cart: false,
        //   cart_id: "",
        // };
        const updatedState = {
          shop_id: "",
          uid: "",
          products: [],
          slots: [],
          dayboarding: [],
          cart: false,
          cart_id: "",
        };

        // Update local storage
        localStorage.setItem("cart", JSON.stringify(updatedState));
        return updatedState;
      }

      case "Quantity": {
        let item = action.payload;
        let updatedProducts = state.products.map((prod) => {
          if (prod.productID === item.id) {
            return {
              ...prod,
              quantity:
                item.act === "plus" ? prod.quantity + 1 : prod.quantity - 1,
            };
          }
          return prod;
        });

        // return { ...state, products: updatedProducts };
        const updatedState = { ...state, products: updatedProducts };

        // Update local storage
        localStorage.setItem("cart", JSON.stringify(updatedState));

        return updatedState;
      }

      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const addService = (item) => {
    dispatch({ type: "Add_Service", payload: item });
    // setAddedItems((prevCount) => prevCount + 1);
  };
  const addProduct = (item) => {
    dispatch({ type: "Add_Product", payload: item });
    // setAddedItems((prevCount) => prevCount + 1);
  };
  const deleteService = (item) => {
    dispatch({ type: "Del_Service", payload: item });
  };
  const deleteProduct = (item) => {
    dispatch({ type: "Del_Product", payload: item });
  };
  const cartDelete = (id) => {
    dispatch({ type: "Del_Cart", payload: id });
  };
  const prodQuantity = (item) => {
    dispatch({ type: "Quantity", payload: item });
  };
  // console.log(state);

  useEffect(() => {
    const person = JSON.parse(localStorage.getItem("user"));
    setUser(person);
  }, []);

  // Current date
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;
  const [currentdate, setcurrentdate] = useState(formattedDate);

  return (
    <CartContext.Provider
      value={{
        state,
        addService,
        addProduct,
        deleteService,
        deleteProduct,
        user,
        setUser,
        ServiceType,
        setServiceType,
        coupon,
        setCoupon,
        store,
        setStore,
        cartDelete,
        prodQuantity,
        currentdate,
        setcurrentdate,
        formattedDate,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default CartContext;