import { useContext } from "react";
import CartProduct from "../components/Cart/CartProduct";
import CartProductPrice from "../components/Cart/CartProductPrice";
import EmptyCart from "../components/Cart/EmptyCart";
import { useLocation } from "react-router-dom";
import CartContext from "../Cart/Cart";
import axios from "axios";
import { useState, useEffect } from "react";
import { commonHeaders, petSetUrl } from "../components/utils";
const Cart = () => {
  const { store } = useContext(CartContext);
  // console.log(store);
  const location = useLocation();
  const EmptycartComponent = location.pathname === "/EmptyCart" && (
    <EmptyCart />
  );
  const CartProductComponent = location.pathname === "/Cart" && <CartProduct />;
  const [apiData, setApiData] = useState([]);

  const getCart = async (id) => {
    try {
      const response = await axios.get(`${petSetUrl}/v1/cart/get?uid=${id}`, {
        headers: commonHeaders,
      });
      if (response) {
        // console.log(response.data.data);
        setApiData(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCart(localStorage.getItem("uid"));
  }, []);
  return (
    <>
      <div className="lh-1" style={{ padding: "1% 5%" }}>
        <p className="mt-5">Home > Cart > {apiData?.store?.store_name || ""}</p>
        <h1 className="text-bold">{apiData?.store?.store_name || ""}</h1>
      </div>
      <div className="row ms-0 ms-md-5 mt-5">
        <div className="col-sm-12 col-md-7 col-lg-8">
          {CartProductComponent}
          {EmptycartComponent}
        </div>
        <div className="col-sm-12 col-md-4 col-lg-3 ms-0 mt-3 mt-md-0 ms-md-4">
          <CartProductPrice />
        </div>
      </div>
    </>
  );
};
export default Cart;
