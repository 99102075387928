import FiftyOff from "../components/Home/FiftyOff";
const Privacy = () => {
  return (
    <>
      <div className="lh-1" style={{ padding: "1% 5%" }}>
        <p className="mt-5">Home > Privacy Policy</p>
        <h1 className="text-bold">Privacy Policy</h1>
      </div>
      <div className="mt-4" style={{ padding: "0 5%" }}>
        <div>
          <p>
            Pet Set Go FZ LLC. and its affiliates and subsidiaries (“Pet Set Go”
            or “we”, “our”, “us”) respect your privacy concerns. This Privacy
            Policy (“Privacy Policy”) explains the type of information we may
            obtain from individuals who visit our websites (each, a “Site” and
            collectively, “Sites”) or use our mobile applications (each, an
            “App” and collectively, “Apps”), which includes tools such as chat
            available through our Sites and Apps. It also explains the type of
            information we may obtain from individuals who otherwise connect
            with us offline, such as by phone (“Offline Services” and
            collectively with all Sites and Apps, the “Services” or
            individually, a “Service”). When we use the term “including”, it
            shall mean “including but not limited to”. In addition, this Privacy
            Policy sets out how Pet Set Go may use, disclose, and protect your
            personal information.
          </p>
          <h4>INFORMATION WE OBTAIN</h4>
          <h6>Information you give us</h6>
          <p>
            We collect and maintain the information you may provide us through
            your use of our Services, including your name, shipping address,
            billing address, payment card information, telephone number(s),
            email address(es) and purchase/return/exchange data and pet
            information. For example, when you: set up your account and/or make
            online purchases or online bookings for pet care services; you will
            be asked to provide information necessary to facilitate the
            transaction, such as contact and payment information); seek
            assistance from our customer service team through email, telephone,
            WhatsApp, or any other channels we may ask you questions about your
            pet or otherwise collect and record additional information from or
            about you during those interactions. enter our contests,
            sweepstakes, or other promotions; you may be asked for information
            necessary to fulfil the promotion. post comments or other content in
            publicly-available forums on our Services (g., Product reviews, Q&A
            forum); anything you post will be available to us and visitors. We
            also may collect information about your pet(s), including name,
            breed, gender, birthdate, size, weight, prescriptions, veterinary
            and pet health information, pet insurance information, and photos.
            You must select a payment method when you place online orders and
            bookings through the website. Online debit/ credit card payment is
            the only payment method for some pet service vendors (groomers,
            boarders, veterinary clinics, trainers, etc.). For transactions, we
            transmit your entire card information to the appropriate
            debit/credit card company in an encrypted format with globally
            accepted rules and applications during online orders and booking
            processing. Upon your choice, we keep some of your card information
            encrypted, taking precautions to maintain physical, electronic, and
            procedural safeguards over your credit card information.{" "}
          </p>

          <h6>Apple Pay, Credit Cards and Payment Information</h6>
          <p>
            When you place an order through the mobile application, you are
            required to select a method of payment. Pet Set GO is not interested
            in your debit/credit card information nor do store any of your
            debit/credit information, since orders could be paid through methods
            of payment such as debit or credit card or Apple pay. For
            transactions with online debit/credit cards, we transmit your entire
            card information to the appropriate debit/credit card company in an
            encrypted format with globally accepted rules and applications
            during order processing. Upon your choice, we keep a part of your
            card information identifier in an encrypted format, taking
            precaution to maintain physical, electronic and procedural
            safeguards over your credit card information.
          </p>

          <h6>Information we obtain by automated means</h6>
          <p>
            We may use technology to automatically obtain other information
            about you and your devices when you visit our Sites, use our App, or
            view our advertisements online. When you visit our site, our server
            sends your computer a "cookie." This cookie is a small packet of
            information that identifies you as a user of our system. Cookies
            save you time by retaining your contact information. This
            information helps our Sites and Apps work correctly and supports our
            marketing and analytics efforts. This information includes but is
            not limited to devise type; device settings; Internet browser
            information; operating system information; IP address; mobile device
            identifiers; software version; phone model; phone operating system;
            mobile phone carrier information; precise geolocation data; the
            date, time, and length of your stay on our Sites and Apps; and the
            referring website, specific pages accessed and other actions you
            take on our Sites and Apps. Your chats, phone/video calls, and
            interactions with our customer service team or veterinarians may be
            recorded. We may obtain other information from you occasionally by
            providing appropriate notice. We also use a third-party session
            replay service that records users’ interactions with our Sites and
            Apps, including users’ clicks, mouse movements and scrolls. Cookies
            do not deliver viruses and can only be read by the server that sent
            you the cookie. You can accept or decline cookies by modifying the
            settings in your browser. Please bear in mind, however, that you may
            not be able to use all the features of our app if cookies are
            disabled.
          </p>
          <h6>Information we obtain from other sources.</h6>
          <p>
            We may receive information about you from other sources to, for
            example, help us correct or supplement our records, improve the
            quality of our Services, prevent or detect fraud and support our
            marketing and analytics. Such sources may include information
            collected from payment processors, data brokers, third-party ad
            technology companies, veterinarians, insurance companies, shelters,
            and shipping carriers. We may use this information consistent with
            this Privacy Policy.
          </p>

          <h6>Third-Party Web Analytics Services</h6>
          <p>
            We use third-party web analytics services on our Sites and Apps,
            such as Google Analytics. The service providers that administer
            these services use automated technologies to collect data (such as
            IP addresses, cookies, and other device identifiers) to evaluate the
            use of our Services. To learn more about Google Analytics and how to
            opt-out, please visit www.google.com/analytics/learn/privacy.html.
            Our third-party service providers may also use cookies, pixel tags,
            web beacons, and similar technologies to better serve you with more
            tailored information and facilitate your ongoing use of our Site. If
            you do not want information collected using cookies, a simple
            procedure in most browsers allows you to decline the use of cookies.
            To learn more about cookies, please visit
            http://www.allaboutcookies.org/.
          </p>

          <h4>HOW WE USE THE INFORMATION WE OBTAIN</h4>

          <p>
            We may use, process, and disclose the information we collect about
            you for our business purposes, including:
          </p>

          <h6>
            Fulfilling orders and responding to your questions and requests
          </h6>
          <p>
            We may use your information to provide you with transactional
            assistance, such as fulfilling your order, processing your payments,
            providing you with customer and technical support, obtaining store
            assistance, offering new products and services, and ensuring the
            proper delivery of the products and services you order from us.
          </p>
          <h6>
            Creating and maintaining your Pet Set Go account and communicating
            with you
          </h6>
          <p>
            We may use your information to maintain your Pet Set Go account,
            including, where applicable, by updating your pet profile with the
            information provided by your veterinarian about your pet. In
            addition, we may use your information to contact you about (1)
            updates to this Privacy Policy or our Terms of Use; (2) changes to
            our Services, products, and programs; (3) reviews, comments, or
            other feedback you provide about our products or Services; (4)
            issues with your orders; or (5) product recalls.
          </p>
          <h6>Improving and personalising our products and Services</h6>
          <p>
            We may use your information to improve our products and Services and
            personalise our Services to meet your preferences and needs,
            customise your experience with us and provide you with a consistent
            experience across the devices you use.
          </p>

          <h6>Reviewing Site and App trends and customer interests</h6>
          <p>
            We may also use your information to analyse the way you use our
            Sites and Apps and understand your interests, including by combining
            information we receive from you and your devices with other
            information about you that we receive from other sources.
          </p>

          <h6>Sending and improving marketing communications</h6>
          <p>
            We may send you communications about promotions or offers via
            regular mail, email, text messaging or other electronic channels,
            including ads on Social Media Services. We may use information
            collected across different online services and from the various
            devices you may use for marketing and analytics purposes and to
            deliver marketing communications based on your behaviour and
            interests.
          </p>

          <h6>Promoting our products or Services</h6>
          <p>
            If you complete a product review, submit feedback, or provide an
            image of your pet(s), we may use your submission for commercial
            purposes, to promote our products and Services, or to send you
            personalised content, including pet portraits, thank you notes and
            holiday cards.
          </p>

          <h6>Administering promotions, such as sweepstakes and contests</h6>
          <p>
            We may use your information to administer sweepstakes, contests, and
            other promotions if you choose to participate.
          </p>

          <h6>Detecting and preventing fraud</h6>
          <p>
            We may use your information to protect against, identify, and
            prevent fraud, other criminal activity, claims, and other
            liabilities.
          </p>

          <h6>Complying with the law</h6>
          <p>
            We may use your information to comply with and enforce applicable
            legal requirements (including maintaining pet health records and
            authorisations), relevant industry standards and our policies,
            including this Privacy Policy and our Terms of Use.
          </p>

          <h6>Other uses</h6>
          <p>
            We may use your information to operate, evaluate, develop, manage
            and improve our business (including operating, administering,
            analysing, and improving our Services; developing new products and
            services; managing and evaluating the effectiveness of our
            communications; conducting market research, aggregating and
            anonymising data, and performing data analytics; facilitating the
            provision of services to Pet Set Go; and performing accounting,
            auditing, and other internal functions). We also may use your
            information in other ways for which we provide specific notice at
            the time of collection or otherwise with your consent.
          </p>

          <h4>COOKIES AND INTEREST-BASED ADVERTISING</h4>
          <h6>Tracking tools we use</h6>
          <p>
            Third-party ad technology providers and we may collect information
            about you and your devices over time and across different websites
            and online services through tracking tools, including cookies, web
            beacons, clear GIFs, pixels, device identifiers and other online
            information-gathering tools. We may use these automated technologies
            to collect information about your equipment, browsing actions, and
            usage patterns. We may use these automated technologies to
            authenticate your account and store your password if you are a
            registered Pet Set Go account holder and otherwise detect and
            prevent fraud. monitor the performance of our Services. learn more
            about the way you interact with our Services and content.
            personalise your experience on our Sites, Apps, and social media
            Services. optimise and tailor our Sites and Apps. make product
            recommendations, perform analytics, and personalisation and provide
            you with relevant advertising content on our Services and
            third-party websites and online services. measure the effectiveness
            of the ads we serve you. conduct data analytics and research to help
            us improve our Services and product offerings; and, better
            understand our Site visitors and App users and their respective
            interests.
          </p>

          <h6>Interest-based advertising</h6>
          <p>
            Third-party ad technology providers and we may use cookies to learn
            about the ads you see and interact with and other actions you take
            on our Services. This allows us to provide more useful and relevant
            ads on Pet Set Go -owned and operated Services and third-party
            websites, apps, and online services. Interest-based or personalised
            or targeted ads are displayed to you based on information collected
            about your online activities, such as when you browse or purchase a
            product on our Sites or Apps or visit third-party websites or apps
            or online services. Third-party ad technology companies may help us
            display content tailored to your interests and serve interest-based
            advertising on our behalf. Additionally, some third-party ad
            technology companies may provide us with information about you (such
            as demographic information) from offline and online sources, which
            we may use to provide you with more relevant and useful advertising.
            Advertisers or third-party ad technology companies working on their
            behalf sometimes use automated technologies to serve the ads that
            appear on our Sites and Apps. They automatically receive your IP
            address and other device identifiers when this happens. They may
            also use cookies to measure their ads’ effectiveness and personalise
            ad content.
          </p>
          <h4>INFORMATION WE DISCLOSE</h4>
          <p>
            We may share information about you with third parties to conduct our
            business when legally required, or with your consent, including
          </p>

          <h6>Affiliates and Subsidiaries</h6>
          <p>
            We may share your information with our affiliates and subsidiaries
            for business, operational, promotional, marketing, or other purposes
            consistent with this Privacy Policy.
          </p>

          <h6>Service Providers</h6>
          <p>
            We may disclose the information we obtain about you to service
            providers who perform services on our behalf (e.g., to fulfil
            orders, deliver packages, send postal mail and/or email, analyse
            data, provide telehealth services, provide session replay services,
            provide marketing research and assistance, process credit card
            payments, detect and prevent fraud, and provide customer service
            including through chat features). Our service providers are
            restricted from using your personal information in any way other
            than to provide services for us.
          </p>

          <h6>Business Partners</h6>
          <p>
            With your consent and direction, we may share your information with
            our business partners (such as veterinarians, shops, and animal
            shelters) so they can provide you with the services you request or
            may interest you.
          </p>

          <h6>Other Third Parties</h6>
          <p>
            We may disclose your information to other third parties concerning
            our marketing efforts to promote our Services or as otherwise
            directed by you.
          </p>

          <h6>Veterinary Care Providers</h6>
          <p>
            Pet Set Go may share your and/or pet’s information with veterinary
            care providers to validate their health conditions. The platform
            also may share information that will help facilitate the treatment
            of your pet or provide required adverse event information to the
            manufacturer.
          </p>

          <h6>Business Transfer</h6>
          <p>
            We may disclose, transfer, or assign to our affiliates or one or
            more third parties, the information collected about you if we sell
            or transfer (or contemplate the sale or transfer of) all or a
            portion of the assets or business of Pet Set Go FZ LLC as part of an
            actual or contemplated merger, acquisition, or joint venture. In the
            unlikely event of our bankruptcy, insolvency, reorganisation,
            receivership, liquidation, divestiture, dissolution, or assignment
            for the benefit of creditors, or the application of laws or
            equitable principles affecting creditors’ rights generally, we may
            transfer your personal information to a successor or to a third
            party that purchases our assets arising from such circumstances. In
            such transactions, customer information generally is one of the
            transferred business assets but remains subject to the promises made
            in any pre-existing Privacy Policy.
          </p>

          <h6>Protection of Pet Set Go and Others</h6>
          <p>
            We may disclose information about you to the government or other
            third parties to comply with applicable laws and regulations,
            governmental and quasi-governmental requests, court orders,
            subpoenas, and other legal processes. We may also disclose
            information to third parties if necessary to (i) enforce any of the
            Terms of Use for our Services or any investigation of potential
            violations thereof, (ii) detect, prevent, or otherwise address
            fraud, security, or technical issues, (iii) protect against harm to
            our Services and (iv) protect our rights, property or safety, or the
            rights, property or safety of our users or others.
          </p>
          <h4>YOUR CHOICES</h4>
          <h6>Cookies</h6>
          <p>
            The “Help” feature on most Internet browsers will tell you how to
            prevent your browser from accepting new cookies, notify the browser
            when you receive a new cookie, or disable cookies altogether. If you
            set your browser to limit or remove cookies or otherwise block our
            use of cookies, some Site features may be unavailable or unable to
            function properly. Your device may not give you the ability to
            control cookies. If you wish to block cookies on our App, you may
            need to delete the App from your mobile device.
          </p>

          <h6>Email marketing opt-out</h6>
          <p>
            If you wish to opt out of any of our marketing email communications,
            you may contact our customer service representatives at the phone
            number or email address below, under the “Contact Us” section of
            this Privacy Policy. You may also follow the “unsubscribe”
            instructions in our promotional emails. Please note that removing
            user information from our database or “opting out” will not stop
            marketing or advertising content generated for distribution or
            otherwise queued for transmission to you before we can implement
            your request. If you unsubscribe from marketing communications, you
            may continue to receive transactional communications, such as email
            or text notifications about your order status, recall information
            and other administrative information. Your information will remain
            in our database but be marked inactive, so you do not receive
            marketing materials from us.
          </p>

          <h6>Interest-based advertising opt-out</h6>
          <p>
            Opting out of receiving interest-based ads does not mean that you
            will no longer receive ads from us, but rather that the ads you
            receive may not be as relevant to your interests.
          </p>

          <h6>Apps opt-out</h6>
          <p>
            Your mobile device may also provide a device-level advertising
            preference feature that prevents your device from receiving in-app
            interest-based ads.
          </p>

          <h6>Do-Not-Track</h6>
          <p>
            Some Internet browsers have a “do not track” feature that lets you
            tell websites that you do not want your online activities tracked.
            Our Sites and Apps do not support “do not track” preferences that
            may be available in your browser and cannot satisfy the preference
            you set.
          </p>

          <h4>DATA RETENTION</h4>
          <p>
            We retain your information for the period reasonably necessary to
            achieve the purposes described in this Privacy Policy or any other
            notice provided at the time of collection, considering applicable
            statutes of limitation and records retention requirements under
            applicable law.
          </p>

          <h4>DATA SECURITY</h4>
          <p>
            We implement reasonable administrative, technical, and physical
            safeguards to protect our collected information.
          </p>

          <h4>INFORMATION FROM CHILDREN</h4>
          <p>
            The Sites and Apps are designed for a general audience and are not
            directed to children under 13. We do not knowingly collect or
            solicit personal information from children under 13 through our
            Sites and Apps. If we become aware that we have collected personal
            information from a child under 13 online without the consent of the
            child’s parent or guardian, we will use all reasonable efforts to
            delete such information promptly.
          </p>

          <h4>LINKS TO THIRD-PARTY WEBSITES, APPS OR ONLINE SERVICES</h4>
          <p>
            As a convenience to our Site visitors and App users, our Services
            may contain links to third-party websites, apps and online services
            that offer useful information. This Privacy Policy does not apply to
            third-party websites, apps, or online services. We suggest reviewing
            such third parties' privacy notices or policies or contacting them
            directly for more information on their privacy practices. Pet Set Go
            is not responsible for the privacy practices of third-party
            services, regardless of whether they are linked to or otherwise
            connected to our Services.
          </p>
          <h4>HOW TO UPDATE YOUR INFORMATION</h4>
          <p>
            You may correct or update certain information collected about you
            through our Services by contacting us at the email address noted in
            the “Contact Us” section of this Privacy Policy below or by editing
            your information and preferences on the “Your Account” and “Profile
            and Password” pages. Following receipt of a request from you, we
            will take reasonable steps to update or correct your information.
          </p>

          <h4>CHANGES TO THIS POLICY</h4>
          <p>
            We reserve the right, at our discretion, to modify our privacy
            practices and update and make changes to this Privacy Policy at any
            time and without prior notice. If we make any changes, we will
            change the “Last Updated” date above and post the new Privacy Policy
            here: www.PetSetGoME.com. You should consult this Privacy Policy
            regularly for any changes. If we make material changes, we will
            notify you by email (sent to the email address specified in your
            account) or using posting a notice on this website before the
            effective date of the change.
          </p>

          <h4>CONTACT US</h4>
          <p>
            For questions or concerns relating to our privacy policy, we can be
            contacted by email at tarun@petsetgome.com or at: IN5 Tech – EO- 5,
            Dubai Internet City, Dubai UAE Phone: 800 2465
          </p>
        </div>
      </div>
      <div className="mt-5">
        <FiftyOff />
      </div>
    </>
  );
};
export default Privacy;
