import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import checkout from "../../images/checkout.png";
import CartContext from "../../Cart/Cart";
import { commonHeaders, petSetUrl } from "../utils";

const CartProductPrice = () => {
  const { user, state, coupon, setCoupon, store } = useContext(CartContext);
  const [total, setTotal] = useState(0.0);
  const [Discount, setDiscount] = useState(0.0);
  const [CouponsApplied, setCouponsApplied] = useState("");
  const [CoupnCode, setCoupnCode] = useState(null);
  const [coupString, setCoupString] = useState("");
  const [coupMsg, setCoupMsg] = useState("");
  const [apiData, setApiData] = useState([]);

  const [prices, setPrices] = useState({
    item_total: 0.0,
    shipping: 0.0,
    pickdrop: 0.0,
    subtotal: 0.0,
    items: 0.0,
    tax: 0.0,
    discount: 0.0,
  });

  const nav = useNavigate();

  const getCartData = async () => {
    try {
      const response = await axios.get(
        `${petSetUrl}/v1/cart/get?uid=${
          localStorage.getItem("uid") || state.user
        }`,
        {
          headers: commonHeaders,
        }
      );
      if (response.data.data) {
        setApiData(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCartData();
  }, [state]);

  const calculatePrices = () => {
    try {
      let item_total = 0.0;
      let full = 0.0;
      let slotTotal = 0.0;
      let productTotal = 0.0;
      let subtotal = 0.0;
      let picdropTotal = 0.0;
      let discountTotal = 0.0;
      let tax = 0.0;

      if (apiData.slotReservations) {
        apiData.slotReservations.forEach((slot) => {
          slotTotal += slot?.service?.service_price;
          if (slot.pickup && !slot.drop_off) {
            picdropTotal += slot.pickup_rate || 0;
          } else if (slot.drop_off && !slot.pickup) {
            picdropTotal += slot.dropoff_rate || 0;
          } else if (slot.pickup && slot.drop_off) {
            picdropTotal +=
              slot.pickup_rate_AND_dropoff_rate || slot?.pickup_dropup_price;
          }
        });
      }

      if (apiData.cartProducts) {
        apiData.cartProducts.forEach((prod) => {
          productTotal += prod.price * prod.quantity;

          // discountTotal += prod.discount_price;
        });
      }

      item_total = productTotal + slotTotal;
      subtotal = item_total / 1.05;
      tax = item_total - subtotal;
      full = subtotal + tax + picdropTotal - discountTotal;

      setPrices({
        ...prices,
        items: slotTotal + productTotal,
        pickdrop: picdropTotal,
        subtotal: subtotal,
        tax: tax,
      });

      setTotal(full);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (apiData && Object.keys(apiData).length > 0) {
      calculatePrices();
    }
  }, [apiData, state]);

  const handleCoupon = async () => {
    try {
      const response = await axios.get(
        `${petSetUrl}/v2/store/coupons/check?couponsCode=${coupString}&customer_id=${user.uid}`,
        {
          headers: commonHeaders,
        }
      );
      if (response?.data?.data) {
        setCoupMsg("Coupon Applied");
        const data = response.data.data;
        if (data.type === "fixed") {
          if (total > data.discountCap) {
            setCoupon({
              type: data.type,
              price: data.rate,
            });
            let totDeduct = total - data.rate;
            setDiscount(data.rate);
            setCouponsApplied("Coupon");
            setCoupnCode(data.couponsCode);
            setTotal(totDeduct);
          } else {
            setCoupMsg("Minimum " + data.discountCap + " purchase Require");
            setTotal(total);
          }
        }
        if (data.type === "percentage") {
          const calcP = (total * data.rate) / 100;
          if (total > data.discountCap) {
            setCoupon({
              type: data.type,
              price: calcP,
            });
            let totDeduct = total - calcP;
            setDiscount(calcP);
            setCouponsApplied("Coupon");
            setCoupnCode(data.couponsCode);
            setTotal(totDeduct);
          } else {
            setCoupMsg("Minimum " + data.discountCap + " purchase Require");
            setTotal(total);
          }
        }
      }
    } catch (error) {
      console.log(error.response.data.message);
      setCoupMsg(error.response.data.message);
    }
  };

  const InputSection = (
    <div className="row g-2">
      <p className="w-100">
        {coupMsg
          ? coupMsg
          : "Note: One discount can be redeemed per order. Check our terms & conditions."}
      </p>
      {!CouponsApplied && (
        <>
          <input
            type="text"
            className="col-7 border rounded-3"
            style={{ outline: "none" }}
            onChange={(e) => setCoupString(e.target.value)}
          />
          <button
            className="border-0 rounded-5 p-2 px-2 col-4 ms-2"
            style={{ backgroundColor: "#E0D9FA", fontFamily: "black" }}
            onClick={() => handleCoupon()}
          >
            Apply
          </button>
        </>
      )}
    </div>
  );

  return (
    <>
      <div className="border p-4">
        <div className="row">
          <p className="col-7">Subtotal </p>
          <h6 className="col-5">AED : {prices.subtotal.toFixed(2)}</h6>
        </div>
        <div className="row  pb-2 ">
          <p className="col-7">Shipping</p>
          <h6 className="col-5">AED : {prices.shipping.toFixed(2)}</h6>
        </div>
        <div className="row pb-2 ">
          <p className="col-7">Pick/Drop</p>
          <h6 className="col-5">AED : {prices.pickdrop.toFixed(2)}</h6>
        </div>
        <div className="row border-bottom pb-2 ">
          <p className="col-7">Discount</p>
          <h6 className="col-5">AED : {Discount.toFixed(2)}</h6>
        </div>
        <div className="row pt-2">
          <p className="col-7">Tax</p>
          <h6 className="col-5">AED: {prices.tax.toFixed(2)}</h6>
        </div>
        <div className="row ">
          <p className="col-7">Total</p>
          <h6 className="col-5">AED :{total.toFixed(2)}</h6>
        </div>
        <button
          className="border-0 rounded-5 p-2 px-5"
          style={{ backgroundColor: "#E0D9FA", fontFamily: "black" }}
          onClick={() => {
            nav("/Checkout", {
              state: {
                sub_total: prices.subtotal,
                tax: prices.tax,
                total_price: total,
                couponApply: CouponsApplied,
                couponName: CoupnCode,
              },
            });
          }}
        >
          Countinue to Payment
        </button>
      </div>
      <div className="pt-4">
        <h6>Promocode & Gifts</h6>
        {InputSection}
      </div>
    </>
  );
};

export default CartProductPrice;
