import React from "react";
import { useEffect, useState } from "react";
import Head from "../components/Home/Head";
import Groaming from "../components/Home/GroamingSection";
import PopularStores from "../components/Home/PopularStores";
import Articles from "../components/Home/Articles";
import FiftyOff from "../components/Home/FiftyOff";
import VideoCall from "../components/Home/VideoCall";
import DownloadApp from "../components/Home/DownloadApp";
import { commonHeaders, petSetUrl } from "../components/utils";
const Home = () => {
  const [Data, setData] = useState([]);
  useEffect(() => {
    const storedLatitude = localStorage.getItem("lat");
    const storedLongitude = localStorage.getItem("lng");
    const storeApiUrl = `${petSetUrl}/v1/store/get?lng=${storedLongitude}&lat=${storedLatitude}`;
    const requestOptions = {
      method: "GET",
      headers: commonHeaders,
    };
    fetch(storeApiUrl, requestOptions)
      .then((response) => response.json())
      // axios
      //   .get(storeApiUrl, {
      //     headers: commonHeaders,
      //   })
      .then((storeResponseData) => {
        setData(
          storeResponseData.shops
            .map((item, index) => (index < 10 ? item : null))
            .filter((item) => item !== null)
        );
      })
      .catch((error) => console.log("error", error));
  }, []);
  return (
    <>
      <Head />
      <Groaming />
      <FiftyOff />
      <VideoCall />
      <PopularStores storeData={Data} title="Popular Stores" />
      <Articles />
      <DownloadApp />
    </>
  );
};

export default Home;
