import { signOut } from "firebase/auth";
import { auth } from "../firbase.Config";
import Accountdetail from "../components/Your Account/Accountdetail";
import { useNavigate } from "react-router";
import CartContext from "../Cart/Cart";
import { useContext } from "react";
const Account = () => {
  const navigate = useNavigate();
  const { setUser } = useContext(CartContext);
  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        navigate("/Login");
        // console.log("Signed out successfully");
        setUser("");
        const lat = localStorage.getItem("lat");
        const lng = localStorage.getItem("lng");

        // Clear localStorage
        localStorage.clear();

        // Restore lat and lng values
        localStorage.setItem("lat", lat);
        localStorage.setItem("lng", lng);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      <div className="lh-1" style={{ padding: "1% 5%" }}>
        <p className="mt-5">Home > Your Account</p>
        <h1 className="text-bold">Your Account</h1>
      </div>
      <Accountdetail />

      <div
        style={{
          padding: "0 5%",
          float: "right",
          fontFamily: "black",
          // paddingBottom: "50%",
          // paddinBottom: "50%"
          marginTop: "3%",
        }}
        onClick={handleLogout}
      >
        <button
          className="border-0 rounded-5 px-5 pb-3 pt-2"
          style={{
            backgroundColor: "rgb(224, 217, 250)",
          }}
        >
          Logout
        </button>
      </div>
      <div style={{ paddingTop: "5%" }}></div>
    </>
  );
};

export default Account;
