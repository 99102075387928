import { GoArrowRight } from "react-icons/go";
import doctor3 from "../../images/dr3.png";
// import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { commonHeaders, petSetUrl } from "../utils";

const DoctorList = () => {
  const Nav = useNavigate();
  const [doctorsData, setdoctorsData] = useState([]);
  useEffect(() => {
    const Api = `${petSetUrl}/v1/video/resources/get?role=Emergency`;
    const requestOptions = {
      method: "GET",
      headers: commonHeaders,
    };
    fetch(Api, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        setdoctorsData(responseData.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <>
      <div className="row" style={{ padding: "0 5%" }}>
        {doctorsData.map((doctor, index) => (
          <div className="col-md-6 col-sm-12 mb-3" key={index}>
            <div
              className="row pt-3 border rounded-3 px-4"
              style={{cursor:"pointer"}}
              onClick={() => {
                Nav("/Doctor", {
                  state: {
                    data: doctor.resource_id,
                  },
                });
              }}
            >
              <div className="d-flex col-11">
                <img
                  className="rounded-5"
                  src={
                    doctor.image.substring(0, 4) === "http"
                      ? doctor.image
                      : doctor.image === ""
                      ? doctor3
                      : "https://petsetgostorage.blob.core.windows.net/petsetgo-u2/" +
                        doctor.image
                  }
                  alt=""
                  height="40px"
                  width="40px"
                />
                <div className="ms-3">
                  <h6 className="small">{doctor.name}</h6>
                  <p className="small">{doctor.specialty}</p>
                </div>
              </div>
              <p
                className="col-1"
                style={{
                  cursor: "pointer",
                }}
              >
                <GoArrowRight />
              </p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
export default DoctorList;
