// import EmergencyCallPayment from "../components/EmergencyCall/EmergencyCallPayment";
import EmergencyCalldetail from "../components/EmergencyCall/EmergencyCalldetail";
import CartContext from "../Cart/Cart";
import { useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { commonHeaders, petSetUrl } from "../components/utils";
const EmergenCall = () => {
  const { user } = useContext(CartContext);
  const [pet, setPet] = useState("");
  // const [selectedPet, setSelectedPet] = useState(null);
  const handlePetChange = (e) => {
    const selectedPetName = e.target.value;
    const selectedPetDetails = pets.find(
      (item) => item.pet_name === selectedPetName
    );

    // console.log(selectedPetDetails);

    localStorage.setItem("EmergencyPetSpecies",selectedPetDetails.species);
    localStorage.setItem("EmergencyPetId",selectedPetDetails.pid
    );
    // setSelectedPet(selectedPetDetails);
    setPet(selectedPetName);
  };

  // console.log(pet);

  const [pets, setPets] = useState([]);
  const location = useLocation();
  const getPets = async (id) => {
    try {
      const response = await axios.get(
        `${petSetUrl}/v1/pet/get?owner_id=${id}`,
        {
          headers: commonHeaders,
        }
      );
      if (response.data) {
        // console.log(response.data.data);
        setPets(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (user?.uid) {
      getPets(user.uid);
    }
  }, []);

  const VeterinaryCall = location.pathname === "/CallVeterinary" && (
    <div className="lh-1" style={{ padding: "1% 5% 1% 5%" }}>
      <p className="mt-5">Home > Checkout > Veterinary</p>
      <h1 className="text-bold">Veterinary</h1>
    </div>
  );
  const EmergencyCall = location.pathname === "/EmergenCall" && (
    <div className="lh-1" style={{ padding: "1% 5% 1% 5%" }}>
      <p className="mt-5">Home > Checkout > Emergency Call</p>
      <h1 className="text-bold">Emergency Call</h1>
    </div>
  );
  return (
    <>
      {EmergencyCall}
      {VeterinaryCall}

      <div className="row ms-5 mt-5">
        <div className="col-8 col-md-8 ">
          {/* <EmergencyCallPayment /> */}
          <div className="col-12">
            <h6 style={{ fontSize: "14px" }}>Select Pet</h6>
            {user ? (
              <select
                className="form-select w-50 rounded-3 border mt-4"
                style={{ boxShadow: "none", border: "none" }}
                value={pet}
                onChange={handlePetChange}
              >
                <option value="" disabled selected>
                  Select Your Pet
                </option>
                {pets
                  ? pets.map((item, i) => (
                      <option value={item.pet_name} key={i}>
                        {item.pet_name}
                      </option>
                    ))
                  : null}
              </select>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="col-8 col-md-3 ms-4">
          <EmergencyCalldetail />
        </div>
      </div>
    </>
  );
};
export default EmergenCall;
