import { useState, useContext, useEffect } from "react";
// import food from "../../images/DogFood.png";
import increment from "../../images/increment.png";
import decrement from "../../images/decrement.png";
import deleteIcon from "../../images/delete.png";
import CartContext from "../../Cart/Cart";
import axios from "axios";
import { commonHeaders, petSetUrl } from "../utils";

const CartProduct = () => {
  const { user, state, deleteService, deleteProduct, prodQuantity } =
    useContext(CartContext);
  // console.log(state);
  // const [count, setCount] = useState(Array(state.products.length).fill(1));

  // const handleIncrement = (index) => {
  //   console.log(state.products);
  //   const newCounts = [...count];
  //   newCounts[index] += 1;
  //   setCount(newCounts);
  // };

  // console.log(state.slot.length + state.products.length);
  const handleIncDec = async (id, act) => {
    // console.log(apiData);
    const product = apiData.cartProducts.find(
      (item) => item.product.pid === id
    );
    // console.log(product);

    if (!product) {
      return;
    }

    let newQuantity = product.quantity;
    // console.log(newQuantity)

    if (act === "plus") {
      newQuantity += 1;
    } else if (act === "minus" && newQuantity > 1) {
      newQuantity -= 1;
    } else {
      return; // Do not proceed if the quantity is already at its minimum
    }

    // Update local state
    const updatedApiData = {
      ...apiData,
      cartProducts: apiData.cartProducts.map((item) =>
        item.product.pid === id ? { ...item, quantity: newQuantity } : item
      ),
    };
    setApiData(updatedApiData);
    // console.log(newQuantity)
    // Update the server-side quantity
    try {
      const data = {
        productID: product?.product?.pid,
        quantity: newQuantity,
      };
      const response = await axios.post(
        `${petSetUrl}/v1/cart/product/update?cartProductID=${product.cartProductID}`,
        data,
        {
          headers: commonHeaders,
        }
      );

      if (response) {

        // console.log("Product quantity updated successfully:", response.data);
        window.location.reload();
        // You may handle success cases or update the context state if needed
      }
    } catch (error) {
      console.log("Error updating product quantity:", error);
      // Handle error cases if needed
    }
  };
  // const handleIncDec = (id, act) => {
  //   const data = { id, act };
  //   if (
  //     act === "minus" &&
  //     state.products.find((item) => item.productID === id)?.quantity === 1
  //   ) {
  //     return;
  //   }
  //   prodQuantity(data);
  // };
  const [apiData, setApiData] = useState([]);

  const getCart = async (id) => {
    try {
      const response = await axios.get(`${petSetUrl}/v1/cart/get?uid=${id}`, {
        headers: commonHeaders,
      });
      if (response) {
        // console.log(response.data.data);
        setApiData(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteItem = async (cartId, type, id) => {
    try {
      const response = await axios.delete(
        `${petSetUrl}/v1/cart/product/remove?cartProductID=${cartId}&type=${type}`,
        {
          headers: commonHeaders,
        }
      );
      if (response) {
        // console.log(response);
        if (type === "service") {
          deleteService(id);
        } else {
          // console.log(id);
          deleteProduct(id);
        }
        getCart(state.uid);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = (id, type) => {
    // console.log(type);
    // console.log(id, "idddddddddddddd");
    if (type === "service") {
      const item = apiData?.slotReservations?.find(
        (item) => item.service_id === id
      );
      // console.log(item);
      deleteItem(item?.slot_reservation_id, "service", item?.service_id);
    }
    if (type === "product") {
      const item = apiData?.cartProducts?.find((item) => item.productID === id);
      // console.log(item);
      deleteItem(item?.cartProductID, "product", item?.productID);
    }
  };

  useEffect(() => {
    getCart(state.uid || localStorage.getItem("uid"));
  }, [state]);

  const services = apiData.slotReservations
    ? apiData.slotReservations.map((item, i) => {
        return (
          <div>
            <div className="row border-md-bottom pb-4 ms-1" key={i}>
              <img
                src={item?.media || item?.service?.media}
                alt=""
                height="185px"
                className="col-lg-4 col-md-4 col-sm-5 col-8"
              />
              {/* <img
                src={deleteIcon}
                alt=""
                height="20px"
                width="20px"
                className="col-lg-1 col-md-2 ms-5 d-blobk d-md-none"
                style={{ width: "40px" }}
                onClick={() => handleDelete(item.service_id, "service")}
              /> */}
              <div className="mt-3 col-lg-7 col-md-6 col-10">
                <h6>{item?.resource_name || item?.service?.service_title}</h6>
                <h6>AED {item?.price || item?.service?.service_price}</h6>
                <p>Selected Date: {item?.slotDate}</p>
                <p>Selected Time: {item?.slotTiming}</p>
              </div>
              <img
                src={deleteIcon}
                alt=""
                height="20px"
                width="20px"
                className="col-lg-1 col-md-2 mt-3 mt-md-0"
                style={{ width: "40px" }}
                onClick={() => handleDelete(item.service_id, "service")}
              />
            </div>
          </div>
        );
      })
    : "";

  const Product = apiData.cartProducts
    ? apiData.cartProducts.map((item, i) => {
        return (
          <div key={i}>
            <div className="row border-md-bottom pb-4 ms-1" key={i}>
              <img
                src={item?.media || item?.product?.img_id}
                alt=""
                height="185px"
                className="col-4 col-md-4 col-sm-5 col-8"
              />
              {/* <img
                src={deleteIcon}
                alt=""
                height="20px"
                width="20px"
                className="col-lg-1 col-md-2 ms-5 d-md-none d-block"
                style={{ width: "40px" }}
                onClick={() => handleDelete(item.productID, "product")}
              /> */}
              <div className="mt-3 col-lg-7 col-md-6 col-10">
                <h6>{item?.title || item?.product?.title}</h6>
                <h6>
                  AED{" "}
                  {(item?.price * item.quantity).toFixed(2) ||
                    (item?.product?.price * item.quantity).toFixed(2)}
                </h6>
                <p className="small" style={{ color: "#A3A3A3" }}>
                  {item?.Weight || item?.product?.weight}
                </p>
                <h6 className="mt-2">QUANTITY</h6>

                <div
                  className="d-flex justify-content-between border px-2"
                  style={{ height: "40px", width: "40%", alignItems: "center" }}
                >
                  <div>
                    <img
                      src={increment}
                      alt=""
                      onClick={() => handleIncDec(item.productID, "plus")}
                    />
                  </div>
                  {item.quantity}
                  <div>
                    <img
                      src={decrement}
                      alt=""
                      onClick={() => handleIncDec(item.productID, "minus")}
                    />
                  </div>
                </div>
              </div>

              <img
                src={deleteIcon}
                alt=""
                height="20px"
                width="20px"
                className="col-lg-1 col-md-2 mt-3 mt-md-0"
                style={{ width: "40px" }}
                onClick={() => handleDelete(item.productID, "product")}
              />
            </div>
          </div>
        );
      })
    : "";
  return (
    <>
      {services}
      {Product}
      <div>
        {/* <div className="mt-3">
          <button
            className="border-0 rounded-5 p-2 px-4"
            style={{ backgroundColor: "#E0D9FA", fontFamily: "black" }}
          >
            Continue Shopping
          </button>
        </div> */}
      </div>
    </>
  );
};

export default CartProduct;
