import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { commonHeaders, petSetUrl } from "../components/utils";
const OrderHistory = () => {
  const [OrderData, setOrderData] = useState([]);
  const [loading, setLoading] = useState(false);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const formattedDate = `${
      date.getMonth() + 1
    }/${date.getDate()}/${date.getFullYear()}`;
    return formattedDate;
  };
  useEffect(() => {
    setLoading(true);
    const Api = `${petSetUrl}/v1/order/order/get?customer_id=${localStorage.getItem(
      "uid"
    )}`;
    const requestOptions = {
      method: "GET",
      headers: commonHeaders,
    };
    fetch(Api, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        // console.log(responseData);
        setOrderData(responseData.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        // Set loading to false after two seconds
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      });
  }, []);
  return (
    <>
      <div className="lh-1" style={{ padding: "1% 5%" }}>
        <p className="mt-5">Home > Your Account > Order History</p>
        <h1 className="text-bold">Order History</h1>
      </div>

      <div className="mt-5" style={{ padding: "0 5%" }}>
        <table class="table border">
          <thead style={{ backgroundColor: "black" }}>
            <tr>
              <th className="small bg-light" style={{ fontWeight: "400" }}>
                Order ID
              </th>
              <th className="small bg-light" style={{ fontWeight: "400" }}>
                Date
              </th>
              <th className="small bg-light" style={{ fontWeight: "400" }}>
                Total Price
              </th>
              <th className="small bg-light" style={{ fontWeight: "400" }}>
                Payment
              </th>
              <th className="small bg-light" style={{ fontWeight: "400" }}>
                Status
              </th>
              <th className="small bg-light" style={{ fontWeight: "400" }}></th>
            </tr>
          </thead>
          {loading ? (
            <div
              className="loader"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            ></div>
          ) : (
            <tbody>
              {OrderData.map((OrderData, index) => (
                <tr key={index}>
                  <td className="small" style={{ fontFamily: "black" }}>
                    {OrderData.orderID}
                  </td>
                  <td className="small">{formatDate(OrderData.createdAt)}</td>
                  <td className="small" style={{ fontFamily: "black" }}>
                    AED {OrderData.total_price}
                  </td>
                  <td className="small">{OrderData.paymentType}</td>
                  <td className="small">
                    <span
                    // className="rounded-5 px-4 py-1"
                    // style={{
                    //   color: OrderData.statusColor,
                    //   backgroundColor: OrderData.bgColor,
                    // }}
                    >
                      {OrderData.order_status}
                    </span>
                  </td>
                  <td className="small" style={{ color: "#F97316" }}>
                    <Link
                      to={`/OrderDetail/${OrderData.orderID}`}
                      className="text-decoration-none"
                      style={{ color: "#F97316" }}
                    >
                      <span>Detail</span>
                    </Link>
                    {/* <span className="ms-4">{OrderData.order_status}</span> */}
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
    </>
  );
};

export default OrderHistory;
